/*
  Queues a function to be called during a browser's idle once the timer expires.
  Example use case: send analytics after 2 seconds, once the timer expires check if browser is idle and only then perform send
  What is the benefit instead using just setTimeout ? -> it will check once the timer expires if browser is in idle state and can execute function without big impact on current processing

  What is the benefit instead using just requestIdleCallback ? it will delay processing the callback so it will not interrupt the current execution
  Let's assume we have following sequence of events:
  BEFORE (without setTimmeoutWhenIdle)
   - link clicked -> route change started -> analytics about route change started is sent -> page is rendered -> route change completed -> analytics about route change completed is sent -> page is rerendered

  AFTER (using setTimmeoutWhenIdle for analytics):
   - link clicked -> route change started -> page is rendered -> route change completed -> page is rerendered -> analytics about route change started is sent ->  analytics about route change completed is sent

  it will postpone processing of analytics script to some later phase so therefore it will not have impact on performance on route change
 */
export const setTimeoutWhenIdle = (
  callback: () => void,
  waitBeforeExecution: number,
  requestIdleCallbackTimeout?: number,
) => {
  setTimeout(() => {
    if (window.requestIdleCallback) {
      window.requestIdleCallback(
        () => {
          callback();
        },
        {
          timeout: requestIdleCallbackTimeout ? requestIdleCallbackTimeout : waitBeforeExecution,
        },
      );
    } else {
      callback();
    }
  }, waitBeforeExecution);
};
