import { prop, sortBy } from 'ramda';
import { INote } from 'lib/scrapbook/types';

export const getNoteById = (notes: Array<INote>, id: string): INote | {} =>
  notes.find((n) => n.id === id) || {};

export const isMobileOrTabletPortrait = ({
  isMobile,
  isLandscape,
  isTablet,
}: {
  isMobile: boolean;
  isTablet: boolean;
  isLandscape: boolean;
}) => isMobile || (isTablet && !isLandscape);

export const sortNotes = (notes: Array<INote>): Array<INote> =>
  // @ts-ignore FIXME
  sortBy(prop('updatedAt'), notes).reverse();
