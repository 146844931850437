import produce from 'immer';
import { merge } from 'ramda';
import { AuthActionTypes } from 'lib/auth/action-types';
import { Action, IReducersImmer, SettingsState } from 'lib/types';
import { UserActionTypes } from 'lib/users/action-types';
import { getReducerActions } from 'lib/utils';
import {
  ChangeCorrespondenceEmailFormAction,
  ChangeCorrespondenceEmailStartAction,
  ChangeFeedbackFieldsAction,
  SettingsActionTypes,
  ToggleFeedbackPopupAction,
} from './action-types';
import ChangeCorrespondenceEmailForm from './changeCorrespondenceEmailForm';
import FeedbackForm from './feedback-form';
import { UserDeletionStatus } from './types';

const initialState: SettingsState = {
  linkAccountsError: null,
  changeCorrespondenceEmailForm: merge(ChangeCorrespondenceEmailForm, {}),
  resetPasswordSuccess: false,
  resetPasswordStart: false,
  showFeedbackPopup: false,
  feedbackForm: FeedbackForm,
  userDeletionStatus: UserDeletionStatus.Disabled,
  showLocationChangeModal: false,
  changeWeddingCountryStatus: 'idle',
};

const reducers: IReducersImmer<SettingsState> = (draft) => ({
  [SettingsActionTypes.LINK_ACCOUNTS_START]: () => {
    draft.linkAccountsError = null;
  },

  [AuthActionTypes.LINK_ACCOUNTS_SUCCESS]: () => {
    draft.linkAccountsError = null;
  },

  [AuthActionTypes.RESET_PASSWORD]: () => {
    draft.resetPasswordStart = true;
  },

  [AuthActionTypes.RESET_PASSWORD_ERROR]: () => {
    draft.resetPasswordStart = false;
    draft.resetPasswordSuccess = false;
  },

  [AuthActionTypes.RESET_PASSWORD_SUCCESS]: () => {
    draft.resetPasswordStart = false;
    draft.resetPasswordSuccess = true;
  },

  [SettingsActionTypes.SET_CORRESPONDENCE_EMAIL_FORM_FIELD]: (
    action: ChangeCorrespondenceEmailFormAction,
  ) => {
    draft.changeCorrespondenceEmailForm.fields.email = action.payload;
  },

  [UserActionTypes.CHANGE_CORRESPONDENCE_EMAIL_START]: (
    action: ChangeCorrespondenceEmailStartAction,
  ) => {
    // weird, looks like just disabled = false
    draft.changeCorrespondenceEmailForm.disabled = Boolean(action.payload);
  },

  [UserActionTypes.CHANGE_CORRESPONDENCE_EMAIL_ERROR]: () => {
    draft.changeCorrespondenceEmailForm.disabled = false;
    draft.changeCorrespondenceEmailForm.error = null;
  },

  [UserActionTypes.CHANGE_CORRESPONDENCE_EMAIL_SUCCESS]: () => {
    draft.changeCorrespondenceEmailForm = merge(ChangeCorrespondenceEmailForm, {});
  },

  [AuthActionTypes.SIGN_OUT_COMPLETED]: () => initialState,

  [SettingsActionTypes.TOGGLE_FEEDBACK_POPUP]: (action: ToggleFeedbackPopupAction) => {
    draft.showFeedbackPopup = action.payload;
  },

  [SettingsActionTypes.CHANGE_FEEDBACK_FIELDS]: (action: ChangeFeedbackFieldsAction) => {
    const { name, value } = action.payload;
    draft.feedbackForm.fields[name] = value;
  },

  [UserActionTypes.MARK_USER_AS_DELETED_START]: () => {
    draft.userDeletionStatus = UserDeletionStatus.Pending;
  },

  [UserActionTypes.MARK_USER_AS_DELETED_SUCCESS]: () => {
    draft.userDeletionStatus = UserDeletionStatus.Success;
  },

  [UserActionTypes.MARK_USER_AS_DELETED_ERROR]: () => {
    draft.userDeletionStatus = UserDeletionStatus.Error;
  },

  [SettingsActionTypes.SHOW_LOCATION_CHANGE_MODAL]: () => {
    draft.showLocationChangeModal = true;
  },

  [SettingsActionTypes.CLOSE_LOCATION_CHANGE_MODAL]: () => {
    draft.showLocationChangeModal = false;
  },
  [SettingsActionTypes.CHANGE_WEDDING_COUNTRY]: () => {
    draft.changeWeddingCountryStatus = 'loading';
  },
  [SettingsActionTypes.CHANGE_WEDDING_COUNTRY_COMPLETED]: () => {
    draft.changeWeddingCountryStatus = 'succeeded';
  },
  [SettingsActionTypes.CHANGE_WEDDING_COUNTRY_FAILED]: () => {
    draft.changeWeddingCountryStatus = 'failed';
  },
});

const reducersActions = getReducerActions(reducers);

/*
	This is a wrapper function which runs a proper reducer from the object above.
*/
const reducer = (state: SettingsState = initialState, action: Action): SettingsState => {
  if (!reducersActions[action.type]) {
    return state;
  }

  try {
    return produce(state, (draft) => reducers(draft)[action.type](action));
  } catch (err) {
    return state;
  }
};

export default reducer;
