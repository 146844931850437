import { getIsMobile } from 'lib/app/selectors';
import { WebAnalyticsContext } from 'lib/bbcommon/utils/bridebook-analytics';
import { TaskTileDataAnalytics } from 'lib/dashboard/analytics/actions';
import { getCountryCode, getWeddingLocale } from 'lib/i18n/selectors';
import { Action, IApplicationState } from 'lib/types';
import { DashboardAnalyticsActions } from './types';

const dashboardAnalytics = (
  { type, payload }: Action,
  bridebookAnalytics: WebAnalyticsContext,
  getState: () => IApplicationState,
) => {
  switch (type) {
    case DashboardAnalyticsActions.USER_UPLOADED_COVER_PHOTO_ANALYTICS: {
      const { track } = bridebookAnalytics.getMethods('hometab');
      track({ event: 'User uploaded cover photo' });
      break;
    }

    case DashboardAnalyticsActions.TRIGGERED_CONFETTI: {
      const { track } = bridebookAnalytics.getMethods('hometab');
      track({ event: 'Clicked confetti button' });
      break;
    }

    case DashboardAnalyticsActions.FEATURE_CARD_CLICKED_ANALYTICS: {
      const { track } = bridebookAnalytics.getMethods('hometab');
      track({
        event: payload.eventName || 'Clicked on feature card',
        cardId: payload.id,
        title: payload.title,
        category: payload.category,
        url: payload.url,
      });
      break;
    }

    case DashboardAnalyticsActions.FEATURE_CARD_VIEWED_ANALYTICS: {
      const { track } = bridebookAnalytics.getMethods('hometab');
      track({
        event: payload.eventName || 'Viewed feature card',
        cardId: payload.id,
        title: payload.title,
        category: payload.category,
        url: payload.url,
      });
      break;
    }

    case DashboardAnalyticsActions.TODAYS_TASK_CLICKED_ANALYTICS: {
      const state = getState();
      const previousPath = state.app.previousPath;
      const countryCode = getCountryCode(state);
      const weddingLocale = getWeddingLocale(state);
      const userEmail = state.users.user?.contacts?.email;
      const weddingId = state.weddings.profile.id;
      const collaborators = state.weddings.collaborators;
      const mobileApp = getIsMobile(state);
      const { track } = bridebookAnalytics.getMethods('hometab');
      track({
        event: 'Clicked on todays task',
        taskId: payload.id,
        taskName: payload.name,
        taskTitle: payload.title,
        profileType: 'user',
        previousPath,
        countryCode,
        weddingLocale,
        collaborators,
        weddingId,
        mobileApp,
        userEmail,
      });
      break;
    }

    case DashboardAnalyticsActions.NEW_TODAYS_TASK_SESSION_ANALYTICS: {
      const { track } = bridebookAnalytics.getMethods('hometab');
      track({
        event: 'User started new session',
        sessionNumber: payload.sessionNumber,
      });
      break;
    }

    case DashboardAnalyticsActions.TODAYS_TASK_VIEWED_ANALYTICS: {
      const state = getState();
      const previousPath = state.app.previousPath;
      const countryCode = getCountryCode(state);
      const weddingLocale = getWeddingLocale(state);
      const userEmail = state.users.user?.contacts?.email;
      const weddingId = state.weddings.profile.id;
      const collaborators = state.weddings.collaborators;
      const mobileApp = getIsMobile(state);
      const { track } = bridebookAnalytics.getMethods('hometab');
      track({
        event: 'Viewed todays task',
        taskId: payload.id,
        taskName: payload.name,
        taskTitle: payload.title,
        profileType: 'user',
        previousPath,
        countryCode,
        weddingLocale,
        collaborators,
        weddingId,
        mobileApp,
        userEmail,
      });
      break;
    }

    case DashboardAnalyticsActions.SCROLLED_THROUGH_TODAYS_TASKS_ANALYTICS: {
      const state = getState();
      const previousPath = state.app.previousPath;
      const countryCode = getCountryCode(state);
      const weddingLocale = getWeddingLocale(state);
      const userEmail = state.users.user?.contacts?.email;
      const weddingId = state.weddings.profile.id;
      const collaborators = state.weddings.collaborators;
      const mobileApp = getIsMobile(state);
      const { track } = bridebookAnalytics.getMethods('hometab');
      const current: TaskTileDataAnalytics = payload.current;
      const next: TaskTileDataAnalytics = payload.next;
      track({
        event: 'Scrolled through todays tasks',
        currentTaskId: current.id,
        currentTaskName: current.name,
        currentTaskTitle: current.title,
        nextTaskId: next.id,
        nextTaskName: next.name,
        nextTaskTitle: next.title,
        profileType: 'user',
        previousPath,
        countryCode,
        weddingLocale,
        collaborators,
        weddingId,
        mobileApp,
        userEmail,
      });
      break;
    }

    case DashboardAnalyticsActions.CLICKED_TO_ADD_WEDDING_DETAILS_TO_THE_COUNTDOWN_WIDGET: {
      const state = getState();
      const countryCode = getCountryCode(state);
      const weddingLocale = getWeddingLocale(state);
      const userEmail = state.users.user?.contacts?.email;
      const weddingId = state.weddings.profile.id;
      const collaborators = state.weddings.collaborators;
      const mobileApp = getIsMobile(state);

      const { track } = bridebookAnalytics.getMethods('hometab');
      track({
        countryCode,
        event: 'Clicked to add wedding details to the countdown widget',
        weddingLocale,
        collaborators,
        weddingId,
        mobileApp,
        userEmail,
      });
      break;
    }
    default:
      break;
  }
};

export default dashboardAnalytics;
