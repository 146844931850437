import { languageTags } from 'lib/i18n/constants';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const path = require('path');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const ChainedBackend = require('i18next-chained-backend').default;
// eslint-disable-next-line @typescript-eslint/no-var-requires
const HttpBackend = require('i18next-http-backend/cjs');

const isBrowser = typeof window !== 'undefined';
const fs = isBrowser ? null : require('fs');

const ns = isBrowser
  ? []
  : fs
      .readdirSync(`./public/locales/en-GB/`)
      .map((file) => (file.endsWith('.json') ? file.replace('.json', '') : null))
      .filter(Boolean);

/** @type {import('i18next').InitOptions} */
module.exports = {
  i18n: {
    defaultLocale: 'en',
  },
  backend: {
    backends: isBrowser ? [HttpBackend] : [],
    backendOptions: isBrowser
      ? [
          {
            loadPath: (locale, namespace) => {
              const languageTag = (() => {
                if (process.env.NEXT_PUBLIC_CROWDIN_IN_CONTEXT) {
                  return 'ach-UG';
                }

                return languageTags[locale] || locale;
              })();

              return `/locales/${languageTag}/${namespace}.json`;
            },
          },
        ]
      : [],
  },
  localePath: (locale, namespace) => {
    const languageTag = (() => {
      if (process.env.NEXT_PUBLIC_CROWDIN_IN_CONTEXT) {
        return 'ach-UG';
      }

      return languageTags[locale] || locale;
    })();
    return path.resolve(`./public/locales/${languageTag}/${namespace}.json`);
  },
  ns,
  // @see https://www.i18next.com/principles/fallback#fallback-to-different-languages
  fallbackLng: (code) => {
    const fallbacks = ['en'];

    /**
     * Change this to `true` to enable serving German and French fallback languages.
     */
    // eslint-disable-next-line no-constant-condition
    if (false) {
      switch (code.substring(0, 2).toLowerCase()) {
        case 'de':
          fallbacks.unshift('de-DE');
          break;
        case 'fr':
          fallbacks.unshift('fr-FR');
          break;
      }
    }

    return fallbacks;
  },
  react: {
    useSuspense: false,
  },
  // This uses the same approach as the official example, to be able to provide
  // config:
  // https://github.com/i18next/i18next-http-backend/blob/master/example/next/next-i18next.config.js
  use: isBrowser ? [ChainedBackend] : [],
  serializeConfig: false,
};
