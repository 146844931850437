import produce from 'immer';
import { IArticlesState } from 'lib/articles/types';
import { AuthActionTypes } from 'lib/auth/action-types';
import { Action, IReducersImmer } from 'lib/types';
import { getReducerActions } from 'lib/utils';
import {
  ArticlesActionTypes,
  IGetRecordsSuccessAction,
  ISetCurrentAuthorAction,
  ISetRelatedArticleAction,
  ISetSearchStringAction,
  IToggleMoreTopicsAction,
} from './action-types';

const initialState: IArticlesState = {
  recordsFound: null,
  searchString: '',
  showAllTags: false,
  currentAuthor: null,
  relatedArticles: null,
};

const reducers: IReducersImmer<IArticlesState> = (draft) => ({
  [ArticlesActionTypes.TOGGLE_MORE_TOPICS]: (action: IToggleMoreTopicsAction) => {
    draft.showAllTags = action.payload.show;
  },

  [ArticlesActionTypes.SET_SEARCH_STRING]: (action: ISetSearchStringAction) => {
    draft.searchString = action.payload;
  },

  [ArticlesActionTypes.CLEAR_SEARCH]: () => {
    draft.searchString = '';
  },

  [AuthActionTypes.SIGN_OUT_COMPLETED]: () => initialState,

  [ArticlesActionTypes.GET_RECORDS_SUCCESS]: (action: IGetRecordsSuccessAction) => {
    draft.recordsFound = action.payload;
  },
  [ArticlesActionTypes.SET_CURRENT_AUTHOR]: (action: ISetCurrentAuthorAction) => {
    draft.currentAuthor = action.payload;
  },
  [ArticlesActionTypes.SET_RELATED_ARTICLES]: (action: ISetRelatedArticleAction) => {
    draft.relatedArticles = action.payload;
  },
});

const reducersActions = getReducerActions(reducers);

/*
	This is a wrapper function which runs a proper reducer from the object above.
*/
const reducer = (state: IArticlesState = initialState, action: Action): IArticlesState => {
  if (!reducersActions[action.type]) {
    return state;
  }

  try {
    return produce(state, (draft) => reducers(draft)[action.type](action));
  } catch (err) {
    return state;
  }
};

export default reducer;
