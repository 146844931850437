import { ICollaboratorForm } from './types';

export enum CollaboratorInviteMethod {
  CopiedLink = 'copiedLink',
  Email = 'email',
  ShareButton = 'shareButton',
}

export enum CollaboratorInviteLocation {
  Home = 'home',
  Onboarding = 'onboarding',
  Settings = 'settings',
  TodaysTask = 'todaysTask',
  FavouritesBanner = 'favouritesBanner',
  PlanningBanner = 'planningBanner',
  SupplierFeedbackRequest = 'supplierFeedbackRequest',
}

export enum Role {
  ADMIN = 'admin',
  EDITOR = 'editor',
  VIEWER = 'viewer',
}

export enum InviteFormStatus {
  Idle = 'idle',
  Loading = 'loading',
}

export const EmptyInviteForm: ICollaboratorForm = {
  fields: {
    email: '',
  },
  url: null,
  appName: null,
  method: null,
  role: null,
  location: null,
  status: InviteFormStatus.Idle,
  error: null,
};
