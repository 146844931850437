import { pathOr } from 'ramda';
import { IGuest, IGuest_Address } from '@bridebook/models/source/models/Weddings/Guests.types';
import { ValidationError } from '@bridebook/toolbox/src';
import {
  AddedGuestAnalyticsAction,
  AddedMultipleGuestsAnalyticsAction,
  ClickedAddGuestsAnalyticsAction,
  GuestlistAnalyticsActionTypes,
  InitialisedGuestlistAnalyticsAction,
  SharedGuestCollectorLinkAnalyticsAction,
  ToggleBulkAddGuestsMethodAnalyticsAction,
} from 'lib/guestlist/analytics/action-types';
import { getSortedCategories } from 'lib/guestlist/selectors';
import { IGuestlistCategory } from 'lib/guestlist/types';
import { getCategoryName, getPlusOnesByGroupId } from 'lib/guestlist/utils';
import { Action, IApplicationState } from 'lib/types';
import { WebAnalyticsContext } from '../../bbcommon/utils/bridebook-analytics';
import { GuestlistActionTypes } from '../action-types';

const filterLabelProps = (props: Record<string, any>) => {
  const newProps = { ...props };
  delete newProps.category;
  delete newProps.guestEmail;
  delete newProps.event;
  delete newProps.userEmail;
  delete newProps.guestPhone;
  delete newProps.guestAddesss;
  delete newProps.guestLinks;
  delete newProps.guestsToLink;
  delete newProps.guestsToUnlink;
  return newProps;
};

export const getGenericGuestProperties = (
  getState: () => IApplicationState,
  draftGuest?: IGuest,
) => {
  const state = getState();
  const {
    weddings: {
      profile: { partners },
    },
  } = state;
  const guest = draftGuest || state.guestlist.guest;

  const categories = getSortedCategories(state);
  const category = categories.find(
    (category: IGuestlistCategory) => category.id === guest.category,
  );

  const categoryLabel = category ? getCategoryName({ category, partners }) : '';

  const guestRSVPStatus = String(pathOr('noReply', ['events', 'wedding', 'attending'], guest));

  return {
    guestId: guest.id,
    guestName: guest.name,
    guestCategoryId: guest.category,
    guestCategoryName: categoryLabel,
    guestInviteType: guest.events.wedding?.invitation || '',
    guestRSVPStatus,
    guestAgeGroup: guest.age || '',
    guestGender: guest.gender || '',
    guestEmail: (guest.contacts || {}).email || '',
    guestPhone: (guest.contacts || {}).phone || '',
    guestAddress: {
      city: (guest.address || ({} as IGuest_Address)).city || '',
      country: (guest.address || ({} as IGuest_Address)).country || '',
      street: (guest.address || ({} as IGuest_Address)).street || '',
      postcode: (guest.address || ({} as IGuest_Address)).postalCode || '',
    },
    guestDietaryRequirements: guest.dietaryOptions || [],
  };
};

const getGenericGuestCategoryProperties = ({
  category,
  getState,
}: {
  category: IGuestlistCategory;
  getState: () => IApplicationState;
}) => {
  const method = getState().guestlist.edit ? 'interior' : 'exterior';
  return {
    guestCategoryType: 'category',
    guestCategoryEditMethod: method,
    guestCategoryId: (category && category.id) || '',
    guestCategoryName: (category && category.value) || '',
  };
};

export const getGenericLinkedProperties = (
  getState: () => IApplicationState,
  draftGuest: IGuest | null,
): object => {
  const {
    guestlist: { list, groupGuestsToLink, groupGuestsToUnlink },
  } = getState();
  const guest: IGuest = draftGuest || getState().guestlist.guest;

  const toLink = [...groupGuestsToLink];
  const toUnlink = [...groupGuestsToUnlink];

  if (toLink.length && toLink.indexOf(guest.id) < 0) toLink.push(guest.id);
  if (toUnlink.length && toUnlink.indexOf(guest.id) < 0) toUnlink.push(guest.id);

  const totalToLink = [guest.id].concat(getPlusOnesByGroupId(guest.id, list).map((g) => g.id));

  return {
    guestLinks: totalToLink,
    guestsToLink: toLink,
    guestsToUnlink: toUnlink,
  };
};

export const getEditGuestFailProperties = (
  edit: boolean,
  method: string,
  error: ValidationError,
): object => {
  const properties: any = {};
  if (edit) {
    properties.editedGuestMethod = method;
  } else {
    properties.addedGuestMethod = method;
    properties.reasonFailedToAddGuest = error.message;
  }

  return properties;
};

export const getAddGuestProperties = (guestMethod: string, guestAction: string | null): object => ({
  addedGuestMethod: guestMethod || '',
  addedGuestAction: guestAction || '',
});

export const getEditGuestProperties = (
  guestMethod: string,
  guestAction: string | null,
): object => ({
  editedGuestMethod: guestMethod || '',
  editedGuestAction: guestAction || '',
});

export const resolveFilterSection = (name: string, value: string) => ({
  usedGuestlistFiltersAction: value ? 'checked' : 'unchecked',
  usedGuestlistFiltersSection: name,
  usedGuestlistFiltersField: value,
});

export const guestBulkAddPropertiesGeneric = ({
  category,
  count,
  method,
}: {
  category: IGuestlistCategory;
  count: number;
  method: 'inline' | 'popup' | 'importContacts';
}) => ({
  guestCategoryId: category.id,
  guestCategoryName: category.value,
  guestBulkAddCount: count,
  addedGuestMethod: method,
});

const guestCollectorPopupGeneric = (getState: () => IApplicationState) => ({
  collectGuestInfoPopupLocation: getState().guestlist.edit ? 'guestInterior' : 'guestExterior',
});

export default function guestlistAnalytics(
  action: Action,
  bridebookAnalytics: WebAnalyticsContext,
  getState: () => IApplicationState,
): void {
  const { type, payload } = action;
  const { track } = bridebookAnalytics.getMethods('Guest List', filterLabelProps);

  switch (type) {
    case 'CLICKED_EDIT_GUEST_ANALYTICS': {
      const { method, guest } = payload;
      track({
        event: 'Clicked edit guest',
        ...getGenericGuestProperties(getState, guest),
        clickedEditGuestMethod: method,
      });
      break;
    }

    case 'CLICKED_TO_EDIT_GUEST_CATEGORY_ANALYTICS': {
      const { category } = payload;
      const event = category
        ? 'Clicked to edit category on guestlist'
        : 'Clicked to add category on guestlist';
      track({
        event,
        ...getGenericGuestCategoryProperties({ category, getState }),
      });
      break;
    }

    case 'UPDATED_GUEST_CATEGORY_ANALYTICS': {
      const { category, event } = payload;
      track({
        event,
        ...getGenericGuestCategoryProperties({ category, getState }),
      });
      break;
    }

    case 'FAILED_TO_DELETE_GUEST_CATEGORY_ANALYTICS': {
      const { category, error } = payload;
      track({
        event: 'Failed to delete guest category',
        ...getGenericGuestCategoryProperties({ category, getState }),
        failedToDeleteGuestlistCategoryReason: error.message,
      });
      break;
    }

    case 'CLICKED_TO_DELETE_CATEGORY_ANALYTICS': {
      const { category } = payload;
      track({
        event: 'Clicked to delete category on guestlist',
        ...getGenericGuestCategoryProperties({ category, getState }),
      });
      break;
    }

    case 'DELETED_CATEGORY_ANALYTICS': {
      const { category } = payload;
      track({
        event: 'Deleted category on guestlist',
        ...getGenericGuestCategoryProperties({ category, getState }),
      });
      break;
    }

    case 'CANCELLED_DELETE_GUEST_ANALYTICS':
      track({
        event: 'Cancelled delete guest',
        ...getGenericGuestProperties(getState),
      });
      break;

    case 'ADDED_GUEST_ANALYTICS': {
      const { guest, method } = payload as AddedGuestAnalyticsAction['payload'];
      track({
        event: 'Added guest',
        ...getGenericGuestProperties(getState, guest),
        ...(method !== 'inline' ? getGenericLinkedProperties(getState, guest) : {}),
        ...getAddGuestProperties(method, 'saveAndContinue'),
      });
      break;
    }
    case 'EDITED_GUEST_ANALYTICS': {
      const { guest, method, editedGuestAction } = payload;
      track({
        event: 'Edited guest',
        ...getGenericGuestProperties(getState, guest),
        ...getGenericLinkedProperties(getState, guest),
        ...getEditGuestProperties(method, editedGuestAction),
      });
      break;
    }

    case 'FAILED_TO_ADD_GUEST_ANALYTICS':
    case GuestlistActionTypes.SAVE_GUEST_ERROR: {
      const { edit, guest, error, method } = payload;
      track({
        event: edit ? 'Failed to add guest' : 'Failed to edit guest',
        ...getGenericGuestProperties(getState, guest),
        ...getGenericLinkedProperties(getState, guest),
        ...getEditGuestFailProperties(edit, method, error),
      });
      break;
    }

    case 'CLICKED_DELETE_GUEST_ANALYTICS':
      track({
        event: 'Clicked delete guest',
        ...getGenericGuestProperties(getState),
      });
      break;

    case 'DELETED_GUEST_ANALYTICS':
      track({
        event: 'Deleted guest',
        ...getGenericGuestProperties(getState),
      });
      break;

    case 'CLICKED_RESET_GUESTLIST_ANALYTICS':
      track({ event: 'Clicked reset guestlist' });
      break;

    case 'USED_GUESTLIST_FILTERS_ANALYTICS': {
      const { name, value } = payload;
      track({
        event: 'Used guestlist filters',
        ...resolveFilterSection(name, value),
      });
      break;
    }

    case 'TOGGLED_GUESTLIST_FILTERS_ANALYTICS': {
      track({
        event: 'Toggled guestlist filters',
        ...getGenericGuestProperties(getState),
        toggledGuestlistFiltersAction: payload ? 'show' : 'hide',
      });
      break;
    }

    case 'RESET_GUESTLIST_ANALYTICS':
      track({ event: 'Reset guestlist' });
      break;

    case 'TOGGLED_GUESTLIST_SEARCH_ANALYTICS': {
      const { analyticAction } = payload;
      track({
        event: 'Toggled guestlist search',
        toggledGuestlistSearchAction: analyticAction,
      });
      break;
    }

    case 'USED_GUESTLIST_SEARCH_ANALYTICS':
      track({ event: 'Used guestlist search' });
      break;

    case 'CANCELLED_RESET_GUESTLIST_ANALYTICS':
      track({ event: 'Cancelled reset guestlist' });
      break;

    case 'EXPORT_GUESTLIST_ANALYTICS':
      track({ event: 'Export guestlist' });
      break;

    case GuestlistAnalyticsActionTypes.CLICKED_ADD_GUESTS: {
      const {
        payload: { method },
      } = action as ClickedAddGuestsAnalyticsAction;
      track({
        event: 'Clicked add guest',
        clickedAddGuestMethod: method,
      });
      break;
    }

    case GuestlistAnalyticsActionTypes.ADDED_MULTIPLE_GUESTS: {
      const {
        payload: { category, count, method },
      } = action as AddedMultipleGuestsAnalyticsAction;
      track({
        event: 'Added multiple guests',
        ...guestBulkAddPropertiesGeneric({ category, count, method }),
      });
      break;
    }

    case GuestlistAnalyticsActionTypes.INITIALISED_GUESTLIST: {
      const {
        payload: { method },
      } = action as InitialisedGuestlistAnalyticsAction;
      track({
        event: 'Initialised guest list',
        guestListInitialisationMethod: method,
      });
      break;
    }

    case GuestlistAnalyticsActionTypes.TOGGLE_BULK_ADD_METHOD: {
      const {
        payload: { method },
      } = action as ToggleBulkAddGuestsMethodAnalyticsAction;
      track({
        event: 'Toggled bulk add guests type',
        toggledBulkAddGuestAction: method,
      });
      break;
    }

    case GuestlistAnalyticsActionTypes.OPENED_GUEST_COLLECTOR_POPUP: {
      track({
        event: 'Triggered collect guest info pop up',
        ...guestCollectorPopupGeneric(getState),
      });
      break;
    }

    case GuestlistAnalyticsActionTypes.CLOSED_GUEST_COLLECTOR_POPUP: {
      track({
        event: 'Closed collect guest info pop up',
        ...guestCollectorPopupGeneric(getState),
      });
      break;
    }

    case GuestlistAnalyticsActionTypes.CLICKED_TO_SHARE_GUEST_COLLECTOR_LINK: {
      track({
        event: 'Clicked to share guest info collector link',
        ...guestCollectorPopupGeneric(getState),
      });
      break;
    }

    case GuestlistAnalyticsActionTypes.SHARED_GUEST_COLLECTOR_LINK: {
      const {
        payload: { appChosen, url },
      } = action as SharedGuestCollectorLinkAnalyticsAction;
      track({
        event: 'Shared guest info collector link',
        ...guestCollectorPopupGeneric(getState),
        appChosen,
        guestCollectorLink: url,
      });
      break;
    }

    case GuestlistAnalyticsActionTypes.COPIED_GUEST_COLLECTOR_LINK: {
      track({
        event: 'Copied guest info collector link',
        ...guestCollectorPopupGeneric(getState),
      });
      break;
    }
  }
}
