import { useEffect } from 'react';
import { useMarket } from 'app-shared/lib/i18n/hooks/use-market';
import { setMarketCookie } from 'lib/i18n/utils/set-market-cookie';

export const useSetMarketCookie = () => {
  const market = useMarket();

  useEffect(() => {
    setMarketCookie(market.prefix);
  }, [market.prefix]);
};
