import articlesAnalytics from 'lib/articles/analytics';
import { WebAnalyticsContext } from 'lib/bbcommon/utils/bridebook-analytics';
import budgetAnalytics from 'lib/budget/analytics';
import checklistAnalytics from 'lib/checklist/analytics';
import datepickerAnalytics from 'lib/datepicker/analytics';
import feedbackAnalytics from 'lib/feedback/analytics-reducer';
import guestlistAnalytics from 'lib/guestlist/analytics';
import mobileAppAnalytics from 'lib/mobile-app/analytics';
import newOnboardingAnalytics from 'lib/onboarding-new/analytics-reducer';
import scrapbookAnalytics from 'lib/scrapbook/analytics';
import settingsAnalytics from 'lib/settings/analytics';
import shortlistAnalytics from 'lib/shortlist/analytics';
import supplierAnalytics from 'lib/supplier/analytics';
import { Action, IDeps } from 'lib/types';
import { setTimeoutWhenIdle } from 'lib/utils/set-timeout-when-idle';
import venueConfirmationAnalytics from 'lib/venue-confirmation/analytics';
import profileAnalytics from 'lib/weddings/analytics';
import aclAnalytics from './access-control/analytics';
import { getLocalisation } from './analytics-utils';
import appAnalytics from './app/analytics';
import authAnalytics from './auth/analytics';
import compareSuppliersAnalytics from './compare-suppliers/analytics';
import dashboardAnalytics from './dashboard/analytics';
import enquiriesAnalytics from './enquiries/analytics';
import inboxAnalytics from './inbox/analytics-reducer';
import lostLeadAnalytics from './lost-lead/analytics/reducer';
import quizAnalytics from './quiz/analytics';
import ratingSearchAnalytics from './rating-search/analytics/reducer';
import realWeddingsAnalytics from './real-weddings/analytics';
import searchAnalytics from './search/analytics';
import uiAnalytics from './ui/analytics';

const AnalyticsMiddleware =
  (bridebookAnalytics: WebAnalyticsContext) =>
  ({ getState: getCurrentState }: IDeps) =>
  (next: (action: Action) => void) =>
  (action: Action) => {
    if (!process.browser) return next(action);

    const state = getCurrentState();
    setTimeoutWhenIdle(() => {
      const getState = () => state;
      bridebookAnalytics.commonProps = () => ({
        ...getLocalisation(state),
        weddingId: state.weddings.profile.id || undefined,
        mobileApp: state?.app?.device?.isCordova,
        sessionId: state?.app?.device?.sessionId,
      });

      authAnalytics(action, bridebookAnalytics, getState);
      appAnalytics(action, bridebookAnalytics, getState);
      budgetAnalytics(action, bridebookAnalytics, getState);
      checklistAnalytics(action, bridebookAnalytics, getState);
      searchAnalytics(action, bridebookAnalytics, getState);
      articlesAnalytics(action, bridebookAnalytics, getState);
      shortlistAnalytics(action, bridebookAnalytics, getState);
      guestlistAnalytics(action, bridebookAnalytics, getState);
      profileAnalytics(action, bridebookAnalytics, getState);
      newOnboardingAnalytics(action, bridebookAnalytics, getState);
      supplierAnalytics(action, bridebookAnalytics, getState);
      uiAnalytics(action, bridebookAnalytics, getState);
      datepickerAnalytics(action, bridebookAnalytics, getState);
      mobileAppAnalytics(action, bridebookAnalytics);
      settingsAnalytics(action, bridebookAnalytics, getState);
      aclAnalytics(action, bridebookAnalytics, getState);
      scrapbookAnalytics(action, bridebookAnalytics, getState);
      venueConfirmationAnalytics(action, bridebookAnalytics, getState);
      enquiriesAnalytics(action, bridebookAnalytics, getState);
      dashboardAnalytics(action, bridebookAnalytics, getState);
      realWeddingsAnalytics(action, bridebookAnalytics, getState);
      inboxAnalytics(action, bridebookAnalytics, getState);
      lostLeadAnalytics(action, bridebookAnalytics, getState);
      quizAnalytics(action, bridebookAnalytics, getState);
      ratingSearchAnalytics(action, bridebookAnalytics);
      feedbackAnalytics(action, bridebookAnalytics, getState);
      compareSuppliersAnalytics(action, bridebookAnalytics);
    }, 3000);

    next(action);
  };

export default AnalyticsMiddleware;
