import produce from 'immer';
import { assocPath, compose, find, propEq } from 'ramda';
import { AuthActionTypes } from 'lib/auth/action-types';
import {
  ICreateNoteAction,
  IDeletePhotoSuccessAction,
  IEditFieldAction,
  IFirebaseSaveSuccessAction,
  IOnFirebaseDataAction,
  IOpenNotePopupAction,
  ScrapbookActionTypes,
} from 'lib/scrapbook/action-types';
import { Action, IReducersImmer } from 'lib/types';
import { getReducerActions } from 'lib/utils';
import { INote, IScrapbookState, ScrapbookNoteEditMode } from './types';

const initialState: IScrapbookState = {
  notes: [],
  mode: ScrapbookNoteEditMode.Closed,
  uninitialized: true,
  form: {
    note: null,
    dirty: false,
    shownError: null,
    error: null,
    showConfirmation: false,
  },
};

const reducers: IReducersImmer<IScrapbookState> = (draft) => ({
  [ScrapbookActionTypes.ON_FIREBASE_SCRAPBOOK_NOTES]: (action: IOnFirebaseDataAction) => {
    const notes = action.payload ? action.payload.notes : [];

    draft.uninitialized = false;
    draft.notes = notes || [];

    if (draft.form.note && draft.form.note.id) {
      draft.form.note = find(propEq('id', draft.form.note.id))(notes) as INote;
    }
  },

  [ScrapbookActionTypes.CREATE_EMPTY_NOTE]: (action: ICreateNoteAction) => {
    const { note } = action.payload;
    draft.form = {
      note: note as INote,
      dirty: false,
    };
  },

  [ScrapbookActionTypes.OPEN_NOTE_POPUP]: (action: IOpenNotePopupAction) => {
    const { id, mode } = action.payload;

    const note =
      id === 'empty_note' ? draft.form.note : (find(propEq('id', id))(draft.notes) as INote);

    draft.mode = mode;
    draft.form = {
      note,
      dirty: false,
    };
  },

  [ScrapbookActionTypes.CLOSE_NOTE_POPUP]: () => {
    draft.mode = ScrapbookNoteEditMode.Closed;
    draft.form = {
      note: null,
      dirty: false,
    };
  },

  [ScrapbookActionTypes.SWITCH_TO_VIEW]: () => {
    draft.mode = ScrapbookNoteEditMode.Closed;
  },

  [ScrapbookActionTypes.DELETE_NOTE_TRIGGER]: () => {
    draft.form.dirty = false;
    draft.form.showConfirmation = true;
  },

  [ScrapbookActionTypes.SAVE_NOTE_TO_FIREBASE_SUCCESS]: (action: IFirebaseSaveSuccessAction) => {
    const { note } = action.payload;
    draft.mode =
      draft.mode === ScrapbookNoteEditMode.Add
        ? ScrapbookNoteEditMode.Closed
        : ScrapbookNoteEditMode.View;
    draft.form = {
      note,
      dirty: false,
    };
  },

  [ScrapbookActionTypes.DELETE_PHOTO_FROM_NOTE_SUCCESS]: (action: IDeletePhotoSuccessAction) => {
    const { note } = action.payload;
    draft.form.note = note;
    // return assocPath<INote, IScrapbookState>(['form', 'note'], note)(draft);
  },

  [ScrapbookActionTypes.EDIT_FIELD]: (action: IEditFieldAction) => {
    const { fieldName, value } = action.payload;
    return compose(
      assocPath<string, IScrapbookState>(['form', 'note', fieldName], value),
      assocPath<boolean, IScrapbookState>(['form', 'dirty'], true),
    )({
      ...draft,
    });
  },

  [AuthActionTypes.SIGN_OUT_COMPLETED]: () => initialState,
});

const reducersActions = getReducerActions(reducers);

/*
	This is a wrapper function which runs a proper reducer from the object above.
*/
const reducer = (state: IScrapbookState = initialState, action: Action): IScrapbookState => {
  if (!reducersActions[action.type]) {
    return state;
  }

  try {
    return produce(state, (draft) => reducers(draft)[action.type](action));
  } catch (err) {
    return state;
  }
};

export default reducer;
