import { getI18n } from 'lib/i18n/getI18n';
import { EnquiryFormRecordType } from './types';

export const getEnquiryForm = (): EnquiryFormRecordType => ({
  fields: {
    partners: [],
    email: '',
    weddingDateDatePicker: null,
    location: { name: '' },
    message: '',
    guestsInitialTarget: 0,
    phone: '',
  },
  dirty: false,
  error: null,
  context: {
    contextName: '',
    buttonCaption: getI18n().t('common:cta.requestBrochure'),
    disclamer: '',
    message: '',
  },
});
