import { IUISupplier } from '@bridebook/toolbox/src/types';
import { WebAnalyticsContext } from 'lib/bbcommon/utils/bridebook-analytics';
import { Action, IApplicationState } from 'lib/types';
import {
  IClosedLostLeadModalAnalytics,
  IOpenedLostLeadModalAnalytics,
  ISelectedLostLeadFeedbackAnalytics,
  ISubmittedLostLeadFeedbackAnalytics,
  LostLeadAnalyticsAction,
} from './actions';

const getCommonProps = (supplier: IUISupplier, state: IApplicationState) => ({
  supplierId: supplier.id,
  supplierCategory: supplier.type,
  supplierName: supplier.name,
  supplierTier: supplier.tier,
  previousPath: state.app.previousPath,
});

export default function lostLeadAnalytics(
  action: Action,
  bridebookAnalytics: WebAnalyticsContext,
  getState: () => IApplicationState,
) {
  const { track } = bridebookAnalytics.getMethods('LostLead');

  switch (action.type) {
    case LostLeadAnalyticsAction.OpenedLostLeadModal: {
      const state = getState();
      const { payload } = action as IOpenedLostLeadModalAnalytics;
      const { location, supplier } = payload;

      track({
        ...getCommonProps(supplier, state),
        location,
        event: 'Triggered not interested pop up',
      });
      break;
    }

    case LostLeadAnalyticsAction.ClosedLostLeadModal: {
      const state = getState();
      const { payload: supplier } = action as IClosedLostLeadModalAnalytics;

      track({
        ...getCommonProps(supplier, state),
        event: 'Closed not interested pop up',
      });
      break;
    }

    case LostLeadAnalyticsAction.SubmittedLostLeadFeedback: {
      const state = getState();
      const { payload: supplier } = action as ISubmittedLostLeadFeedbackAnalytics;

      track({
        ...getCommonProps(supplier, state),
        event: 'Submitted feedback in not interested pop up',
      });
      break;
    }

    case LostLeadAnalyticsAction.SelectedLostLeadFeedback: {
      const state = getState();
      const { payload } = action as ISelectedLostLeadFeedbackAnalytics;
      const { feedback, feedbackType, supplier } = payload;

      track({
        ...getCommonProps(supplier, state),
        feedback,
        feedbackType,
        event: 'Selected feedback in not interested pop up',
      });
      break;
    }

    default:
      break;
  }
}
