import { createEpicMiddleware } from 'redux-observable';
import promiseMiddleware from 'redux-promise-middleware';
import serializeError from 'serialize-error';
import { SentryMinimal } from '@bridebook/toolbox/src/sentry';
import { Middleware, applyMiddleware } from '@reduxjs/toolkit';
import { Action, ActionCreatorWithDeps } from 'lib/types';
import configureDeps, { IConfigureDeps } from './configure-deps';
import configureEpics from './configure-epics';

// Redux logger for Node.js.
const nodeLogger: Middleware = () => (next) => (action) => next(action);
const injectMiddleware =
  (deps: IConfigureDeps) =>
  ({ dispatch, getState }: any) =>
  (next: any) =>
  (action: Action | ReturnType<ActionCreatorWithDeps<Action>>) => {
    const injectAction = () => {
      const depsToInject = { ...deps };
      const nextAction =
        // @ts-ignore FIXME
        typeof action === 'function' ? action({ ...depsToInject, dispatch, getState }) : action;
      if (nextAction?.type === 'NOOP') {
        // do nothing
      } else {
        next(nextAction);
      }
    };

    try {
      injectAction();
    } catch (e) {
      if (process.env.NODE_ENV === 'production') {
        SentryMinimal().captureException(e, {
          extra: serializeError(e),
          tags: { source: 'ReduxMiddleware' },
        });
      } else {
        /* eslint-disable no-console */
        console.error(
          'Actions must be plain objects. Use custom middleware for async actions',
          action,
          e,
        );
      }
    }
  };

const createReduxMiddleware = (platformMiddlewares: Middleware[]) => {
  const deps = configureDeps();
  const rootEpic = configureEpics(deps);
  const epicMiddleware = createEpicMiddleware();
  const middleware = [
    injectMiddleware(deps),
    ...platformMiddlewares,
    epicMiddleware,
    promiseMiddleware({ promiseTypeSuffixes: ['START', 'SUCCESS', 'ERROR'] }),
  ];

  // Logger must be the last middleware in chain.
  // enable for PRs
  if (process.env.NODE_ENV !== 'production') {
    /* eslint-disable-next-line */
    const { createLogger } = require('redux-logger');
    const isServer = !process.browser;
    const logger = isServer ? nodeLogger : createLogger({ collapsed: true });
    middleware.push(logger);
  }

  const appliedMiddleware = applyMiddleware(...middleware);
  return { middleware: appliedMiddleware, rootEpic, epicMiddleware };
};

export default createReduxMiddleware;
