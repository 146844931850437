import { AnalyticsContext, IDefaultProps, IFilterLabelProps } from '@bridebook/analytics';
import { SentryMinimal } from '@bridebook/toolbox/src/sentry';
import { setCookie } from 'app-shared/lib/utils/set-cookie';
import { CriticalWebEvents } from 'lib/analyticsTypes';
import { env } from 'lib/env';
import { isCordovaApp } from '../../utils';
import Queue from './queue';

declare let window: Window & typeof globalThis & { analyticsPool?: Queue<AEvent> };

interface AEvent {
  type: keyof WebAnalyticsContext | keyof IGetMethodsReturn;
  event?: string;
  name?: string;
  id?: string;
  properties?: any;
  traits?: any;
  user?: any;
}

/*
  Manually force add customer io userId cookie because capacitor doesn't allow third party cookie set
 */
const capacitorCustomerIoCookie = async (userId: string) => {
  setCookie('_cioid', userId);
};

export class WebAnalyticsContext extends AnalyticsContext {
  private _getCommonProps: () => any = () => ({});
  private _criticalEvents: string[];

  protected initialiseQueue(force: boolean = false) {
    if (process.browser && env.IS_VISUAL_TEST) {
      if (!window.analyticsPool || force) {
        window.analyticsPool = new Queue<AEvent>();
      }
    }
  }

  get commonProps() {
    return this._getCommonProps();
  }

  set commonProps(fn: () => any) {
    this._getCommonProps = fn;
  }

  constructor(analytics: any, defaultProperties: IDefaultProps) {
    super(analytics, defaultProperties);

    this._criticalEvents = Object.values(CriticalWebEvents);
  }

  isCriticalEvent(event: string) {
    return this._criticalEvents.includes(event);
  }

  alias(...args: Parameters<AnalyticsContext['alias']>) {
    if (process.browser && env.IS_VISUAL_TEST) {
      this.initialiseQueue();

      const analyticsPool = window.analyticsPool as Queue<AEvent>;
      const [id] = args;
      analyticsPool.push({ id, type: 'alias' });
    }

    try {
      super.alias(...args);
    } catch (e) {
      SentryMinimal().captureException(e, {
        tags: {
          source: 'analytics-alias',
        },
      });
    }
  }

  page(...args: Parameters<AnalyticsContext['page']>) {
    if (process.browser && env.IS_VISUAL_TEST) {
      this.initialiseQueue();

      const analyticsPool = window.analyticsPool as Queue<AEvent>;
      const [name, properties] = args;
      analyticsPool.push({ name, type: 'page', properties });
    }

    try {
      super.page(...args);
    } catch (e) {
      SentryMinimal().captureException(e, {
        tags: {
          source: 'analytics-page',
        },
      });
    }
  }

  getMethods(category: string, filterLabelProps?: IFilterLabelProps) {
    const methods = super.getMethods(category, filterLabelProps);

    if (process.browser) {
      const { track: parentTrack, identify: parentIdentify, identifyWithTrack } = methods;

      this.initialiseQueue();

      const analyticsPool = window.analyticsPool as Queue<AEvent>;

      const track = (...args: Parameters<typeof parentTrack>) => {
        const [properties, callback] = args;
        if (env.IS_VISUAL_TEST) {
          analyticsPool.push({ event: properties.event, type: 'track', properties });
        }

        return parentTrack({ ...this.commonProps, ...properties }, callback).catch((e) => {
          if (this.isCriticalEvent(properties.event)) {
            SentryMinimal().captureException(e, {
              tags: {
                source: 'analytics-track',
              },
            });
          }
        });
      };

      const identify = (...args: Parameters<typeof parentIdentify>) => {
        const [userId, traits, callback] = args;

        if (isCordovaApp()) {
          capacitorCustomerIoCookie(userId);
        }

        if (env.IS_VISUAL_TEST) {
          analyticsPool.push({ type: 'identify', traits });
        }

        return parentIdentify(userId, { ...this.commonProps, ...traits }, callback).catch((e) => {
          SentryMinimal().captureException(e, {
            tags: {
              source: 'analytics-identify',
            },
          });
        });
      };

      return { track, identify, identifyWithTrack: identifyWithTrack.bind({ track, identify }) };
    }

    return methods;
  }
}

export type IGetMethodsReturn = ReturnType<WebAnalyticsContext['getMethods']>;

export type ITrackFunction = IGetMethodsReturn['track'];
export type IIdentifyFunction = IGetMethodsReturn['identify'];
