import { selectMarketWithFallback } from 'lib/i18n/selectors';
import { isTodayTaskCompleted } from 'lib/task/selectors';
import { TTaskDefinitionUI } from 'lib/task/task-flow-engine';

export const createGenericTodayTaskDefinition: (
  definition: Pick<TTaskDefinitionUI, 'id' | 'name' | 'storage' | 'markets'>,
) => TTaskDefinitionUI = (definition) => ({
  ...definition,
  type: 'generic',
  isTaskCompleted: ({ getState }) => {
    const state = getState();
    const market = selectMarketWithFallback(state);

    if (
      !definition.markets ||
      (definition.markets && definition.markets.includes(market.country))
    ) {
      if (definition.storage === 'none') return false;
      return isTodayTaskCompleted(state, { id: definition.id, storage: definition.storage });
    }

    return true;
  },
});
