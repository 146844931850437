import produce from 'immer';
import { Action, IReducersImmer } from 'lib/types';
import { getReducerActions } from 'lib/utils';
import {
  AccessControlActionTypes,
  ChangeCollaboratorFormFieldAction,
  IInviteCollaboratorErrorAction,
  ResetInviteForm,
  SetInviteForm,
} from './action-types';
import { EmptyInviteForm } from './constants';
import { ICollaborationState } from './types';

const initialState: ICollaborationState = {
  inviteForm: EmptyInviteForm,
};

const reducers: IReducersImmer<ICollaborationState> = (draft) => ({
  [AccessControlActionTypes.CHANGE_COLLABORATOR_FORM_FIELD]: (
    action: ChangeCollaboratorFormFieldAction,
  ) => {
    const { name, value } = action.payload;

    if (name === 'email') {
      draft.inviteForm.fields.email = value;
    }
  },

  [AccessControlActionTypes.RESET_INVITE_FORM]: (action: ResetInviteForm) => {
    draft.inviteForm = { ...EmptyInviteForm, ...(action.payload || {}) };
  },

  [AccessControlActionTypes.SET_INVITE_FORM]: (action: SetInviteForm) => {
    Object.assign(draft.inviteForm, action.payload);
  },

  [AccessControlActionTypes.INVITE_COLLABORATOR_ERROR]: (
    action: IInviteCollaboratorErrorAction,
  ) => {
    draft.inviteForm.error = action.payload.error;
  },
});

const reducersActions = getReducerActions(reducers);

const reducer = (
  state: ICollaborationState = initialState,
  action: Action,
): ICollaborationState => {
  if (!reducersActions[action.type]) {
    return state;
  }

  try {
    return produce(state, (draft) => reducers(draft)[action.type](action));
  } catch (err) {
    return state;
  }
};

export default reducer;
