import { getPreviousPath } from 'lib/app/selectors';
import { getCollaboratorInvite } from 'lib/auth/selectors';
import { WebAnalyticsContext } from 'lib/bbcommon/utils/bridebook-analytics';
import {
  IArchivedEnquiryAnalytics,
  IClickedQuickResponse,
  IUnarchivedEnquiryAnalytics,
  InboxAnalyticsAction,
} from 'lib/inbox/analytics-actions';
import { Action, IApplicationState } from 'lib/types';

const filterLabelProps = (props: Record<string, any>) => {
  const newProps = { ...props };
  delete newProps.category;
  delete newProps.event;
  delete newProps.firstName;
  delete newProps.partnerName;
  delete newProps.value;
  return newProps;
};

export default function inboxAnalytics(
  { type, payload }: Action,
  bridebookAnalytics: WebAnalyticsContext,
  getState: () => IApplicationState,
) {
  const defaultCategory = 'Messages';

  const getGenericProps = () => {
    const state = getState();
    const collaborator = !!getCollaboratorInvite(state).nonceId?.length;
    const previousPath = getPreviousPath(state);
    const profileType = 'user';

    return {
      collaborator,
      previousPath,
      profileType,
    };
  };

  const track = (props: Record<string, any> = {}, category: string = defaultCategory) => {
    const { track } = bridebookAnalytics.getMethods(category, filterLabelProps);
    track({
      event: type,
      ...getGenericProps(),
      ...props,
    });
  };

  switch (type) {
    case InboxAnalyticsAction.ClickedQuickAction: {
      const { supplierWeddingId, supplier, quickActionText } = payload as IClickedQuickResponse;

      track({
        enquiryId: supplierWeddingId,
        supplierId: supplier.id,
        supplierCategory: supplier.type,
        supplierName: supplier.name,
        supplierTier: supplier.tier,
        quickActionText,
      });
      break;
    }

    case InboxAnalyticsAction.ArchivedEnquiry: {
      const { supplierWeddingId, supplier, archiveLocation } = payload as IArchivedEnquiryAnalytics;

      track({
        enquiryId: supplierWeddingId,
        supplierId: supplier.id,
        supplierCategory: supplier.type,
        supplierName: supplier.name,
        supplierTier: supplier.tier,
        archiveLocation,
      });
      break;
    }

    case InboxAnalyticsAction.UnarchivedEnquiry: {
      const { supplierWeddingId, supplier } = payload as IUnarchivedEnquiryAnalytics;

      track({
        enquiryId: supplierWeddingId,
        supplierId: supplier.id,
        supplierCategory: supplier.type,
        supplierName: supplier.name,
        supplierTier: supplier.tier,
      });
      break;
    }

    default:
      break;
  }
}
