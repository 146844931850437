import { ValidationError } from '@bridebook/toolbox/src';
import { getFeedbackSubject } from 'components/settings/feedback-popup/feedback-subject';
import { getFeedbackType } from 'components/settings/feedback-popup/feedback-type';
import { getLocalisation } from 'lib/analytics-utils';
import { getIsCollaborator } from 'lib/analytics-utils/get-is-collaborator';
import { SettingsActionTypes } from 'lib/settings/action-types';
import { Action, IApplicationState } from 'lib/types';
import serverSideTrack from 'lib/utils/server-side-track';
import { WebAnalyticsContext } from '../../bbcommon/utils/bridebook-analytics';
import { filterLabelProps } from '../../shortlist/analytics';
import {
  ChangedAppIconAnalyticsAction,
  ClickedAnalyticsAction,
  ClickedToUploadProfilePhotoAnalytics,
  CreateMicrositeErrorAnalyticsAction,
  CreateMicrositeSuccessAnalyticsAction,
  DeleteMicrositeSuccessAnalyticsAction,
  SelectPredefinedProfilePhotoAnalytics,
  SettingsAnalyticsActionTypes,
  ShareMicrositeAnalyticsAction,
  TriggerDeleteMicrositeAnalyticsAction,
  UploadProfilePhotoAnalytics,
} from './action-types';

const micrositePropertiesGeneric = ({
  getState,
  id,
}: {
  getState: () => IApplicationState;
  id: string;
}) => {
  const {
    weddings: {
      profile: { id: weddingId },
    },
  } = getState();
  return {
    weddingId,
    micrositeURL: id,
  };
};

export interface IFeedbackPropertiesGeneric {
  feedbackType: string;
  feedbackTag: string;
  feedbackDescription: string;
}

export const feedbackPropertiesGeneric = (
  getState: () => IApplicationState,
): IFeedbackPropertiesGeneric => {
  const {
    settings: {
      feedbackForm: { fields },
    },
  } = getState();

  return {
    feedbackType:
      getFeedbackType(false).find((type) => type.value === fields.feedbackType)?.label ||
      fields.feedbackType,
    feedbackTag:
      getFeedbackSubject(false).find((type) => type.value === fields.feedbackTag)?.label ||
      fields.feedbackTag,
    feedbackDescription: fields.feedbackDescription,
  };
};

const settingsAnalytics = (
  action: Action,
  bridebookAnalytics: WebAnalyticsContext,
  getState: () => IApplicationState,
): void => {
  const { type } = action;
  const { track } = bridebookAnalytics.getMethods('Settings', filterLabelProps);

  switch (type) {
    case SettingsAnalyticsActionTypes.CREATE_MICROSITE_SUCCESS_ANALYTICS: {
      const {
        payload: { id },
      } = action as CreateMicrositeSuccessAnalyticsAction;
      track({
        event: 'Generated microsite',
        category: 'Microsite',
        ...micrositePropertiesGeneric({ getState, id }),
      });
      break;
    }
    case SettingsAnalyticsActionTypes.CREATE_MICROSITE_ERROR_ANALYTICS: {
      const {
        payload: { id, error },
      } = action as CreateMicrositeErrorAnalyticsAction;
      const type = error instanceof ValidationError ? 'failedValidation' : 'notUnique';
      track({
        event: 'Failed to generate microsite',
        category: 'Microsite',
        ...micrositePropertiesGeneric({ getState, id }),
        failedToGenerateMicrositeReason: type,
      });
      break;
    }
    case SettingsAnalyticsActionTypes.TRIGGER_DELETE_MICROSITE_ANALYTICS: {
      const {
        payload: { id },
      } = action as TriggerDeleteMicrositeAnalyticsAction;
      track({
        event: 'Clicked to delete microsite',
        category: 'Microsite',
        ...micrositePropertiesGeneric({ getState, id }),
      });
      break;
    }
    case SettingsAnalyticsActionTypes.DELETE_MICROSITE_SUCCESS_ANALYTICS: {
      const {
        payload: { id },
      } = action as DeleteMicrositeSuccessAnalyticsAction;
      track({
        event: 'Deleted microsite',
        category: 'Microsite',
        ...micrositePropertiesGeneric({ getState, id }),
      });
      break;
    }
    case SettingsAnalyticsActionTypes.SHARE_MICROSITE_ANALYTICS: {
      const {
        payload: { id },
      } = action as ShareMicrositeAnalyticsAction;
      track({
        event: 'Clicked to share microsite',
        category: 'Microsite',
        ...micrositePropertiesGeneric({ getState, id }),
      });
      break;
    }
    case SettingsAnalyticsActionTypes.OPEN_APP_VERSION_POPUP_ANALYTICS: {
      track({
        event: 'Opened secret App Version popup',
        category: 'Settings',
      });
      break;
    }
    case SettingsAnalyticsActionTypes.USER_DELETE_PROFILE_PHOTO_ANALYTICS: {
      track({
        event: 'User deleted profile photo',
        category: 'Settings',
      });
      break;
    }
    case SettingsAnalyticsActionTypes.USER_UPLOAD_PROFILE_PHOTO_ANALYTICS: {
      const { payload } = action as UploadProfilePhotoAnalytics;
      track({
        event: 'User uploaded profile photo',
        category: 'Settings',
        uploadLocation: payload?.location ?? 'navigation',
      });
      break;
    }
    case SettingsAnalyticsActionTypes.USER_SELECTED_PREDEFINED_PROFILE_PHOTO: {
      const { payload } = action as SelectPredefinedProfilePhotoAnalytics;
      track({
        event: 'User selected predefined profile photo',
        category: 'Settings',
        ...payload,
        previousPath: getState().app.previousPath,
      });
      break;
    }
    case SettingsAnalyticsActionTypes.USER_CHANGED_APP_ICON_ANALYTICS: {
      const { payload } = action as ChangedAppIconAnalyticsAction;

      track({
        event: 'User changed app icon',
        category: 'Settings',
        ...payload,
      });
      break;
    }
    case SettingsAnalyticsActionTypes.USER_CLICKED_DELETE_ACCOUNT_ANALYTICS: {
      track({
        event: 'Clicked delete account',
        category: 'Settings',
      });
      break;
    }
    case 'TRIGGERED_ZENDESK_WIDGET_ANALYTICS': {
      track({ event: 'Triggered Zendesk widget', category: 'Feedback' });
      break;
    }
    case 'TRIGGERED_GET_HELP_WIDGET_ANALYTICS': {
      track({ event: 'Triggered get help widget', category: 'Feedback' });
      break;
    }
    case 'TRIGGERED_FEEDBACK_FORM_ANALYTICS': {
      track({ event: 'Triggered feedback form', category: 'Feedback' });
      break;
    }
    case SettingsAnalyticsActionTypes.USER_CLICKED_UPLOAD_PROFILE_PHOTO_ANALYTICS: {
      const state = getState();
      const { payload } = action as ClickedToUploadProfilePhotoAnalytics;
      const {
        app: { previousPath },
        users: { user },
        weddings: { profile },
      } = state;
      const userEmail = user?.contacts?.email;
      const weddingId = profile?.id;
      const collaborator = getIsCollaborator(state);

      track({
        event: 'Clicked to upload profile photo',
        uploadLocation: payload?.location ?? 'navigation',
        collaborator,
        previousPath,
        profileType: 'user',
        userEmail,
        weddingId,
        ...getLocalisation(state),
      });
      break;
    }
    case SettingsActionTypes.SEND_FEEDBACK: {
      const state = getState();
      const { user } = state.users;
      const userId = user ? user.id : null;

      if (userId) {
        serverSideTrack({
          state,
          event: 'Submitted feedback',
          specificEventProps: {
            category: 'Feedback',
            ...feedbackPropertiesGeneric(() => state),
          },
        });
      }
      break;
    }
    case SettingsAnalyticsActionTypes.CLICKED_ANALYTICS_ACTION: {
      const { payload } = action as ClickedAnalyticsAction;
      track({
        event: 'Clicked',
        ...payload,
      });
      break;
    }
    case SettingsAnalyticsActionTypes.TRIGGERED_INTERCEPT_POPUP: {
      track({
        event: 'Triggered intercept popup',
        category: 'reminder',
        popupCTA: 'Reminder to view settings page to change Bridebook app logo',
        popupVersion: 'pridebookInterceptPopup',
        popupLocation: 'firstTimeAppLoadInJune',
        mobileApp: true,
      });
      break;
    }
  }
};

export default settingsAnalytics;
