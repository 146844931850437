import { IUISupplier } from '@bridebook/toolbox/src/types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { NewOnboardingState } from 'lib/types';

const initialState: NewOnboardingState = {
  onboardingModalVisible: false,
  LIVE_22269_suppliersToContact: [],
};

const name = 'newOnboarding';

const newOnboardingSlice = createSlice({
  name,
  initialState,
  reducers: {
    toggleOnboardingModal: (state, action) => {
      state.onboardingModalVisible = action.payload;
    },
    LIVE_22269_setSuppliersToContact: (state, action: PayloadAction<IUISupplier[]>) => {
      state.LIVE_22269_suppliersToContact = action.payload;
    },
  },
});

export const { toggleOnboardingModal, LIVE_22269_setSuppliersToContact } =
  newOnboardingSlice.actions;
export default newOnboardingSlice.reducer;
