export enum FeedbackAnalyticsAction {
  RequestedFeedback = 'Requested feedback',
  SubmittedFeedback = 'Submitted feedback',
  TriggeredFeedbackForm = 'Triggered feedback form',
}

export type FeedbackRequestLocation = 'supplierProfilePage' | 'favouritesPage';
export type FeedbackRequestSection =
  | 'supplierNotes_photo'
  | 'supplierNotesBottomButton'
  | 'supplierNotesTopButton'
  | 'supplierNotesEditMenuButton'
  | 'supplierNotesInviteCollaboratorRatingWidget'
  | 'supplierProfile_photo';

export type SubmittedFeedbackSource =
  | 'supplierProfileRatingToast'
  | 'supplierNotesFavouritesPageRatingWidget';

export type SubmittedFeedbackMethod = 'preSetOption';

export type RequestFeedbackPayload = {
  requestedLink: string;
  requestedType: 'inviteCollaborator';
  supplierCategory: string;
  adminArea0: string;
  adminArea1: string;
  supplierId: string;
  supplierRegistered: boolean;
  supplierCountryCode: string;
  supplierCountryName: string;
  supplierTier: number;
} & (
  | { requestedLocation: 'supplierProfilePage'; requestedSection: 'supplierProfile_photo' }
  | {
      requestedLocation: 'favouritesPage';
      requestedSection: FeedbackRequestSection;
    }
);

export type SubmittedFeedbackPayload = {
  supplierCategory: string;
  adminArea0: string;
  adminArea1: string;
  supplierId: string;
  supplierRegistered: boolean;
  supplierCountryCode: string;
  supplierCountryName: string;
  supplierTier: number;
  feedbackSource: SubmittedFeedbackSource;
  feedbackMethod: SubmittedFeedbackMethod;
  feedbackDescription: 1 | 2 | 3;
};

const requestedFeedback = (payload: RequestFeedbackPayload) => ({
  type: FeedbackAnalyticsAction.RequestedFeedback,
  payload,
});

const submittedFeedback = (payload: SubmittedFeedbackPayload) => ({
  type: FeedbackAnalyticsAction.SubmittedFeedback,
  payload,
});

export type TriggeredFeedbackFormPayload = {
  feedbackSource: 'supplierProfileRatingToast' | 'supplierNotesFavouritesPageRatingWidget';
  supplierId: string;
};

const triggeredFeedbackForm = (payload: TriggeredFeedbackFormPayload) => ({
  type: FeedbackAnalyticsAction.TriggeredFeedbackForm,
  payload,
});

export const analytics = {
  requestedFeedback,
  submittedFeedback,
  triggeredFeedbackForm,
};
