import * as R from 'remeda';
import { extractArticleTags } from '@bridebook/toolbox/src';
import { IArticle as IRawArticle } from '@bridebook/toolbox/src/types';
import { getTagName } from 'lib/articles/utils';
import { getI18n } from 'lib/i18n/getI18n';
import { IArticle } from './types';

export const cloudinaryBaseUrl: string = 'https://res.cloudinary.com/bridebook/image/upload';

export const getCloudinaryTransformParams = (publicId: string, dpr: string = 'auto'): string => {
  const publicIdEsc = publicId.replace(/\//g, ':');

  return `u_${publicIdEsc},h_1.0,c_fill,o_70,e_brightness_hsb:-50,f_auto/w_800,f_auto,q_auto,dpr_${dpr}`;
};

/**
 * format cloudinary url
 * output formatted cloudinary url
 * @method formatCloudinaryUrl
 * @param {string} url - url to format
 */
export const formatCloudinaryUrl = (url: string, dpr: string = 'auto'): string => {
  const matches = CLOUDINARY_REGEX.exec(url);
  if (matches) {
    const publicId = matches[4];
    const transform = getCloudinaryTransformParams(publicId, dpr);
    const ext = 'jpg';
    url = `${cloudinaryBaseUrl}/${transform}/${publicId}.${ext}`;
  }

  return url;
};

export const getTagsList = (article: IArticle | null) => {
  const tagsListPath = ['terms', 'post_tag'] as const;

  return R.pipe(
    article,
    R.pathOr(tagsListPath, []),
    R.filter((tag) => tag && tag.slug !== 'all'),
    R.map((tag) => ({
      ...tag,
      name: getTagName(tag.slug),
    })),
    R.filter((tag) => !!tag.name && !!tag.slug),
  );
};

export const getArticleShareImage = (formattedContent: string): string => {
  const imgRegex = /src="(https?:\/\/.*?\.(?:jpg|png))"/;
  const imgSrc: Array<string> | null = imgRegex.exec(formattedContent);

  return (imgSrc && imgSrc[1]) || '';
};

/**
 * checks if article is real wedding article
 * @method isRealWeddingArticle
 * @param {IArticle} article - article object
 */
export const isRealWeddingArticle = (article: IArticle | IRawArticle): boolean =>
  extractArticleTags(article).includes('real-wedding');

/**
 * extracts simplified article category from article object
 * @method extractArticleCategory
 * @param {IArticle} article - article object
 * @returns {String} - category of article
 */
export const extractArticleCategory = (article: IArticle | IRawArticle): string =>
  isRealWeddingArticle(article)
    ? getI18n().t('articles:tagName.realWedding', 'Real Wedding')
    : getI18n().t('articles:tagName.expertAdvice', 'Expert Advice');

const CLOUDINARY_REGEX =
  /^.+\.cloudinary\.com\/(?:[^\/]+\/)(?:(image|video)\/)?(?:(upload|fetch)\/)?(?:(?:[^_/]+_[^,/]+,?)*\/)?(?:v(\d+|\w{1,2})\/)?([^\.^\s]+)(?:\.(jpg|jpeg|png|gif|bmp))?$/;

/**
 * get article thumbnail id
 * gets specified article thumbnail public id
 * @method getArticleThumbId
 * @param {Object} featuredImage - thumbnail object to get id for
 */
export const getArticleThumbId = (featuredImage: IArticle['featured_image']) => {
  let theUrl = '';
  const isCloudinaryUrl = CLOUDINARY_REGEX.exec(featuredImage?.source || '');
  // Return absolute url if it's not Cloudinary
  if (!isCloudinaryUrl && (featuredImage?.source || '').startsWith('https://')) {
    return featuredImage?.source || '';
  }
  if (featuredImage && featuredImage.source) {
    const fileSplit = featuredImage.source.split('/');
    const uploadId = fileSplit.splice(6, 8).join('/');
    theUrl = uploadId.replace(/\.(jpg|png|gif)/, '');
  } else {
    theUrl = 'v1450745725/assets/ph';
  }

  return theUrl;
};

export const getArticlePropertiesGeneric = ({
  article,
}: {
  article: IArticle;
}): {
  articleTitle: string;
  articleTags: Array<string>;
  articleCategory: string;
} => {
  const articleTags = extractArticleTags(article);
  return {
    articleTitle: article.title,
    articleTags,
    articleCategory: extractArticleCategory(article),
  };
};
