import { authenticatedFetch } from '@bridebook/toolbox/src/api/auth/authenticated-fetch';
import { getLocationName } from 'lib/analytics-utils';
import { WebAnalyticsContext } from 'lib/bbcommon/utils/bridebook-analytics';
import { getShortlistedSupplierDetails } from 'lib/shortlist/selectors';
import { Action, IApplicationState, TUIShortlistSupplier } from 'lib/types';
import { ConfirmVenueAction, VenueConfirmActionTypes } from 'lib/venue-confirmation/action-types';
import {
  CloseSupplierInterestConfirmationAnalyticsAction,
  ClosedBookedSupplierPopupAnalyticsActionAction,
  CompletedVenueSourceStepAnalyticsAction,
  ConfirmOrDenyInterestAnalyticsAction,
  OpenSupplierInterestConfirmationAnalyticsAction,
  TriggeredVenueConfirmationPopupAnalyticsAction,
  VenueConfirmAnalyticsActionTypes,
} from './action-types';

const getCommonProps = (supplier: TUIShortlistSupplier, state: IApplicationState) => ({
  supplierId: supplier.id,
  supplierCategory: supplier.type,
  supplierName: supplier.name,
  supplierTier: supplier.tier,
  previousPath: state.app.previousPath,
});

const settingsAnalytics = (
  action: Action,
  bridebookAnalytics: WebAnalyticsContext,
  getState: () => IApplicationState,
) => {
  switch (action.type) {
    case VenueConfirmAnalyticsActionTypes.OPEN_SUPPLIER_INTEREST_CONFIRMATION: {
      const state = getState();
      const { payload: supplierId } = action as OpenSupplierInterestConfirmationAnalyticsAction;
      const supplier = getShortlistedSupplierDetails(state, supplierId);

      // without supplier, analytics data is not usable
      if (!supplier) break;

      const { track } = bridebookAnalytics.getMethods('Shortlist');
      track({
        event: 'Triggered venue specific pop-up',
        venueSpecificPopupLocation: 'venueConfirmationPopup',
        ...getCommonProps(supplier, state),
      });
      break;
    }

    case VenueConfirmAnalyticsActionTypes.CONFIRM_OR_DENY_SUPPLIER_INTEREST: {
      const state = getState();
      const { payload } = action as ConfirmOrDenyInterestAnalyticsAction;
      const { supplier, isConfirmed } = payload;

      const { track } = bridebookAnalytics.getMethods('Shortlist');

      track({
        event: 'Completed venue specific pop-up',
        venueSpecificPopupLocation: 'venueConfirmationPopup',
        venueSpecificConfirmationMethod: isConfirmed ? 'Yes' : 'No',
        ...getCommonProps(supplier, state),
      });
      break;
    }

    case VenueConfirmAnalyticsActionTypes.CLOSE_SUPPLIER_INTEREST_CONFIRMATION: {
      const state = getState();
      const { payload: supplier } = action as CloseSupplierInterestConfirmationAnalyticsAction;
      const { track } = bridebookAnalytics.getMethods('Shortlist');

      track({
        event: 'Closed venue specific pop-up',
        venueSpecificPopupLocation: 'venueConfirmationPopup',
        ...getCommonProps(supplier, state),
      });
      break;
    }

    case VenueConfirmAnalyticsActionTypes.TRIGGERED_BOOKING_CONFIRMATION_POPUP: {
      const {
        payload: { popupCTA, popupVersion, supplierCategory, supplierId, supplierName },
      } = action as TriggeredVenueConfirmationPopupAnalyticsAction;
      const state = getState();

      const { track } = bridebookAnalytics.getMethods('Shortlist');
      track({
        event: 'Triggered booking confirmation popup',
        popupCTA,
        popupVersion,
        popupLocation: state.venueConfirmation.triggerLocation,
        supplierCategory,
        ...(supplierId && { supplierId }),
        ...(supplierName && { supplierName }),
      });
      break;
    }

    case VenueConfirmAnalyticsActionTypes.COMPLETED_BOOKING_CONFIRMATION_POPUP: {
      const {
        payload: { popupCTA, popupVersion, supplierCategory, supplierId, supplierName },
      } = action as TriggeredVenueConfirmationPopupAnalyticsAction;
      const state = getState();

      const { track } = bridebookAnalytics.getMethods('Shortlist');
      track({
        event: 'Completed booking confirmation popup',
        popupCTA,
        popupVersion,
        popupLocation: state.venueConfirmation.triggerLocation,
        supplierCategory,
        ...(supplierId && { supplierId }),
        ...(supplierName && { supplierName }),
      });
      break;
    }

    case VenueConfirmAnalyticsActionTypes.CLOSED_BOOKED_SUPPLIER_POPUP: {
      const {
        payload: {
          popupCTA,
          popupVersion,
          supplierCategory,
          supplierId,
          supplierName,
          popupLocation,
        },
      } = action as ClosedBookedSupplierPopupAnalyticsActionAction;
      const { track } = bridebookAnalytics.getMethods('Shortlist');
      track({
        event: 'Closed booking confirmation popup',
        popupLocation: popupLocation ?? getLocationName(getState()),
        popupCTA,
        popupVersion,
        supplierCategory,
        ...(supplierId && { supplierId }),
        ...(supplierName && { supplierName }),
      });
      break;
    }

    case VenueConfirmAnalyticsActionTypes.SHOW_VENUE_SOURCE_STEP: {
      const { track } = bridebookAnalytics.getMethods('Shortlist');

      track({
        event: 'Triggered how did you discover your venue pop up',
        discoverVenueTriggeringLocation: getLocationName(getState()),
      });
      break;
    }

    case VenueConfirmAnalyticsActionTypes.COMPLETED_VENUE_SOURCE_STEP: {
      const { payload } = action as CompletedVenueSourceStepAnalyticsAction;
      const { track } = bridebookAnalytics.getMethods('Shortlist');

      track({
        event: 'Completed how did you discover your venue pop up',
        discoverVenueAnswer: payload,
        discoverVenueTriggeringLocation: getLocationName(getState()),
      });
      break;
    }

    case VenueConfirmActionTypes.CONFIRM_VENUE: {
      const { payload } = action as ConfirmVenueAction;
      authenticatedFetch('/api/a/t/confirmed-venue-and-date', {
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        method: 'POST',
        body: JSON.stringify(payload),
      });
      break;
    }

    default:
  }
};

export default settingsAnalytics;
