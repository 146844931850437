export enum RatingSearchAnalyticsAction {
  TriggeredRatingModal = '[Analytics] Triggered rating modal',
  SubmittedFeedback = '[Analytics] Submitted feedback in rating modal',
  ClosedRatingModal = '[Analytics] Closed rating modal',
}

export type FeedbackMethod = 'preSetOption' | 'textBox';

export const closedRatingModalAnalytics = () => ({
  type: RatingSearchAnalyticsAction.ClosedRatingModal,
});

export const triggeredRatingModalAnalytics = () => ({
  type: RatingSearchAnalyticsAction.TriggeredRatingModal,
});

export const submittedFeedbackRatingModalAnalytics = (
  feedback: string | number,
  feedbackMethod: FeedbackMethod,
) => ({
  type: RatingSearchAnalyticsAction.SubmittedFeedback,
  payload: { feedback, feedbackMethod },
});

export type ISubmittedFeedbackRatingModalAnalytics = ReturnType<
  typeof submittedFeedbackRatingModalAnalytics
>;
