import { mergeDeepLeft, omit, pathOr, values } from 'ramda';
import { ScrapbookActionTypes } from 'lib/scrapbook/action-types';
import { INote, IScrapbookPhoto } from 'lib/scrapbook/types';
import { Action, IApplicationState } from 'lib/types';
import { WebAnalyticsContext } from '../bbcommon/utils/bridebook-analytics';
import { getNoteById } from './utils';

interface ScrapbookNoteDetailsGeneric {
  scrapbookNoteId: string;
  scrapbookNoteTitle: string;
  scrapbookNotePhotos: string;
  scrapbookNoteCreatedAt: number;
  scrapbookNoteLastEdited: number;
}

const scrapbookNoteDetailsGeneric = (note: INote): ScrapbookNoteDetailsGeneric => ({
  scrapbookNoteId: note.id || '',
  scrapbookNoteTitle: note.title || '',
  scrapbookNotePhotos: JSON.stringify(values(note.photos || {})),
  scrapbookNoteCreatedAt: typeof note.createdAt === 'number' ? note.createdAt : 0,
  scrapbookNoteLastEdited: typeof note.updatedAt === 'number' ? note.updatedAt : 0,
});

interface PhotoPropertiesGeneric {
  photoUrl: string;
}

const photoPropertiesGeneric = (photo: IScrapbookPhoto): PhotoPropertiesGeneric => ({
  photoUrl: photo.path || '',
});

const scrapbookAnalytics = (
  { type, payload }: Action,
  bridebookAnalytics: WebAnalyticsContext,
  getState: () => IApplicationState,
): void => {
  const { track } = bridebookAnalytics.getMethods('Scrapbook');

  switch (type) {
    case ScrapbookActionTypes.ADD_NOTE_TRIGGER_ANALYTICS: {
      track({
        event: 'Clicked add note on scrapbook',
        category: 'Scrapbook',
      });
      break;
    }
    case ScrapbookActionTypes.EDIT_NOTE_TRIGGER_ANALYTICS: {
      const note = pathOr({}, ['note'], payload) as INote;
      track({
        event: 'Clicked edit note on scrapbook',
        category: 'Scrapbook',
        ...scrapbookNoteDetailsGeneric(note),
      });
      break;
    }
    case ScrapbookActionTypes.ADDED_NOTE_ANALYTICS: {
      const note = pathOr({}, ['note'], payload) as INote;

      track({
        event: 'Added note to scrapbook',
        category: 'Scrapbook',
        ...scrapbookNoteDetailsGeneric(note),
      });
      break;
    }
    case ScrapbookActionTypes.EDITED_NOTE_ANALYTICS: {
      const note = pathOr({}, ['note'], payload) as INote;

      track({
        event: 'Edited note on scrapbook',
        category: 'Scrapbook',
        ...scrapbookNoteDetailsGeneric(note),
      });
      break;
    }
    case ScrapbookActionTypes.DELETE_NOTE_TRIGGER_ANALYTICS: {
      const {
        scrapbook: { notes },
      } = getState();
      const { id } = payload;
      const note = getNoteById(notes, id);

      track({
        event: 'Clicked delete note on scrapbook',
        category: 'Scrapbook',
        ...scrapbookNoteDetailsGeneric(note as INote),
      });
      break;
    }
    case ScrapbookActionTypes.DELETE_NOTE_ANALYTICS: {
      const {
        scrapbook: { notes },
      } = getState();
      const { id } = payload;
      const note = getNoteById(notes, id);

      track({
        event: 'Deleted note on scrapbook',
        category: 'Scrapbook',
        ...scrapbookNoteDetailsGeneric(note as INote),
      });
      break;
    }
    case ScrapbookActionTypes.OPEN_NOTE_TRIGGER_ANALYTICS: {
      const {
        scrapbook: { notes },
      } = getState();
      const { id } = payload;
      const note = getNoteById(notes, id);

      track({
        event: 'Opened note on scrapbook',
        category: 'Scrapbook',
        ...scrapbookNoteDetailsGeneric(note as INote),
      });
      break;
    }
    case ScrapbookActionTypes.ADD_PHOTO_TO_NOTE_ANALYTICS: {
      const {
        scrapbook: { form },
      } = getState();
      const { photo } = payload;
      const note = form.note || {};

      track({
        event: 'Added photo to note on scrapbook',
        category: 'Scrapbook',
        ...scrapbookNoteDetailsGeneric(note as INote),
        ...photoPropertiesGeneric(photo),
      });
      break;
    }

    case ScrapbookActionTypes.DELETE_PHOTO_FROM_NOTE_ANALYTICS: {
      const { photo, note } = payload;

      // note is read-only
      const noteCopy = mergeDeepLeft(note, {});
      noteCopy.photos = omit([photo.id], note.photos || {});

      track({
        event: 'Deleted photo from note on scrapbook',
        category: 'Scrapbook',
        ...scrapbookNoteDetailsGeneric(noteCopy),
        ...photoPropertiesGeneric(photo),
      });
      break;
    }

    case ScrapbookActionTypes.ADD_PHOTO_TO_NOTE_ERROR_ANALYTICS: {
      const {
        scrapbook: { form },
      } = getState();
      const { error, photo = {} } = payload;
      const note = form.note || {};

      track({
        event: 'Failed to add photo to note on scrapbook',
        category: 'Scrapbook',
        ...scrapbookNoteDetailsGeneric(note as INote),
        ...photoPropertiesGeneric(photo),
        failedToAddPhotoReason: error || '',
      });
      break;
    }

    default:
      break;
  }
};

export default scrapbookAnalytics;
