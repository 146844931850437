import { Slug } from '@bridebook/toolbox/src/types';

export const getListOfRemainingSuppliers = (
  supplierTypesByBookingPriority: Slug[],
  booked: Slug[],
  isGroomOnly: boolean,
  isBrideOnly: boolean,
): Slug[] => {
  const isSupplierTypeBooked = (slug: Slug): boolean => booked.includes(slug) || false;

  let remainingSuppliers = supplierTypesByBookingPriority.filter(
    (supplier) => !isSupplierTypeBooked(supplier),
  );

  if (isGroomOnly) {
    remainingSuppliers = remainingSuppliers.filter((supplier) => supplier !== 'dress');
  } else if (isBrideOnly) {
    remainingSuppliers = remainingSuppliers.filter((supplier) => supplier !== 'menswear');
  }

  return remainingSuppliers;
};
