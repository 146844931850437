import produce from 'immer';
import { Action, IReducersImmer } from 'lib/types';
import { getReducerActions } from 'lib/utils';
import {
  IGetVenuerexModalStateAction,
  IToggleGoToVenuerexModalAction,
  VenuerexActionTypes,
} from './action-types';
import { IVenuerexState } from './types';
import venuerexModalStateInitial from './venuerex-modal-state';

const initialState: IVenuerexState = {
  showGoToVenuerexPageModal: false,
  venuerexModalState: venuerexModalStateInitial,
};

const reducers: IReducersImmer<IVenuerexState> = (draft) => ({
  [VenuerexActionTypes.TOGGLE_GO_TO_VENUEREX_MODAL]: (action: IToggleGoToVenuerexModalAction) => {
    draft.showGoToVenuerexPageModal = action.payload;
  },

  [VenuerexActionTypes.GET_VENUEREX_MODAL_STATE]: (action: IGetVenuerexModalStateAction) => {
    draft.venuerexModalState = action.payload;
  },
});

const reducersActions = getReducerActions(reducers);

/*
	This is a wrapper function which runs a proper reducer from the object above.
*/
const reducer = (state: IVenuerexState = initialState, action: Action): IVenuerexState => {
  if (!reducersActions[action.type]) {
    return state;
  }

  try {
    return produce(state, (draft) => reducers(draft)[action.type](action));
  } catch (err) {
    return state;
  }
};

export default reducer;
