import { UserInfo } from 'firebase/auth';
import { AuthProviders } from 'lib/auth/types';
import { ProvidersType } from 'lib/users/types';

export const Provider: UserInfo = {
  email: '',
  providerId: '',
  displayName: '',
  phoneNumber: null,
  photoURL: null,
  uid: '',
};

export const Providers: ProvidersType = {
  [AuthProviders.APPLE]: Provider,
  [AuthProviders.FACEBOOK]: Provider,
  [AuthProviders.GOOGLE]: Provider,
  [AuthProviders.PASSWORD]: Provider,
};
