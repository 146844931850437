import type { ICard } from '@bridebook/models/source/models/Countries/Cards.types';
import { DashboardAnalyticsActions } from './types';

export const userUploadedCoverPhotoAnalytics = () => ({
  type: DashboardAnalyticsActions.USER_UPLOADED_COVER_PHOTO_ANALYTICS,
});

export const triggeredConfettiAnalytics = () => ({
  type: DashboardAnalyticsActions.TRIGGERED_CONFETTI,
});

export const triggeredInviteCollaborationBannerAnalytics = () => ({
  type: DashboardAnalyticsActions.TRIGGERED_INVITE_COLLABORATION_BANNER_ANALYTICS,
});

export const featureCardClickedAnalytics = (payload: ICard & { url: string }) => ({
  type: DashboardAnalyticsActions.FEATURE_CARD_CLICKED_ANALYTICS,
  payload,
});

export const featureCardViewedAnalytics = (payload: ICard) => ({
  type: DashboardAnalyticsActions.FEATURE_CARD_VIEWED_ANALYTICS,
  payload,
});

export const todaysTaskClickedAnalytics = (payload: ICard & { name: string }) => ({
  type: DashboardAnalyticsActions.TODAYS_TASK_CLICKED_ANALYTICS,
  payload,
});

export type TaskTileDataAnalytics = {
  id: string;
  name: string;
  title: string;
};
export const scrolledThroughTodaysTasksAnalytics = (payload: {
  current: TaskTileDataAnalytics;
  next: TaskTileDataAnalytics;
}) => ({
  type: DashboardAnalyticsActions.SCROLLED_THROUGH_TODAYS_TASKS_ANALYTICS,
  payload,
});

export const newTodaysTaskSessionAnalytics = (payload: { sessionNumber: number }) => ({
  type: DashboardAnalyticsActions.NEW_TODAYS_TASK_SESSION_ANALYTICS,
  payload,
});

export const todaysTaskViewedAnalytics = (payload: TaskTileDataAnalytics) => ({
  type: DashboardAnalyticsActions.TODAYS_TASK_VIEWED_ANALYTICS,
  payload,
});

export const clickedToAddWeddingDetailsToTheCountdownWidget = () => ({
  type: DashboardAnalyticsActions.CLICKED_TO_ADD_WEDDING_DETAILS_TO_THE_COUNTDOWN_WIDGET,
});
