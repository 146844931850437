export interface IFeedbackFormField {
  feedbackType: string;
  feedbackTag: string;
  feedbackDescription: string;
}

export interface IFeedbackForm {
  fields: IFeedbackFormField;
  error: Error | null;
}

const FeedbackForm: IFeedbackForm = {
  fields: {
    feedbackType: '',
    feedbackTag: '',
    feedbackDescription: '',
  },
  error: null,
};

export default FeedbackForm;
