import produce from 'immer';
import { merge } from 'ramda';
import { Action } from 'redux';
import { recursiveSerializeTimestamps } from '@bridebook/toolbox/src/serialize-timestamps';
import {
  AuthActionTypes,
  IUpdateProfilePhotoUploadProgress,
  UserSetupCompletedAction,
} from 'lib/auth/action-types';
import { AuthProviders } from 'lib/auth/types';
import {
  IOnUserListenerAction,
  ISaveUserProviderAction,
  ISetPartnerNamesFormAction,
  UserActionTypes,
} from 'lib/users/action-types';
import { IUsersState } from 'lib/users/types';
import { getReducerActions } from 'lib/utils';
import { IUpdateWeddingAction, WeddingActionTypes } from 'lib/weddings/action-types';
import PartnerNamesForm from './partner-names-form';
import { Provider, Providers } from './providers';
import UserAccessControl from './user-access-control';

const initialState: IUsersState = {
  partnerNamesForm: PartnerNamesForm,
  user: null,
  providers: Providers,
  userAccessControl: UserAccessControl,
  profilePhotoUploadProgress: null,
};

const reducers: any = (draft: IUsersState) => ({
  [AuthActionTypes.USER_SETUP_COMPLETED]: (action: UserSetupCompletedAction) => {
    draft.user = recursiveSerializeTimestamps(action.payload.user);
  },

  [UserActionTypes.SET_PARTNER_NAMES_FORM_FIELD]: (action: ISetPartnerNamesFormAction) => {
    const index = action.payload.isPartner ? 1 : 0;
    draft.partnerNamesForm.partners[index] = action.payload.name;
  },

  [UserActionTypes.ON_USER_LISTENER]: (action: IOnUserListenerAction) => {
    if (action.payload) {
      draft.user = recursiveSerializeTimestamps(action.payload);
    }
  },

  [WeddingActionTypes.UPDATE_WEDDING]: (action: IUpdateWeddingAction) => {
    const { partners } = action.payload.wedding;
    draft.partnerNamesForm.partners[0] = partners[0] || '';
    draft.partnerNamesForm.partners[1] = partners[1] || '';
  },

  [UserActionTypes.SAVE_USER_PROVIDER]: (action: ISaveUserProviderAction) => {
    const providerId = action.payload.providerId;
    if (
      providerId === AuthProviders.FACEBOOK ||
      providerId === AuthProviders.GOOGLE ||
      providerId === AuthProviders.PASSWORD
    ) {
      draft.providers[providerId] = merge(Provider, action.payload);
    }
  },

  [UserActionTypes.UPDATE_PROFILE_PHOTO_UPLOAD_PROGRESS]: (
    action: IUpdateProfilePhotoUploadProgress,
  ) => {
    draft.profilePhotoUploadProgress = action.payload.progress;
  },

  // Set users to initial state upon sign out
  [AuthActionTypes.SIGN_OUT_COMPLETED]: () => initialState,
});

const reducersActions = getReducerActions(reducers);

/*
  This is a wrapper function which runs a proper reducer from the object above.
*/
const reducer = (state: IUsersState = initialState, action: Action): IUsersState => {
  if (!reducersActions[action.type]) {
    return state;
  }

  try {
    return produce(state, (draft) => reducers(draft)[action.type](action));
  } catch (err) {
    return state;
  }
};

export default reducer;
