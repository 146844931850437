import { INotificationMode } from 'lib/bbcommon/types';
import { IFeedbackForm } from 'lib/settings/feedback-form';
import { BridebookError } from 'lib/types';

export interface IChangeCorrespondenceEmailForm {
  disabled: boolean;
  error: BridebookError | null;
  fields: { email: string };
}

export interface SettingsState {
  linkAccountsError: Error | null;
  changeCorrespondenceEmailForm: IChangeCorrespondenceEmailForm;
  resetPasswordSuccess: boolean;
  resetPasswordStart: boolean;
  showFeedbackPopup: boolean;
  feedbackForm: IFeedbackForm;
  userDeletionStatus: UserDeletionStatus;
  showLocationChangeModal: boolean;
  changeWeddingCountryStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
}

export interface INotification {
  type: INotificationMode;
  message: string;
}

export enum UserDeletionStatus {
  Disabled,
  Success,
  Pending,
  Error,
}

export enum SettingsSection {
  AccountDetails = 'account-details',
  WeddingDetails = 'wedding-details',
  ShareYourWedding = 'share-your-wedding',
  Notifications = 'notifications',
  Support = 'support',
  Dev = 'dev',
}

export type TSettingsQuery = {
  params?: [SettingsSection | 'invite-collaborator' | 'manage-collaborators'];
};
