import { serverTimestamp } from 'firebase/firestore';
import { Users, Weddings } from '@bridebook/models';
import { Tasks as UsersTasks } from '@bridebook/models/source/models/Users/Tasks';
import { ITask as IUserTask } from '@bridebook/models/source/models/Users/Tasks.types';
import { Tasks as WeddingTasks } from '@bridebook/models/source/models/Weddings/Tasks';
import { ITask as IWeddingTask } from '@bridebook/models/source/models/Weddings/Tasks.types';
import gazetteer from '@bridebook/toolbox/src/gazetteer';
import { SentryMinimal } from '@bridebook/toolbox/src/sentry';
import { taskSlice } from 'lib/task/slice';
import {
  TTaskDefinitionUI,
  getCurrentSession,
  getNextTaskFlowInstance,
  getPreviousTaskFlowInstance,
  getTaskFlowInstance,
  migrateToNewTaskFlowDefinition,
  saveTaskFlowInstance,
  signalTaskFlow,
} from 'lib/task/task-flow-engine';
import { IDeps } from 'lib/types';
import { selectCurrentUser } from 'lib/users/selectors';
import { assertState } from 'lib/utils/assertState';
import { getWeddingProfileId } from 'lib/weddings/selectors';
import { newTodaysTaskSessionAnalytics } from '../dashboard/analytics/actions';
import { noopAction } from '../utils';
import { checkIsNewSession } from './check-is-new-session';
import { getTaskFlowDefinitionUI } from './hooks/query/use-today-task-flow-definition';

export const initializeTaskFlows =
  () =>
  ({ getState, dispatch }: IDeps) => {
    const weddingCountry = getState().weddings.profile.l10n.country;
    const market = gazetteer.getMarketByCountry(weddingCountry);
    const taskFlowApiDefinition = getState().task.todayTaskFlowDefinition;
    assertState(taskFlowApiDefinition, 'loaded');
    const taskFlowDefinition = getTaskFlowDefinitionUI(taskFlowApiDefinition.data);
    try {
      const previousTaskFlowInstance = getPreviousTaskFlowInstance(taskFlowDefinition, {
        getState,
        market,
      });

      const nextTaskFlowInstance = getTaskFlowInstance(taskFlowDefinition, {
        getState,
        market,
      });

      dispatch(taskFlowsInitialized({ todayTaskFlowInstance: nextTaskFlowInstance }));

      if (checkIsNewSession(previousTaskFlowInstance, nextTaskFlowInstance)) {
        dispatch(
          newTodaysTaskSessionAnalytics({ sessionNumber: nextTaskFlowInstance.currentSession.id }),
        );
      }
      return noopAction();
    } catch (e) {
      SentryMinimal().captureException(e, {
        tags: {
          source: 'taskFlows',
        },
      });
      // eslint-disable-next-line no-console
      console.error('Task flows initialization failed', e);
      return taskFlowsInitializationFailed();
    }
  };

export const interactWithTodayTask =
  () =>
  ({ dispatch }: IDeps) => {
    dispatch(signalTodayTaskFlow());
    return noopAction();
  };

export const signalTodayTaskFlow =
  () =>
  ({ getState, dispatch }: IDeps) => {
    const weddingCountry = getState().weddings.profile.l10n.country;
    const market = gazetteer.getMarketByCountry(weddingCountry);

    const taskFlowApiDefinition = getState().task.todayTaskFlowDefinition;
    assertState(taskFlowApiDefinition, 'loaded');
    const taskFlowDefinition = getTaskFlowDefinitionUI(taskFlowApiDefinition.data);

    const previousTaskFlowInstance = getPreviousTaskFlowInstance(taskFlowDefinition, {
      getState,
      market,
    });

    const nextTaskFlowInstance = signalTaskFlow(taskFlowDefinition, {
      getState,
      market,
    });

    dispatch(updateTodayTaskFlow({ todayTaskFlowInstance: nextTaskFlowInstance }));
    if (checkIsNewSession(previousTaskFlowInstance, nextTaskFlowInstance)) {
      dispatch(
        newTodaysTaskSessionAnalytics({ sessionNumber: nextTaskFlowInstance.currentSession.id }),
      );
    }
    return noopAction();
  };

export const markTodayTaskAsCompleted =
  (task: Pick<TTaskDefinitionUI, 'id' | 'storage' | 'name'>) =>
  ({ getState, dispatch }: IDeps) => {
    const asyncAction = async () => {
      const state = getState();
      let wasTaskAlreadyCompleted = false;
      if (task.storage === 'wedding') {
        const weddingId = getWeddingProfileId(state);
        const weddingRef = Weddings._.getById(weddingId);
        const dbWeddingTaskRef = weddingRef.Tasks.getById(task.id);
        const dbWeddingTask = await dbWeddingTaskRef.get(true);
        wasTaskAlreadyCompleted = dbWeddingTask?.done;
        const saveTask: Omit<IWeddingTask, 'createdAt'> = {
          id: task.id,
          group: WeddingTasks.todayTaskGroupId,
          custom: false,
          period: '+P',
          order: 1,
          name: task.name,
          done: true,
        };
        await weddingRef.Tasks.getById(task.id).set(
          dbWeddingTask ? saveTask : { ...saveTask, createdAt: serverTimestamp() },
        );
        const updatedTask = await dbWeddingTaskRef.get(true);
        dispatch(weddingTaskUpdated(updatedTask));
      } else if (task.storage === 'user') {
        const currentUser = selectCurrentUser(state);
        if (!currentUser) throw new Error('Something went wrong');
        const dbUserRef = Users._.getById(currentUser.id);
        const dbUserTaskRef = dbUserRef.Tasks.getById(task.id);
        const dbUserTask = await dbUserTaskRef.get(true);
        wasTaskAlreadyCompleted = dbUserTask?.done;
        const saveTask: Omit<IUserTask, 'createdAt'> = {
          id: task.id,
          group: UsersTasks.todayTaskGroupId,
          custom: false,
          order: 1,
          name: task.name,
          done: true,
        };
        await dbUserTaskRef.set(
          dbUserTask ? saveTask : { ...saveTask, createdAt: serverTimestamp() },
        );

        const updatedTask = await dbUserTaskRef.get(true);
        dispatch(userTaskUpdated(updatedTask));
      } else {
        throw new Error('Invalid task passed');
      }

      if (state.task.todayTasks.status === 'loaded') {
        const market = gazetteer.getMarketByCountry(state.weddings.profile.l10n.country);
        const taskFlowApiDefinition = getState().task.todayTaskFlowDefinition;
        assertState(taskFlowApiDefinition, 'loaded');
        const taskFlowDefinition = getTaskFlowDefinitionUI(taskFlowApiDefinition.data);

        const currentSession = getCurrentSession(taskFlowDefinition, { getState, market });
        const currentSessionTaskIds = currentSession.tasks.map((task) => task.id);

        if (!wasTaskAlreadyCompleted && currentSessionTaskIds.includes(task.id)) {
          dispatch(signalTodayTaskFlow());
        }
      }
    };
    asyncAction();
    return noopAction();
  };

// It updates the current session if it is completed
export const syncTodayTaskFlow =
  () =>
  ({ getState, dispatch }: IDeps) => {
    try {
      const state = getState();
      const market = gazetteer.getMarketByCountry(state.weddings.profile.l10n.country);
      const taskFlowApiDefinition = getState().task.todayTaskFlowDefinition;
      assertState(taskFlowApiDefinition, 'loaded');
      const taskFlowDefinition = getTaskFlowDefinitionUI(taskFlowApiDefinition.data);

      if (state.task.taskFlows.status !== 'loaded') return noopAction();

      const context = {
        getState,
        market,
      };

      const previousTaskFlowInstance = getPreviousTaskFlowInstance(taskFlowDefinition, context);

      if (!previousTaskFlowInstance) return noopAction();

      const nextTaskFlowInstance = getNextTaskFlowInstance(
        taskFlowDefinition,
        context,
        previousTaskFlowInstance,
        false,
      );

      dispatch(taskFlowsUpdated({ todayTaskFlowInstance: nextTaskFlowInstance }));

      if (checkIsNewSession(previousTaskFlowInstance, nextTaskFlowInstance)) {
        saveTaskFlowInstance(taskFlowDefinition, context, nextTaskFlowInstance);
        dispatch(
          newTodaysTaskSessionAnalytics({
            sessionNumber: nextTaskFlowInstance.currentSession.id,
          }),
        );
      }
    } catch (e) {
      SentryMinimal().captureException(e, {
        tags: {
          source: 'taskFlows',
        },
      });
    }
    return noopAction();
  };

export const mountTaskComponent = () => () => taskComponentMounted();

export const reinitializeTodayTaskFlowDefinition =
  () =>
  ({ getState, dispatch }: IDeps) => {
    const weddingCountry = getState().weddings.profile.l10n.country;
    const market = gazetteer.getMarketByCountry(weddingCountry);

    const taskFlowApiDefinition = getState().task.todayTaskFlowDefinition;
    assertState(taskFlowApiDefinition, 'loaded');
    const taskFlowDefinition = getTaskFlowDefinitionUI(taskFlowApiDefinition.data);

    const previousTaskFlowInstance = getPreviousTaskFlowInstance(taskFlowDefinition, {
      getState,
      market,
    });

    if (previousTaskFlowInstance) {
      const nextTaskFlowInstance = migrateToNewTaskFlowDefinition(
        taskFlowDefinition,
        previousTaskFlowInstance,
        {
          getState,
          market,
        },
      );

      dispatch(updateTodayTaskFlow({ todayTaskFlowInstance: nextTaskFlowInstance }));
      if (checkIsNewSession(previousTaskFlowInstance, nextTaskFlowInstance)) {
        dispatch(
          newTodaysTaskSessionAnalytics({ sessionNumber: nextTaskFlowInstance.currentSession.id }),
        );
      }
    }
    return noopAction();
  };

export const {
  taskFlowDefinitionInitialized,
  registerTodayTaskTileData,
  taskFlowsInitialized,
  taskFlowsInitializationFailed,
  updateTodayTaskFlow,
  taskComponentMounted,
  userTasksLoaded,
  weddingTasksLoaded,
  weddingTaskUpdated,
  userTaskUpdated,
  taskFlowsUpdated,
  toggleAddCollaboratorModal,
  updateCarouselFadeIn,
  updateCarouselTask,
} = taskSlice.actions;
