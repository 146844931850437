import { TodayTaskFlowDefinitionApi } from '@bridebook/toolbox/src/today-task/types';
import { createGenericTodayTaskDefinition } from 'components/task/today-task/generic-today-task/task-definition';
import { PLACEHOLDER_TASK_DEFINITION } from 'components/task/today-task/placeholder-task/task-definition';
import {
  TTaskFlowDefinition,
  createSession,
  registeredTodayTasks,
} from 'lib/task/task-flow-engine';

export const getTaskFlowDefinitionUI = (todayTaskFlowApiDefinition: TodayTaskFlowDefinitionApi) => {
  const todayTaskFlow: TTaskFlowDefinition = {
    id: todayTaskFlowApiDefinition.id,
    sessions: todayTaskFlowApiDefinition.sessions.map((session) =>
      createSession({
        id: session.id,
        tasks: session.tasks.map((taskId) => {
          let taskDefinition = registeredTodayTasks[taskId];
          const apiTaskDefinition = todayTaskFlowApiDefinition.tasks[taskId];
          if (!taskDefinition && apiTaskDefinition && apiTaskDefinition.type === 'generic') {
            taskDefinition = createGenericTodayTaskDefinition({
              id: taskId,
              name: apiTaskDefinition.name,
              storage: apiTaskDefinition.config.storage,
              markets: apiTaskDefinition.markets,
            });
          }
          return taskDefinition;
        }),
        fallbackTask: PLACEHOLDER_TASK_DEFINITION,
      }),
    ),
  };
  return todayTaskFlow;
};
