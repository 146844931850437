import { values } from 'ramda';
import { IPackage } from '@bridebook/models/source/models/Suppliers/Packages.types';

export enum PackagesSections {
  pricePerHead = 'pricePerHead',
  totalInclusive = 'totalInclusive',
  venueHire = 'venueHire',
  inHouseCatering = 'inHouseCatering',
}

type PackagesSectionsKey = keyof typeof PackagesSections;

const mapToSection = ({
  priceMethod,
  guestsType,
}: IPackage): (typeof PackagesSections)[PackagesSectionsKey] | null => {
  switch (true) {
    case priceMethod === 'perHead' && guestsType === 'minMax':
      return PackagesSections.pricePerHead;
    case priceMethod === 'perHead' && guestsType === 'guestIndependent':
      return PackagesSections.inHouseCatering;
    case priceMethod === 'fixed' && guestsType === 'fixed':
      return PackagesSections.totalInclusive;
    case priceMethod === 'fixed' && guestsType === 'guestIndependent':
      return PackagesSections.venueHire;
    default:
      return null;
  }
};

/**
 * https://github.com/Bridebook/bb-scratch/blob/master/suppliers-migration-sheets/suppliers.groovy#L397-L509
 * Map Firestore Packages subcollection records to current cms packages
 * @param firestorePackages - Firestore Packages object
 * @returns {Object} object of packages mapped to pricing sections
 */
const mapToPackagesSections = (firestorePackages: Record<string, IPackage>): IPackagesSections => {
  const sections: IPackagesSections = {};

  values(firestorePackages).forEach((firestorePkg) => {
    const packageSection = mapToSection(firestorePkg);
    if (packageSection) {
      sections[packageSection] = firestorePkg;
    }
  });

  return sections;
};

export const sortSupplierPackages = (supplierPackages: Record<string, IPackage>) =>
  Object.values(supplierPackages).sort(
    (a, b) => a.order - b.order || Number(a.price) - Number(b.price),
  ) as ISupplierPackage[];

export interface IPackagesSections
  extends Partial<Record<keyof typeof PackagesSections, IPackage>> {}

export interface ISupplierPackage
  extends Omit<IPackage, 'price' | 'included' | 'order' | 'createdAt' | 'id'> {
  price: number;
  included: string[];
  order: number;
  id?: string;
}

export default mapToPackagesSections;
