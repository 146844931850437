import { getI18n } from 'lib/i18n/getI18n';

export interface IArticleTag {
  value: string;
  id: string;
  label: string;
  isPriority: boolean;
}

export interface IArticleTags {
  [k: string]: IArticleTag;
}
//That string is used to check if we are checking supplier category and not the basic ones
export const supplierCategoryTags = [
  'suppliers',
  'videography',
  'photography',
  'catering',
  'decor',
  'entertainment',
  'cake',
  'beauty-and-hair',
  'transport',
];

export const getArticleTags = (): IArticleTags => {
  const i18n = getI18n();

  return {
    all: {
      value: '',
      id: 'all-topic',
      label: i18n.t('articles:tagName.all'),
      isPriority: true,
    },
    'general-advice': {
      value: 'general-advice',
      id: 'general-advice',
      label: i18n.t('articles:tagName.GeneralAdvice'),
      isPriority: true,
    },
    'expert-advice': {
      value: 'expert-advice',
      id: 'expert-advice',
      label: i18n.t('articles:tagName.expertAdvice', 'Expert Advice'),
      isPriority: true,
    },
    budget: {
      value: 'budget',
      id: 'budget',
      label: i18n.t('articles:tagName.wedding-budget'),
      isPriority: true,
    },
    guests: {
      value: 'guests',
      id: 'all-guests',
      label: i18n.t('articles:tagName.guests'),
      isPriority: true,
    },
    venue: {
      value: 'venue',
      id: 'venue',
      label: i18n.t('articles:tagName.venue'),
      isPriority: false,
    },
    catering: {
      value: 'catering',
      id: 'catering',
      label: i18n.t('articles:tagName.catering'),
      isPriority: false,
    },
    cake: {
      value: 'cake',
      id: 'cake',
      label: i18n.t('articles:tagName.cake'),
      isPriority: false,
    },
    flowers: {
      value: 'flowers',
      id: 'flowers',
      label: i18n.t('articles:tagName.flowers'),
      isPriority: false,
    },
    decor: {
      value: 'decor',
      id: 'decor',
      label: i18n.t('articles:tagName.decor'),
      isPriority: false,
    },
    photography: {
      value: 'photography',
      id: 'photography',
      label: i18n.t('articles:tagName.photography'),
      isPriority: false,
    },
    videography: {
      value: 'videography',
      id: 'videography',
      label: i18n.t('articles:tagName.videography'),
      isPriority: false,
    },
    dress: {
      value: 'dress',
      id: 'dress',
      label: i18n.t('articles:tagName.dress'),
      isPriority: false,
    },
    jewellery: {
      value: 'jewellery',
      id: 'jewellery',
      label: i18n.t('articles:tagName.jewellery'),
      isPriority: false,
    },
    'beauty-and-hair': {
      value: 'beauty-and-hair',
      id: 'beauty-and-hair',
      label: i18n.t('articles:tagName.beauty'),
      isPriority: false,
    },
    stationery: {
      value: 'stationery',
      id: 'stationery',
      label: i18n.t('articles:tagName.stationery'),
      isPriority: false,
    },
    entertainment: {
      value: 'entertainment',
      id: 'entertainment',
      label: i18n.t('articles:tagName.music'),
      isPriority: false,
    },
    transport: {
      value: 'transport',
      id: 'transport',
      label: i18n.t('articles:tagName.transport'),
      isPriority: false,
    },
    ceremony: {
      value: 'ceremony',
      id: 'ceremony',
      label: i18n.t('articles:tagName.ceremony'),
      isPriority: true,
    },
    'speeches-and-vows': {
      value: 'speeches-and-vows',
      id: 'speeches-and-vows',
      label: i18n.t('articles:tagName.speeches'),
      isPriority: false,
    },
    'wedding-day': {
      value: 'wedding-day',
      id: 'wedding-day',
      label: i18n.t('articles:tagName.weddingDay'),
      isPriority: false,
    },
    'post-wedding': {
      value: 'post-wedding',
      id: 'post-wedding',
      label: i18n.t('articles:tagName.postWedding'),
      isPriority: true,
    },
    'real-wedding': {
      value: 'real-wedding',
      id: 'real-wedding',
      label: i18n.t('articles:tagName.realWeddings'),
      isPriority: true,
    },
    'blogger-advice': {
      value: 'blogger-advice',
      id: 'blogger-advice',
      label: i18n.t('articles:tagName.bloggerAdvice'),
      isPriority: false,
    },
    'health-and-fitness': {
      value: 'health-and-fitness',
      id: 'health-and-fitness',
      label: i18n.t('articles:tagName.health'),
      isPriority: false,
    },
    suppliers: {
      value: 'suppliers',
      id: 'suppliers',
      label: i18n.t('articles:tagName.suppliers'),
      isPriority: true,
    },
    menswear: {
      value: 'menswear',
      id: 'menswear',
      label: i18n.t('articles:tagName.menswear', 'Menswear'),
      isPriority: false,
    },
    marquee: {
      value: 'marquee',
      id: 'marquee',
      label: i18n.t('articles:tagName.marquee', 'Marquee'),
      isPriority: false,
    },
  };
};

// these tags are used to get the translated name
export const getExtraArticleTags = (): { [slug: string]: string } => {
  const i18n = getI18n();
  return {
    '1-month-to-go': i18n.t('articles:tagName.1MonthToGo'),
    '1-week-to-go': i18n.t('articles:tagName.1WeekToGo'),
    '12-months-to-go': i18n.t('articles:tagName.12MonthsToGo'),
    '6-months-to-go': i18n.t('articles:tagName.6MonthsToGo'),
    '9-months-to-go': i18n.t('articles:tagName.9MonthsToGo'),
    DIY: i18n.t('articles:tagName.DIY'),
    advice: i18n.t('articles:tagName.advice'),
    autumn: i18n.t('articles:tagName.autumn'),
    barn: i18n.t('articles:tagName.barn'),
    'bridebook-business': i18n.t('articles:tagName.bridebookBusiness'),
    classic: i18n.t('articles:tagName.classic'),
    coronavirus: i18n.t('articles:tagName.coronavirus'),
    'country-house': i18n.t('articles:tagName.countryHouse'),
    'couple-advice': i18n.t('articles:tagName.coupleAdvice'),
    drinks: i18n.t('articles:tagName.drinks'),
    food: i18n.t('articles:tagName.food'),
    'getting-started': i18n.t('articles:tagName.gettingStarted'),
    gifts: i18n.t('articles:tagName.gifts'),
    groom: i18n.t('articles:tagName.groom'),
    honeymoon: i18n.t('articles:tagName.honeymoon'),
    'looking-good': i18n.t('articles:tagName.lookingGood'),
    marquee: i18n.t('articles:tagName.marquee', 'Marquee'),
    outdoor: i18n.t('articles:tagName.outdoor'),
    reception: i18n.t('articles:tagName.reception'),
    reviews: i18n.t('articles:tagName.reviews'),
    rustic: i18n.t('articles:tagName.rustic'),
    spring: i18n.t('articles:tagName.spring'),
    summer: i18n.t('articles:tagName.summer'),
    'supplier-stories': i18n.t('articles:tagName.supplierStories'),
    suppliers: i18n.t('articles:tagName.suppliers'),
    venues: i18n.t('articles:tagName.venues'),
    videography: i18n.t('articles:tagName.videography'),
    wedding: i18n.t('articles:tagName.wedding'),
    'wedding-trends': i18n.t('articles:tagName.weddingTrends'),
    'wedding-venue-list': i18n.t('articles:tagName.weddingVenueList'),
    'wedding-venues': i18n.t('articles:tagName.weddingVenues'),
    winter: i18n.t('articles:tagName.winter'),
  };
};
