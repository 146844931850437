import produce, { original } from 'immer';
import { Action } from 'redux';
import { IBookingFile } from '@bridebook/toolbox/src/types';
import {
  DeleteFilesSuccessAction,
  FetchFilesSuccessAction,
  FileManagementActionTypes,
} from 'lib/files/action-types';
import { getReducerActions } from 'lib/utils';

export interface IFilesState {
  openedBookingFiles: IBookingFile[] | null;
}

const initialState: IFilesState = {
  openedBookingFiles: null,
};

export const findFileIndex = (file: IBookingFile, files: IBookingFile[] | null) =>
  files?.findIndex((f) => f.file.id === file.file.id) ?? -1;

const reducers: any = (draft: IFilesState) => ({
  [FileManagementActionTypes.FETCH_FILES_SUCCESS]: (action: FetchFilesSuccessAction) => {
    draft.openedBookingFiles = action.payload.files;
  },

  [FileManagementActionTypes.DELETE_FILES_SUCCESS]: ({
    payload: { files },
  }: DeleteFilesSuccessAction) => {
    files.forEach((file) => {
      // Remove deleted file from general files table without re-fetching
      const indexTable = findFileIndex(file, original(draft.openedBookingFiles) || []);
      if (indexTable > -1 && draft.openedBookingFiles) {
        draft.openedBookingFiles.splice(indexTable, 1);
      }
    });
  },
});

const reducersActions = getReducerActions(reducers);

/*
	This is a wrapper function which runs a proper reducer from the object above.
*/
const reducer = (state: IFilesState = initialState, action: Action): IFilesState => {
  if (!reducersActions[action.type]) {
    return state;
  }

  try {
    return produce(state, (draft) => reducers(draft)[action.type](action));
  } catch (err) {
    return state;
  }
};

export default reducer;
