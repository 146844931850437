import { BridebookError } from 'lib/types';
import FormFields from './formFields';
import { IAuthFields } from './types';

export interface IForm {
  disabled: boolean;
  resetDisabled: boolean;
  error: BridebookError | null;
  fields: IAuthFields;
}

const Form: IForm = {
  disabled: false,
  resetDisabled: false,
  error: null,
  fields: FormFields,
};

export default Form;
