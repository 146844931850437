import { getIsCollaborator } from 'lib/analytics-utils/get-is-collaborator';
import { getPreviousPath } from 'lib/app/selectors';
import { WebAnalyticsContext } from 'lib/bbcommon/utils/bridebook-analytics';
import { FeedbackAnalyticsAction, analytics } from 'lib/feedback/analytics-actions';
import { Action, IApplicationState, ReturnTypeAction } from 'lib/types';

const filterLabelProps = (props: Record<string, any>) => {
  const newProps = { ...props };
  return newProps;
};

export default function feedbackAnalytics(
  notTypedAction: Action,
  bridebookAnalytics: WebAnalyticsContext,
  getState: () => IApplicationState,
) {
  const defaultCategory = 'Feedback';

  const action = notTypedAction as
    | ReturnTypeAction<
        FeedbackAnalyticsAction.RequestedFeedback,
        typeof analytics.requestedFeedback
      >
    | ReturnTypeAction<
        FeedbackAnalyticsAction.SubmittedFeedback,
        typeof analytics.submittedFeedback
      >
    | ReturnTypeAction<
        FeedbackAnalyticsAction.TriggeredFeedbackForm,
        typeof analytics.triggeredFeedbackForm
      >;

  const getGenericProps = () => {
    const state = getState();
    const previousPath = getPreviousPath(state);
    const profileType = 'user';
    const collaborator = getIsCollaborator(state);

    return {
      previousPath,
      profileType,
      collaborator,
    };
  };

  const { payload, type } = action;

  const track = (props: Record<string, any> = {}, category: string = defaultCategory) => {
    const { track } = bridebookAnalytics.getMethods(category, filterLabelProps);
    track({
      event: action.type,
      ...getGenericProps(),
      ...props,
    });
  };

  switch (type) {
    case FeedbackAnalyticsAction.RequestedFeedback: {
      track({
        category: 'feedback',
        requestedLink: payload.requestedLink,
        requestedLocation: payload.requestedLocation,
        requestedSection: payload.requestedSection,
        requestedType: payload.requestedType,
        supplierCategory: payload.supplierCategory,
        adminArea0: payload.adminArea0,
        adminArea1: payload.adminArea1,
        supplierId: payload.supplierId,
        supplierRegistered: payload.supplierRegistered,
        supplierCountryCode: payload.supplierCountryCode,
        supplierCountryName: payload.supplierCountryName,
        supplierTier: payload.supplierTier,
      });
      break;
    }

    case FeedbackAnalyticsAction.SubmittedFeedback: {
      track({
        feedbackType: 'other',
        feedbackTag: 'Supplier feedback',
        supplierCategory: payload.supplierCategory,
        adminArea0: payload.adminArea0,
        adminArea1: payload.adminArea1,
        supplierId: payload.supplierId,
        supplierRegistered: payload.supplierRegistered,
        supplierCountryCode: payload.supplierCountryCode,
        supplierCountryName: payload.supplierCountryName,
        supplierTier: payload.supplierTier,
        feedbackSource: payload.feedbackSource,
        feedbackMethod: payload.feedbackMethod,
        feedbackDescription: payload.feedbackDescription,
      });
      break;
    }

    case FeedbackAnalyticsAction.TriggeredFeedbackForm: {
      track({
        feedbackSource: payload.feedbackSource,
        supplierId: payload.supplierId,
      });
      break;
    }

    default:
      break;
  }
}
