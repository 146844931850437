import { isEmpty, pathOr, values } from 'ramda';
import * as R from 'remeda';
import { IGroup } from '@bridebook/models/source/models/Countries/Groups.types';
import { ITask } from '@bridebook/models/source/models/Weddings/Tasks.types';
import { mapDateToUI, mapToExactDate, slugify } from '@bridebook/toolbox/src';
import gazetteer from '@bridebook/toolbox/src/gazetteer';
import { SentryMinimal } from '@bridebook/toolbox/src/sentry';
import { IUISupplier } from '@bridebook/toolbox/src/types';
import { createWeddingLevelIdentify } from 'lib/analytics-utils';
import { WebAnalyticsContext } from 'lib/bbcommon/utils/bridebook-analytics';
import { ChecklistActionTypes } from 'lib/checklist/action-types';
import { updatedSubtaskAnalytics } from 'lib/checklist/analytics/actions';
import {
  getGroupSupplierSlug,
  getNextTaskGroup,
  getShowDetailsTask,
  getTaskAssignee,
  getTaskGroup,
} from 'lib/checklist/selectors';
import { IChecklistGroup } from 'lib/checklist/types';
import { extractRawName, getPeriodName } from 'lib/checklist/utils';
import { getGroupsTranslation } from 'lib/countries/data/groups';
import { getCountryCodeWithFallback } from 'lib/i18n/selectors';
import { Action, IApplicationState } from 'lib/types';

const filterLabelProps = (props: Record<string, any>) => {
  const newProps = { ...props };
  delete newProps.category;
  delete newProps.event;
  delete newProps.checklistMainTaskAssignedTo;
  delete newProps.value;
  delete newProps.updatedMainTasksAction;
  delete newProps.updatedMainTasksMethod;
  delete newProps.openedSubtasksViewMethod;
  delete newProps.exitedSubtasksViewMethod;
  delete newProps.updatedSubtasksAction;
  delete newProps.followedLinkOnSubtasksViewCategory;
  delete newProps.followedLinkOnSubtasksViewUrl;
  return newProps;
};

export const getLocationName = (getState: () => IApplicationState) => {
  const pathname = getState().app.pathname;
  const urlName = pathname === '/' ? pathname : pathname.split('/')[1];
  let location;
  switch (urlName) {
    case 'search':
    case 'shortlist':
    case 'home':
    case 'checklist':
      location = urlName;
      break;
    case '/':
      location = 'home';
      break;
    default:
      location = 'supplierProfile';
  }
  return location;
};

export const checklistStatePropertiesGeneric = (
  state: IApplicationState,
  tag: any = null,
  filterExp: any = null,
) => {
  const {
    weddings: { profile },
    checklist,
  } = state;
  const filter = filterExp || checklist.filter;
  const checklistInitialisationDate = profile
    ? new Date(profile.tasks.initializedAt || 0).toISOString()
    : undefined;

  const checklistFilter = filter || undefined;
  const checklistDateTag = filter !== 'mytasks' && filter !== 'alltasks' ? tag : 'all';

  const market = gazetteer.getMarketByCountry(getCountryCodeWithFallback(state));
  const exactDate = profile ? mapToExactDate(mapDateToUI(profile.date), market) : 0;
  const checklistWeddingDate = profile ? new Date(exactDate || 0).toISOString() : undefined;

  return {
    checklistInitialisationDate,
    checklistFilter,
    checklistDateTag,
    checklistWeddingDate,
  };
};

interface IChecklistMainTaskPropertiesGenericArgs {
  taskId?: string;
  taskName?: string;
  assigneeName?: string;
  newAssigneeName?: string;
  checklistMainTaskSupplierCategory?: string;
}

export const checklistMainTaskPropertiesGeneric = ({
  taskId,
  taskName,
  assigneeName = '',
  newAssigneeName,
  checklistMainTaskSupplierCategory,
}: IChecklistMainTaskPropertiesGenericArgs) => ({
  checklistMainTaskId: taskId,
  checklistMainTaskName: taskName,
  checklistMainTaskAssignedTo: newAssigneeName || assigneeName,
  checklistMainTaskSupplierCategory: checklistMainTaskSupplierCategory || '',
});

export const getSubTaskPropsGeneric = ({
  subtaskId,
  name,
}: {
  subtaskId: string | ITask;
  name?: string;
}) => ({
  checklistSubtaskId: subtaskId && ((subtaskId as ITask).id || subtaskId),
  checklistSubtaskName: name,
});

const getChecklistMainTaskSupplierCategory = ({
  getState,
  taskId,
}: {
  getState: () => IApplicationState;
  taskId?: string;
}): string => {
  const {
    checklist: { detailsTaskId },
    app: { groups },
  } = getState();

  const detailsTask = detailsTaskId
    ? getShowDetailsTask(getState())
    : taskId
    ? groups[taskId]
    : undefined;

  return detailsTask?.supplierType || '';
};

interface ITaskArgs {
  getState: () => IApplicationState;
  taskName?: string;
}

const getUpdateTaskGenerics = ({ getState, taskId, taskName }: ITaskArgs & { taskId: string }) => {
  const {
    checklist: { detailsTaskId },
  } = getState();

  const state = getState();
  const openedTask = getShowDetailsTask(state);
  const assigneeName = getTaskAssignee(state, detailsTaskId as string);
  const taskKey = openedTask?.i18nKey;
  const taskTranslationName = taskKey && getGroupsTranslation[taskKey]?.().name;

  return {
    ...checklistStatePropertiesGeneric(state),
    ...checklistMainTaskPropertiesGeneric({
      taskId: detailsTaskId ? openedTask?.id : taskId,
      taskName: detailsTaskId ? taskTranslationName : taskName,
      checklistMainTaskSupplierCategory: getChecklistMainTaskSupplierCategory({
        getState,
        taskId,
      }),
      assigneeName,
    }),
  };
};

const getShowDetailsGenerics = ({ getState }: { getState: () => IApplicationState }) => {
  const state = getState();
  const detailsTaskId = state.checklist.detailsTaskId;
  const mainTask = getTaskGroup(state, detailsTaskId as string);
  const assigneeName = getTaskAssignee(state, detailsTaskId as string);
  const checklistMainTaskSupplierCategory = getGroupSupplierSlug(state);

  return {
    ...checklistStatePropertiesGeneric(state),
    ...checklistMainTaskPropertiesGeneric({
      ...mainTask,
      taskId: mainTask?.id,
      taskName: mainTask?.name,
      checklistMainTaskSupplierCategory,
      assigneeName,
    }),
  };
};

const getTaskDeletionGenerics = ({
  getState,
  task,
  taskName,
}: ITaskArgs & { task?: ITask | IGroup }) => ({
  ...checklistStatePropertiesGeneric(getState()),
  ...checklistMainTaskPropertiesGeneric({
    taskId: task?.id,
    taskName,
    checklistMainTaskSupplierCategory: getChecklistMainTaskSupplierCategory({
      getState,
      taskId: task?.id,
    }),
  }),
});

const getTriggeredDeletionGenerics = ({
  getState,
  detailsTask: mainTask,
  name,
  task,
}: {
  getState: () => IApplicationState;
  detailsTask: IGroup;
  name: string;
  task: ITask;
}) => {
  const {
    checklist: { detailsTaskId },
  } = getState();

  const detailsTask = detailsTaskId ? getShowDetailsTask(getState()) : mainTask;
  const mainTaskId = detailsTask ? detailsTask.id : '';
  const mainTaskName = detailsTask ? detailsTask.name : '';

  return {
    ...checklistStatePropertiesGeneric(getState()),
    ...(detailsTaskId ? getSubTaskPropsGeneric({ subtaskId: task.id, name }) : {}),
    ...checklistMainTaskPropertiesGeneric({
      taskId: mainTaskId,
      taskName: mainTaskName,
      checklistMainTaskSupplierCategory: getChecklistMainTaskSupplierCategory({
        getState,
        taskId: mainTaskId,
      }),
    }),
  };
};

const getIdentifyGeneric = (result: Record<string, ITask>, getState: () => IApplicationState) => {
  try {
    const taskGroup = getNextTaskGroup(getState());
    if (!taskGroup) return;

    const {
      weddings: { profile },
      checklist: { tasksInitial },
    } = getState();

    // take tasks within group (subtasks) that are not done and not custom
    // assign name from tasks initial collection
    const groupTasks = R.pipe(
      values<Record<string, ITask>, string>(result),
      R.sortBy(R.prop<ITask, 'order'>('order')),
      R.filter<ITask>((t) => t.group === taskGroup.id && !t.done),
      R.map((t) => ({
        ...t,
        name: extractRawName(pathOr(t.name || '', [t.id, 'name'], tasksInitial)),
      })),
    );

    const total = result ? values(result).length : 180;
    const done = result ? values(result).filter((t) => t.done).length : 0;

    const { name, id, intro } = taskGroup;
    const period = pathOr(null, [0, 'period'], groupTasks);
    const market = gazetteer.getMarketByCountry(getCountryCodeWithFallback(getState()));
    const percComplete = total ? Math.round((done / total) * 100) : 0;
    const dueDate = period
      ? getPeriodName({ date: profile.date, period, market, asDate: true })
      : '';

    const props = {
      nextTaskDueDate: dueDate,
      nextTaskDescription: intro,
      nextTaskUrl: `/checklist/${slugify(name)}-${id}`,
      percentageOfTasksCompleted: percComplete,
      nextTaskSubTask1Title: pathOr(undefined, [0, 'name'], groupTasks),
      nextTaskSubTask2Title: pathOr(undefined, [1, 'name'], groupTasks),
      nextTaskSubTask3Title: pathOr(undefined, [2, 'name'], groupTasks),
      nextTaskTitle: name,
      totalTaskCount: total,
      numberOfTasksCompleted: done,
    };
    return props;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    SentryMinimal().captureException(e, {
      tags: {
        source: 'analytics-checklist',
      },
    });
  }
};

const checklistAnalytics = (
  action: Action,
  bridebookAnalytics: WebAnalyticsContext,
  getState: () => IApplicationState,
) => {
  const { payload, type } = action;
  const { track } = bridebookAnalytics.getMethods('Checklist', filterLabelProps);

  const checklistIdentify =
    createWeddingLevelIdentify<ReturnType<typeof getIdentifyGeneric>>(getState);

  switch (type) {
    case 'CHECKLIST_INITIAL_IDENTIFY': {
      const {
        checklist: { tasks },
      } = getState();
      const props = getIdentifyGeneric(tasks, getState);
      checklistIdentify(props);

      break;
    }

    case ChecklistActionTypes.FETCH_USER_CHECKLIST_SUCCESS: {
      if (!payload || isEmpty(payload)) break;
      const { source, result } = payload;
      if (source !== 'server') break; // Ignore local cache events
      const props = getIdentifyGeneric(result, getState);
      checklistIdentify(props);
      break;
    }

    case 'INITIALISE_CHECKLIST_ANALYTICS':
    case 'REINITIALISE_CHECKLIST_ANALYTICS': {
      track({
        event:
          type === 'INITIALISE_CHECKLIST_ANALYTICS'
            ? 'Initialised checklist'
            : 'Reinitialised checklist',
        ...checklistStatePropertiesGeneric(getState()),
      });
      break;
    }
    case 'UPDATE_TASK_ANALYTICS': {
      const { updatedMainTasksMethod, checked } = payload;

      track({
        event: 'Updated main tasks',
        updatedMainTasksMethod,
        updatedMainTasksAction: checked ? 'checked' : 'unchecked',
        ...getUpdateTaskGenerics({ getState, ...payload }),
      });
      break;
    }
    case 'UPDATE_SUBTASK_ANALYTICS': {
      const { subtaskId, name, checked, method } = payload as ReturnType<
        typeof updatedSubtaskAnalytics
      >['payload'];

      track({
        event: 'Updated subtasks',
        ...getUpdateTaskGenerics({ getState, ...payload }),
        ...getSubTaskPropsGeneric({ subtaskId, name }),
        updatedSubtasksAction: checked ? 'checked' : 'unchecked',
        updatedSubtaskMethod: method,
      });
      break;
    }
    case 'SHOW_DETAILS_OPENED_ANALYTICS': {
      // agreed with Fergus to pass previousPath
      // const openedSubtasksViewMethod = method || 'mainView';
      const { previousPath } = getState().app;
      track({
        event: 'Opened subtasks view',
        previousPath,
        // openedSubtasksViewMethod,
        ...getShowDetailsGenerics({
          getState,
        }),
      });
      break;
    }
    case 'UPDATE_CURRENT_TAG_ANALYTICS': {
      const { tag } = payload;
      track({
        event: 'Changed checklist month',
        ...checklistStatePropertiesGeneric(getState(), tag),
      });
      break;
    }
    case 'ADD_TASK_ANALYTICS': {
      const { tag, newTask, name, method } = payload;
      track({
        event: 'Added main task',
        ...checklistStatePropertiesGeneric(getState(), tag),
        ...checklistMainTaskPropertiesGeneric({
          taskId: newTask.id,
          taskName: name,
        }),
        addedMainTaskMethod: method,
      });
      break;
    }
    case 'ADD_SUBTASK_ANALYTICS': {
      const { newTask, name } = payload;

      const state = getState();
      const task = getShowDetailsTask(state);

      track({
        event: 'Added subtask',
        ...checklistStatePropertiesGeneric(state),
        ...checklistMainTaskPropertiesGeneric({
          taskId: task?.id,
          taskName: task?.name,
        }),
        ...getSubTaskPropsGeneric({ subtaskId: newTask.id, name }),
      });
      break;
    }
    case 'DELETE_TASK_SUB_ANALYTICS': {
      const {
        task: { id: subtaskId, name },
      } = payload;

      const task = getShowDetailsTask(getState());

      track({
        event: 'Deleted subtask',
        ...getTaskDeletionGenerics({
          getState,
          taskName: task?.name,
          task,
        }),
        ...getSubTaskPropsGeneric({ subtaskId, name }),
      });
      break;
    }
    case 'DELETE_TASK_MAIN_ANALYTICS': {
      const {
        task: { name: taskName },
      } = payload;

      track({
        event: 'Deleted main task',
        ...getTaskDeletionGenerics({
          getState,
          taskName,
          ...payload,
        }),
      });
      break;
    }
    case 'TRIGGER_DELETE_TASK_SUB_ANALYTICS':
      track({
        event: 'Triggered delete subtask',
        ...getTriggeredDeletionGenerics({
          getState,
          ...payload,
        }),
      });
      break;
    case 'TRIGGER_DELETE_TASK_MAIN_ANALYTICS':
      track({
        event: 'Triggered delete main task',
        ...getTriggeredDeletionGenerics({
          getState,
          ...payload,
          detailsTask: payload.task,
        }),
      });
      break;
    case 'FILTER_CHECKLIST_ANALYTICS':
      track({
        event: 'Changed checklist filter',
        ...checklistStatePropertiesGeneric(getState(), null, payload),
      });
      break;
    case 'ON_EDIT_TASK_ASSIGNEE_ANALYTICS': {
      const { task, taskId, taskName } = payload;
      track({
        event: 'Clicked assign checklist task',
        ...checklistStatePropertiesGeneric(getState()),
        ...checklistMainTaskPropertiesGeneric({
          ...task,
          taskId,
          taskName,
        }),
      });
      break;
    }
    case 'SAVE_TASK_ASSIGNEE_ANALYTICS': {
      const { task, taskId, taskName, newAssigneeName } = payload;
      track({
        event: 'Assigned checklist task',
        ...checklistStatePropertiesGeneric(getState()),
        ...checklistMainTaskPropertiesGeneric({
          ...task,
          taskId,
          taskName,
          newAssigneeName,
        }),
      });
      break;
    }
    case 'ON_TRIGGER_SOCIAL_SHARE_TASK_ANALYTICS': {
      const { task, taskName, network } = payload;
      track({
        event: 'Triggered social share',
        category: 'Social sharing',
        ...checklistStatePropertiesGeneric(getState()),
        ...checklistMainTaskPropertiesGeneric({
          ...task,
          taskId: task.id,
          taskName,
        }),
        socialShareType: 'checklistTask',
        socialShareMethod: network,
      });
      break;
    }
    case 'ON_COPY_TASK_URL_ANALYTICS': {
      const { task, taskName, method, url } = payload;
      track({
        event: 'Copied URL',
        category: 'Social sharing',
        ...checklistStatePropertiesGeneric(getState()),
        ...checklistMainTaskPropertiesGeneric({
          ...task,
          taskId: task.id,
          taskName,
        }),
        copiedUrlType: 'checklistTask',
        copiedUrlMethod: method,
        copiedUrlUrl: url,
      });
      break;
    }

    case 'FOLLOWED_SUBTASKS_LINK_ANALYTICS': {
      const { task, taskName, url } = payload;
      track({
        event: 'Followed link on subtasks view',
        ...checklistStatePropertiesGeneric(getState()),
        ...checklistMainTaskPropertiesGeneric({
          ...task,
          taskId: task.id,
          taskName,
        }),
        followedLinkOnSubtasksViewCategory: url.split('/')[1],
        followedLinkOnSubtasksViewUrl: url,
      });
      break;
    }
    case 'USED_TAB_NAVIGATION_ON_SUBTASK_VIEW_ANALYTICS': {
      const { task, taskName, tab } = payload;
      track({
        event: 'Used tab navigation on subtask view',
        ...checklistStatePropertiesGeneric(getState()),
        ...checklistMainTaskPropertiesGeneric({
          ...task,
          taskId: task.id,
          taskName,
        }),
        subtaskViewTab: tab,
      });
      break;
    }
    case 'LOADED_RELATED_SUPPLIERS_ANALYTICS': {
      const { area, category, loadedSuppliers } = payload;
      track({
        event: 'Loaded related suppliers',
        category: 'Directory',
        loadedRelatedSuppliersLocation: getLocationName(getState),
        loadedRelatedSuppliersCategory: category,
        loadedRelatedSuppliersSearchLocation: area,
        loadedRelatedSuppliersList: loadedSuppliers.length
          ? (loadedSuppliers as IUISupplier[]).map((s) => s.id)
          : [],
      });
      break;
    }

    case 'DELETE_MAIN_TASK_ANALYTICS': {
      const { task, taskName } = payload;
      track({
        event: 'Deleted main task',
        ...checklistStatePropertiesGeneric(getState()),
        ...checklistMainTaskPropertiesGeneric({
          ...task,
          taskId: task.id,
          taskName,
        }),
      });
      break;
    }

    case ChecklistActionTypes.CLICKED_ADD_MAIN_TASK_ANALYTICS: {
      track({
        event: 'Clicked add main task',
        ...checklistStatePropertiesGeneric(getState()),
      });
      break;
    }

    case 'CHANGED_MAIN_TASK_PERIOD_ANALYTICS': {
      const task = action.payload as IChecklistGroup;
      track({
        event: 'Changed main task date',
        ...checklistStatePropertiesGeneric(getState()),
        ...checklistMainTaskPropertiesGeneric({
          ...task,
          taskId: task.id,
          taskName: task.name,
        }),
      });
      break;
    }

    default:
      break;
  }
};

export default checklistAnalytics;
