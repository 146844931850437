export default class Queue<T> {
  private _store: T[] = [];

  get store(): T[] {
    return [...this._store];
  }

  push(val: T) {
    this._store.push(val);
  }

  pop(): T | undefined {
    return this._store.shift();
  }
}
