import React from 'react';
import { getDeleteAccountInSession } from 'lib/auth/selectors';
import { getCurrentUserDeleteAt, selectIsUserLoggedIn } from 'lib/users/selectors';
import { useSelector } from 'lib/utils';
import NoticeContent from './notice-content';

interface IProps {
  children?: React.ReactNode;
}

const UserDeletionNotice = ({ children }: IProps) => {
  const deleteAt = useSelector(getCurrentUserDeleteAt);
  const isLoggedIn = useSelector(selectIsUserLoggedIn);
  const deleteAccountInSession = useSelector(getDeleteAccountInSession);

  // Don't show this notice if the account was deleted in this session
  const displayNotice = Boolean(deleteAt && isLoggedIn && !deleteAccountInSession);

  if (displayNotice) return <NoticeContent />;

  return <>{children}</>;
};

export default UserDeletionNotice;
