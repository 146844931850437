import { assocPath } from 'ramda';
import { AnyAction } from 'redux';
import { mergeClean } from '@bridebook/toolbox/src';
import { EpicsState } from './types';

const initialState: EpicsState = {
  epicsAdded: {
    advice: false,
    article: false,
    bookmarks: false,
    budget: false,
    changePassword: false,
    checklist: false,
    datepicker: false,
    gifts: false,
    guestlist: false,
    home: false,
    locationLanding: false,
    index: false,
    onboarding: false,
    scrapbook: false,
    search: false,
    settings: false,
    shortlist: false,
    signup: false,
    staticPage: false,
    supplierCategory: false,
    supplier: false,
    toolCategory: false,
    weddingIdeasInspiration: false,
    weddingPlanningTools: false,
    weddingSuppliers: false,
  },
};

export default function featureReducer(state: EpicsState = initialState, action: AnyAction) {
  const newState: EpicsState = mergeClean(initialState, state);
  switch (action.type) {
    case 'EPICS_ADDED': {
      const { name } = action.payload;
      return assocPath(['epicsAdded', name], true, state);
    }

    default:
      return newState;
  }
}
