import { ISupplier } from '@bridebook/models/source/models/Suppliers.types';
import { getBaseURL, getSupplierUrl, getUrlCoupleSideLocale } from '@bridebook/toolbox';
import { Gazetteer } from '@bridebook/toolbox/src/gazetteer';
import { Appsflyer } from '@ionic-native/appsflyer';
import { CriticalWebEvents } from 'lib/analyticsTypes';
import { WebAnalyticsContext } from 'lib/bbcommon/utils/bridebook-analytics';
import { DashboardAnalyticsActions } from 'lib/dashboard/analytics/types';
import { filterLabelProps } from 'lib/shortlist/analytics';
import { Action, IApplicationState } from 'lib/types';
import serverSideTrack from 'lib/utils/server-side-track';
import { AccessControlActionTypes } from './action-types';
import { CollaboratorInviteLocation, CollaboratorInviteMethod, Role } from './constants';

interface ICollaboratorPropertiesGeneric {
  collaboratorEmail?: string;
  collaboratorInvite?: string;
  collaboratorPermissions?: Role;
}

export type ICollaboratorInvitePropertiesGeneric = Partial<{
  collaboratorInviteApp?: string;
  collaboratorInviteLocation: CollaboratorInviteLocation;
  collaboratorInviteMethod: CollaboratorInviteMethod;
}>;

export type ICollaborationActionPayloadGeneric = Partial<{
  inviteApp: string;
  inviteLocation: CollaboratorInviteLocation;
  inviteMethod: CollaboratorInviteMethod;
}>;

const collaboratorPropertiesGeneric = (
  getState: () => IApplicationState,
): ICollaboratorPropertiesGeneric => {
  const {
    acl: { inviteForm },
    auth: { collaboratorInvite },
  } = getState();

  const inviteUrl = collaboratorInvite?.inviteUrl || inviteForm.url;
  const email =
    inviteForm.method === CollaboratorInviteMethod.Email ? inviteForm.fields.email : null;
  const role = inviteForm.role || collaboratorInvite.role || null;

  return {
    ...(email && { collaboratorEmail: email }),
    ...(inviteUrl && { collaboratorInvite: inviteUrl }),
    ...(role && { collaboratorPermissions: role }),
  };
};

const getInviteFormLocation = (getState: () => IApplicationState) => {
  const {
    acl: { inviteForm },
  } = getState();

  return inviteForm.location;
};

export type ICollaboratorInviteSupplierPropertiesGeneric = {
  supplierId: string;
  supplierName: string;
  supplierCountryCode: string;
  supplierCountryName: string;
  supplierCategory: string;
  supplierTier: number;
  supplierProfileURL: string;
  supplierThumbnailURL?: string;
};

const collaboratorFeedbackInviteSupplierPropertiesGeneric = (
  supplier: ISupplier,
  supplierTier: number,
  supplierThumbnailURL: string,
): ICollaboratorInviteSupplierPropertiesGeneric | Record<string, never> => {
  const supplierCountryCode = supplier.l10n?.country;
  const supplierCategory = supplier.type[0];
  const baseUrl = getBaseURL();
  const supplierLocale = getUrlCoupleSideLocale(supplierCountryCode);
  const supplierProfileURL = `${baseUrl}/${supplierLocale}${getSupplierUrl(supplier)}`;

  return {
    supplierId: supplier.id,
    supplierName: supplier.name,
    supplierCountryCode,
    supplierCountryName: Gazetteer.getCountryName(supplierCountryCode),
    supplierCategory,
    supplierTier,
    supplierProfileURL,
    supplierThumbnailURL,
  };
};

const collaboratorInvitePropertiesGeneric = (
  getState: () => IApplicationState,
): ICollaboratorInvitePropertiesGeneric => {
  const {
    acl: { inviteForm },
  } = getState();
  const location = getInviteFormLocation(getState);

  return {
    ...(inviteForm.appName && { collaboratorInviteApp: inviteForm.appName }),
    ...(location && { collaboratorInviteLocation: location }),
    ...(inviteForm.method && { collaboratorInviteMethod: inviteForm.method }),
  };
};

const collaborationAnalytics = (
  action: Action,
  bridebookAnalytics: WebAnalyticsContext,
  getState: () => IApplicationState,
): void => {
  const { type, payload } = action;
  const { track } = bridebookAnalytics.getMethods('Collaboration', filterLabelProps);

  switch (type) {
    case DashboardAnalyticsActions.TRIGGERED_INVITE_COLLABORATION_BANNER_ANALYTICS: {
      const location = getInviteFormLocation(getState);
      track({
        event: 'Triggered invite collaborator banner',
        category: 'Bride-site linked accounts',
        ...(location && { collaboratorInvitePopupLocation: location }),
      });
      break;
    }
    case AccessControlActionTypes.CLOSE_INVITE_COLLABORATOR_ANALYTICS: {
      const location = getInviteFormLocation(getState);
      track({
        event: 'Closed collaborator invite popup',
        category: 'Bride-site linked accounts',
        ...(location && { collaboratorInvitePopupLocation: location }),
      });
      break;
    }
    case AccessControlActionTypes.OPEN_INVITE_COLLABORATOR_ANALYTICS: {
      const location = getInviteFormLocation(getState);
      track({
        event: 'Triggered collaborator invite popup',
        category: 'Bride-site linked accounts',
        ...(location && { collaboratorInvitePopupLocation: location }),
      });
      break;
    }
    case AccessControlActionTypes.CLICKED_INVITE_COLLABORATOR_ANALYTICS: {
      const location = getInviteFormLocation(getState);
      track({
        event: 'Clicked Invite collaborator to wedding profile',
        category: 'Bride-site linked accounts',
        ...collaboratorInvitePropertiesGeneric(getState),
        ...(location && { collaboratorInviteLocation: location }),
      });
      break;
    }
    case AccessControlActionTypes.FAILED_TO_INVITE_COLLABORATOR_ANALYTICS: {
      const { errorMessage } = payload;
      track({
        event: 'Failed to invite collaborator to wedding profile',
        category: 'Bride-site linked accounts',
        ...collaboratorInvitePropertiesGeneric(getState),
        ...collaboratorPropertiesGeneric(getState),
        failedToInviteCollaboratorReason: errorMessage || '',
      });
      break;
    }
    case AccessControlActionTypes.INVITE_COLLABORATOR_SUCCESS_ANALYTICS: {
      const state = getState();
      const { user } = state.users;
      const { id } = state.weddings.profile;
      const supplier = payload?.supplier;
      const supplierTier = payload?.supplierTier;
      const supplierThumbnailURL = payload?.supplierThumbnailURL;
      const location = payload?.location;

      const userId = user ? user.id : null;
      if (userId) {
        serverSideTrack({
          state,
          event: CriticalWebEvents.INVITED_COLLABORATED_TO_WEDDING_PROFILE,
          specificEventProps: {
            ...collaboratorPropertiesGeneric(getState),
            ...collaboratorInvitePropertiesGeneric(getState),
            ...(location &&
              supplier &&
              supplierTier &&
              supplierThumbnailURL &&
              collaboratorFeedbackInviteSupplierPropertiesGeneric(
                supplier,
                supplierTier,
                supplierThumbnailURL,
              )),
            ...(location && { collaboratorInviteLocation: location }),
          },
          identifyProps: { weddingId: id },
        });
      }
      break;
    }
    case AccessControlActionTypes.ACCEPTED_COLLABORATOR_INVITE: {
      const state = getState();
      const { collaboratorInvite } = state.auth;
      const { user } = state.users;

      const userId = user ? user.id : null;

      if (
        userId &&
        collaboratorInvite &&
        collaboratorInvite.nonceId &&
        collaboratorInvite.inviteUrl
      ) {
        serverSideTrack({
          state,
          event: CriticalWebEvents.COLLABORATED_ON_WEDDING_PROFILE,
          specificEventProps: {
            ...collaboratorPropertiesGeneric(getState),
            ...collaboratorInvitePropertiesGeneric(getState),
          },
          identifyProps: { collaborator: true },
        });

        const { isCordova } = state.app.device;

        if (isCordova) {
          Appsflyer.logEvent('af_collaborator_registration', {
            af_customer_user_id: userId,
            af_registration_method: 'collaborator',
          });
        }
      }
      break;
    }
    case AccessControlActionTypes.INVITE_COLLABORATOR_ERROR: {
      const { error } = payload;
      const state = getState();
      const userId = state.users.user?.id;

      if (userId) {
        serverSideTrack({
          state,
          event: 'Failed to invite collaborator to wedding profile',
          specificEventProps: {
            ...collaboratorPropertiesGeneric(getState),
            ...collaboratorInvitePropertiesGeneric(getState),
            failedToInviteCollaboratorReason: (error && error.message) || '',
          },
        });
      }
      break;
    }
  }
};

export default collaborationAnalytics;
