import { isEqual } from 'lodash';
import {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { getIsDesktop } from 'lib/app/selectors';
import { getHomeSectionsOrder } from 'lib/home/get-home-sections-order';
import { THomeSectionDefinition, TSectionId } from 'lib/home/types';
import { getBookedSupplierCategories } from 'lib/shortlist/selectors';
import { useSelector } from 'lib/utils';
import { getVenueBooked } from 'lib/weddings/selectors';

interface ISectionState {
  shouldRender: ReturnType<THomeSectionDefinition['useShouldRender']>;
}

export type TSectionsContext = {
  sections: Partial<Record<TSectionId, ISectionState>>;
  setSections: Dispatch<SetStateAction<TSectionsContext['sections']>>;
  sectionsOrder: TSectionId[];
};

const initialValue: TSectionsContext = {
  sections: {},
  sectionsOrder: [],
  setSections: () => {
    throw new Error('setSections function must be overridden');
  },
};

const SectionsContext = createContext<TSectionsContext>(initialValue);

interface ISectionsContextProviderProps {
  children: ReactNode;
}
export const SectionsContextProvider: FC<ISectionsContextProviderProps> = ({ children }) => {
  const [sections, setSections] = useState<TSectionsContext['sections']>({});
  const isDesktop = useSelector(getIsDesktop);
  const venueBooked = useSelector(getVenueBooked);
  const bookedCategories = useSelector(getBookedSupplierCategories);
  const isPhotographerBooked = bookedCategories?.includes('photo');
  const sectionsOrder = useMemo(
    () =>
      getHomeSectionsOrder({
        isDesktop,
        bookedSuppliers: { venue: venueBooked, photo: !!isPhotographerBooked },
      }),
    [isDesktop, isPhotographerBooked, venueBooked],
  );
  const contextValue = useMemo(
    () => ({ sections, setSections, sectionsOrder }),
    [sections, sectionsOrder],
  );

  // left for debugging:
  // useEffect(() => {
  //   console.table(
  //     sectionsOrder.map((order) => ({
  //       id: order,
  //       loaded: sections[order]?.shouldRender.state === 'loaded',
  //       show: sections[order]?.shouldRender['value'],
  //     })),
  //   );
  // }, [sections, sectionsOrder]);

  return <SectionsContext.Provider value={contextValue}>{children}</SectionsContext.Provider>;
};

export const useSectionsContext = (): TSectionsContext => {
  const context = useContext(SectionsContext);
  if (context === undefined) {
    throw new Error('useSectionsContext must be used within a SectionsContextProvider');
  }

  return context;
};

export const usePreviousSectionsLoaded = (sectionId: TSectionId) => {
  const { sections, sectionsOrder } = useSectionsContext();

  const canSectionRender = useMemo(() => {
    let canRender = true;

    for (let i = 0; i < sectionsOrder.length; i++) {
      const iteratedSectionId = sectionsOrder[i];
      if (iteratedSectionId === sectionId) break;

      const iteratedSectionRenderState = sections[iteratedSectionId];
      if (
        !iteratedSectionRenderState ||
        iteratedSectionRenderState.shouldRender.state !== 'loaded'
      ) {
        canRender = false;
      }
    }

    return canRender;
  }, [sectionId, sections, sectionsOrder]);

  return canSectionRender;
};

export const useSectionUpdated = (sectionId: TSectionId) => {
  const { setSections } = useSectionsContext();

  const updateSection = useCallback(
    (shouldRender: ISectionState['shouldRender']) => {
      setSections((sections) => {
        if (isEqual(sections[sectionId]?.shouldRender, shouldRender)) {
          return sections;
        }

        return {
          ...sections,
          [sectionId]: {
            shouldRender,
          },
        };
      });
    },
    [sectionId, setSections],
  );
  return {
    updateSection,
  };
};
