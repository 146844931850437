import produce from 'immer';
import {
  Action,
  IReducersImmer,
  IVenueConfirmState,
  VenueConfirmSlides,
  VenueFoundAnswers,
} from 'lib/types';
import { getReducerActions } from 'lib/utils';
import {
  SaveSupplierIdToConfirmAction,
  SaveVenueEstimateAction,
  TriggerBookingConfirmationModalAction,
  VenueBookedToggleAction,
  VenueConfirmActionTypes,
  VenueConfirmChangeSlideAction,
  VenueConfirmSaveVenueInStateAction,
} from './action-types';

const initialState: IVenueConfirmState = {
  showVenueConfirm: false,
  hasFoundVenue: VenueFoundAnswers.unselected,
  venueToBook: null,
  currentSlide: VenueConfirmSlides.intro,
  triggerLocation: '',
  venuePrice: 0,
  supplierIdToConfirm: null,
  weddingYear: null,
  weddingDay: null,
  weddingMonth: null,
  showIntro: false,
  supplierToBook: null,
  searchSupplierType: null,
};

const reducers: IReducersImmer<IVenueConfirmState> = (draft) => ({
  [VenueConfirmActionTypes.TRIGGER_BOOKING_CONFIRMATION_MODAL]: (
    action: TriggerBookingConfirmationModalAction,
  ) => {
    const {
      location: triggerLocation,
      showIntro = false,
      supplier,
      searchSupplierType,
    } = action.payload;

    return {
      ...initialState,
      showVenueConfirm: true,
      showIntro,
      triggerLocation,
      supplierToBook: supplier,
      searchSupplierType,
    };
  },

  [VenueConfirmActionTypes.CLOSE_BOOKING_CONFIRMATION_MODAL]: () => ({
    ...initialState,
  }),

  [VenueConfirmActionTypes.VENUE_BOOKED_TOGGLE]: ({ payload }: VenueBookedToggleAction) => {
    draft.hasFoundVenue = payload;
    draft.venueToBook = payload === VenueFoundAnswers.yes ? draft.venueToBook : null;
  },

  [VenueConfirmActionTypes.SAVE_VENUE_TO_BOOK]: (action: VenueConfirmSaveVenueInStateAction) => {
    draft.venueToBook = action.payload;
  },

  [VenueConfirmActionTypes.CHANGE_SLIDE]: (action: VenueConfirmChangeSlideAction) => {
    draft.currentSlide = action.payload;
  },

  [VenueConfirmActionTypes.SAVE_VENUE_PRICE]: (action: SaveVenueEstimateAction) => {
    draft.venuePrice = action.payload;
  },

  [VenueConfirmActionTypes.SAVE_WEDDING_YEAR]: (action: SaveVenueEstimateAction) => {
    draft.weddingYear = action.payload;
  },

  [VenueConfirmActionTypes.SAVE_WEDDING_MONTH]: (action: SaveVenueEstimateAction) => {
    draft.weddingMonth = action.payload;
  },
  [VenueConfirmActionTypes.SAVE_WEDDING_DAY]: (action: SaveVenueEstimateAction) => {
    draft.weddingDay = action.payload;
  },
  [VenueConfirmActionTypes.SAVE_SUPPLIER_ID_TO_CONFIRM]: (
    action: SaveSupplierIdToConfirmAction,
  ) => {
    draft.supplierIdToConfirm = action.payload;
  },
  [VenueConfirmActionTypes.CLOSE_CONFIRMATION_MODAL]: () => {
    draft.currentSlide = VenueConfirmSlides.intro;
    draft.venueToBook = null;
  },
});

const reducersActions = getReducerActions(reducers);

/*
	This is a wrapper function which runs a proper reducer from the object above.
*/
const reducer = (state: IVenueConfirmState = initialState, action: Action): IVenueConfirmState => {
  if (!reducersActions[action.type]) {
    return state;
  }

  try {
    return produce(state, (draft) => reducers(draft)[action.type](action));
  } catch (err) {
    return state;
  }
};

export default reducer;
