import { WebAnalyticsContext } from 'lib/bbcommon/utils/bridebook-analytics';
import {
  ISubmittedFeedbackRatingModalAnalytics,
  RatingSearchAnalyticsAction,
} from 'lib/rating-search/analytics/actions';
import { Action } from 'lib/types';

export default function ratingSearchAnalytics(
  action: Action,
  bridebookAnalytics: WebAnalyticsContext,
) {
  const { track } = bridebookAnalytics.getMethods('ratingSearch');

  switch (action.type) {
    case RatingSearchAnalyticsAction.TriggeredRatingModal: {
      track({
        event: 'Triggered feedback form',
        feedbackSource: 'searchResultsPageRatingToast',
      });
      break;
    }

    case RatingSearchAnalyticsAction.ClosedRatingModal: {
      track({
        event: 'Clicked',
        category: 'Feedback',
        cta: 'Dismiss search results rating toast',
        clickedSection: 'searchResultsPage',
        clickedLocation: 'ratingToast',
      });
      break;
    }

    case RatingSearchAnalyticsAction.SubmittedFeedback: {
      const { payload } = action as ISubmittedFeedbackRatingModalAnalytics;

      track({
        event: 'Submitted feedback',
        feedbackDescription: payload.feedback.toString(),
        feedbackMethod: payload.feedbackMethod,
        feedbackType: 'other',
        feedbackTag: 'Searching venues / suppliers',
        feedbackSource: 'searchResultsPageRatingToast',
      });
      break;
    }

    default:
      break;
  }
}
