import { IArticle } from '../schemas/article/types';

/**
 * extract article tags
 * extracts and filters article tags from article object
 * @method extractArticleTags
 * @param {IArticle} article - article object
 * @returns {Array} - Array with tag slugs
 */
const extractArticleTags = ({ terms }: IArticle | { terms: IArticle['terms'] }): string[] => {
  let tags: string[] = [];
  if (terms && Array.isArray(terms.post_tag)) {
    tags = terms.post_tag.map((tag) => tag.slug).filter((slug) => slug !== 'all');
  }

  return tags;
};

export default extractArticleTags;
