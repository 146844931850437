import React from 'react';
import { A, Box, Button, ImageX } from '@bridebook/ui';
import Head from 'components/app/head';
import { imgixBaseURL } from 'lib/utils';
import getComponentStyle from './delete-account-success-content.style';

interface IProps {
  button?: {
    text: string;
    href: string;
  };
  desc: string;
  heading: string;
}

const desktopMB = 32;

const DeleteAccountSuccessContent = ({ button, heading, desc }: IProps) => {
  const styles = getComponentStyle();

  return (
    <>
      <Head />
      <Box style={styles.wrapper} mb={button ? [0, 0] : [0, desktopMB]}>
        <Box w={103} mb={[8, 4]}>
          <ImageX
            src={imgixBaseURL + '/assets/icons/bb-heart-sad.svg?auto=compress,format'}
            w={103}
            h={138}
            fit="scale"
          />
        </Box>
        <Box style={styles.textWrapper}>
          <Box as="h1" style={styles.heading}>
            {heading}
          </Box>
          <Box mt={[6, 4]} mb={8} style={styles.info}>
            {desc}
          </Box>
        </Box>
      </Box>
      {button && (
        <Box style={styles.buttonWrapper} mb={[0, desktopMB]}>
          <A
            href={button.href}
            target="_blank"
            rel="noreferrer noopener"
            style={styles.button}
            noUnderline>
            <Button
              theme="primary"
              text={button.text}
              style={styles.buttonInner}
              dataTest="delete-account-success-button"
            />
          </A>
        </Box>
      )}
    </>
  );
};

export default DeleteAccountSuccessContent;
