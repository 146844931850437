import { IDropdownOption } from '@bridebook/ui';
import { getI18n } from 'lib/i18n/getI18n';

export const getFeedbackSubject = (translate = true): IDropdownOption[] => [
  {
    label: translate ? getI18n().t('settings:feedback.checklist') : 'Checklist',
    value: 'checklist',
  },
  {
    label: translate ? getI18n().t('settings:feedback.guestlist') : 'Guest list',
    value: 'guestlist',
  },
  {
    label: translate ? getI18n().t('settings:feedback.budget') : 'Budget',
    value: 'budget',
  },
  {
    label: translate
      ? getI18n().t('settings:feedback.shortlist')
      : 'Shortlist and booked suppliers',
    value: 'shortlist',
  },
  {
    label: translate ? getI18n().t('settings:feedback.scrapbook') : 'Scrapbook',
    value: 'scrapbook',
  },
  {
    label: translate ? getI18n().t('settings:feedback.advice') : 'Advice',
    value: 'advice',
  },
  {
    label: translate ? getI18n().t('settings:feedback.search') : 'Searching venues / suppliers',
    value: 'search',
  },
  {
    label: translate ? getI18n().t('settings:feedback.enquiries') : 'Making enquiries',
    value: 'enquiries',
  },
  {
    label: translate ? getI18n().t('settings:feedback.design') : 'Navigation / design',
    value: 'navigation',
  },
  {
    label: translate ? getI18n().t('settings:feedback.technical') : 'Technical issues',
    value: 'technical-issues',
  },
  {
    label: translate ? getI18n().t('settings:feedback.notifications') : 'Emails / notifications',
    value: 'notifications',
  },
  {
    label: translate ? getI18n().t('settings:feedback.featureRequest') : 'New feature request',
    value: 'feature-request',
  },
  {
    label: translate ? getI18n().t('settings:feedback.account') : 'My account',
    value: 'my-account',
  },
  {
    label: translate
      ? getI18n().t('settings:feedback.partner')
      : 'Inviting a partner / collaborator',
    value: 'collaboration',
  },
  {
    label: translate
      ? getI18n().t('settings:feedback.suggestProfessional')
      : 'Suggest a professional to be listed',
    value: 'suggest-supplier',
  },
  {
    label: translate ? getI18n().t('settings:feedback.general') : 'General feedback',
    value: 'general',
  },
  {
    label: translate ? getI18n().t('settings:feedback.other') : 'Other',
    value: 'other',
  },
];
