import { WritableDraft } from 'immer/dist/types/types-external';
import { ITask as IUserTask } from '@bridebook/models/source/models/Users/Tasks.types';
import { ITask as IWeddingTask } from '@bridebook/models/source/models/Weddings/Tasks.types';
import { SentryMinimal } from '@bridebook/toolbox/src/sentry';
import { TodayTaskFlowDefinitionApi } from '@bridebook/toolbox/src/today-task/types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { TaskTileData } from 'components/home/todays-task/task-tile';
import { AuthActionTypes } from 'lib/auth/action-types';
import { TTaskFlowInstance, getCurrentTaskAndIndex } from 'lib/task/task-flow-engine';
import { TaskState } from './types';

const initialState: TaskState = {
  todayTaskFlowDefinition: {
    status: 'init',
  },
  todayTaskTileData: {
    registered: {},
  },
  taskFlows: {
    status: 'init',
  },
  todayTasks: {
    status: 'notLoaded',
  },
  addCollaboratorTodayTask: {
    modal: {
      show: false,
    },
  },
  carousel: {
    currentTaskIndex: 0,
    isFadeIn: true,
    currentTaskId: null,
    currentSessionId: null,
  },
};

const shouldUpdateCarousel = (
  currentCarousel: TaskState['carousel'],
  todayTaskFlow: TTaskFlowInstance,
) => {
  const carouselItemFoundInTodayTaskFlow = todayTaskFlow.currentSession.notCompletedTasks.some(
    (notCompletedTask) => notCompletedTask === currentCarousel.currentTaskId,
  );
  return (
    !carouselItemFoundInTodayTaskFlow ||
    currentCarousel.currentSessionId !== todayTaskFlow.currentSession.id
  );
};

const updateCarousel = (
  state: WritableDraft<TaskState>,
  todayTaskFlowInstance: TTaskFlowInstance,
) => {
  const taskAndIndex = getCurrentTaskAndIndex(todayTaskFlowInstance);
  state.carousel.currentTaskId = taskAndIndex.currentTaskId;
  state.carousel.currentTaskIndex = taskAndIndex.currentTaskIndex;
  state.carousel.currentSessionId = todayTaskFlowInstance.currentSession.id;
};

export const taskSlice = createSlice({
  name: 'task',
  initialState,
  reducers: {
    taskFlowDefinitionInitialized: (
      state,
      action: PayloadAction<{ definition: TodayTaskFlowDefinitionApi }>,
    ) => {
      state.todayTaskFlowDefinition = {
        status: 'loaded',
        data: action.payload.definition,
      };
    },
    taskFlowsInitialized: (
      state,
      action: PayloadAction<{ todayTaskFlowInstance: TTaskFlowInstance }>,
    ) => {
      state.taskFlows = {
        status: 'loaded',
        todayTaskFlow: action.payload.todayTaskFlowInstance,
      };
      if (shouldUpdateCarousel(state.carousel, state.taskFlows.todayTaskFlow)) {
        updateCarousel(state, action.payload.todayTaskFlowInstance);
      }
    },
    taskFlowsInitializationFailed: (state) => {
      state.taskFlows = {
        status: 'error',
      };
    },
    taskFlowsReset: (state) => {
      state.taskFlows = {
        status: 'init',
      };
    },
    taskComponentMounted: (state) => {
      state.taskFlows = {
        status: 'loading',
      };
    },
    taskFlowsUpdated: (
      state,
      action: PayloadAction<{ todayTaskFlowInstance: TTaskFlowInstance }>,
    ) => {
      if (state.taskFlows.status === 'loaded') {
        state.taskFlows.todayTaskFlow = action.payload.todayTaskFlowInstance;
        if (shouldUpdateCarousel(state.carousel, state.taskFlows.todayTaskFlow)) {
          updateCarousel(state, action.payload.todayTaskFlowInstance);
        }
      }
    },
    updateTodayTaskFlow: (
      state,
      action: PayloadAction<{ todayTaskFlowInstance: TTaskFlowInstance }>,
    ) => {
      state.taskFlows = {
        status: 'loaded',
        todayTaskFlow: action.payload.todayTaskFlowInstance,
      };
      if (shouldUpdateCarousel(state.carousel, action.payload.todayTaskFlowInstance)) {
        updateCarousel(state, action.payload.todayTaskFlowInstance);
      }
    },
    registerTodayTaskTileData: (
      state,
      action: PayloadAction<{ todayTaskTileData: TaskTileData }>,
    ) => {
      const todayTaskTileData = action.payload.todayTaskTileData;
      state.todayTaskTileData.registered[todayTaskTileData.id] = todayTaskTileData;
    },
    weddingTasksLoaded: (state, action: PayloadAction<Record<string, IWeddingTask>>) => {
      if (state.todayTasks.status === 'partiallyLoaded' && state.todayTasks.user) {
        state.todayTasks = {
          status: 'loaded',
          wedding: action.payload,
          user: state.todayTasks.user,
        };
      } else if (
        (state.todayTasks.status === 'partiallyLoaded' && state.todayTasks.wedding) ||
        state.todayTasks.status === 'notLoaded'
      ) {
        state.todayTasks = {
          status: 'partiallyLoaded',
          wedding: action.payload,
        };
      } else if (state.todayTasks.status === 'loaded') {
        state.todayTasks = {
          status: 'loaded',
          wedding: action.payload,
          user: state.todayTasks.user,
        };
      } else {
        SentryMinimal().captureMessage('Something is wrong, today tasks were not updated', {
          tags: {
            source: 'taskFlows',
          },
        });
      }
    },
    weddingTaskUpdated: (state, action: PayloadAction<IWeddingTask>) => {
      if (
        (state.todayTasks.status === 'loaded' || state.todayTasks.status === 'partiallyLoaded') &&
        state.todayTasks.wedding
      ) {
        state.todayTasks.wedding[action.payload.id] = action.payload;
      }
    },

    userTasksLoaded: (state, action: PayloadAction<Record<string, IUserTask>>) => {
      if (state.todayTasks.status === 'partiallyLoaded' && state.todayTasks.wedding) {
        state.todayTasks = {
          status: 'loaded',
          wedding: state.todayTasks.wedding,
          user: action.payload,
        };
      } else if (
        (state.todayTasks.status === 'partiallyLoaded' && state.todayTasks.user) ||
        state.todayTasks.status === 'notLoaded'
      ) {
        state.todayTasks = {
          status: 'partiallyLoaded',
          user: action.payload,
        };
      } else if (state.todayTasks.status === 'loaded') {
        state.todayTasks = {
          status: 'loaded',
          wedding: state.todayTasks.wedding,
          user: action.payload,
        };
      } else {
        SentryMinimal().captureMessage('Something is wrong, today tasks were not updated', {
          tags: {
            source: 'taskFlows',
          },
        });
      }
    },
    userTaskUpdated: (state, action: PayloadAction<IUserTask>) => {
      if (
        (state.todayTasks.status === 'loaded' || state.todayTasks.status === 'partiallyLoaded') &&
        state.todayTasks.user
      ) {
        state.todayTasks.user[action.payload.id] = action.payload;
      }
    },
    toggleAddCollaboratorModal: (state, action: PayloadAction<boolean>) => {
      state.addCollaboratorTodayTask.modal.show = action.payload;
    },
    updateCarouselTask: (
      state,
      action: PayloadAction<{
        currentTaskIndex: number;
        currentTaskId: string;
        articleSlug?: string;
      }>,
    ) => {
      state.carousel.currentTaskIndex = action.payload.currentTaskIndex;
      state.carousel.currentTaskId = action.payload.currentTaskId;
    },
    updateCarouselFadeIn: (state, action: PayloadAction<{ fadeIn: boolean }>) => {
      state.carousel.isFadeIn = action.payload.fadeIn;
    },
  },
  extraReducers: {
    [AuthActionTypes.SIGN_OUT_COMPLETED]: () => initialState,
  },
});

export default taskSlice.reducer;
