import React from 'react';
import { Box } from '@bridebook/ui';
import { IconCrossBold } from '@bridebook/ui/src/icons';
import { IconChevronFat } from '@bridebook/ui/src/icons/dynamic';
import Modal from 'components/bbcommon/modal';
import styles from './full-mobile-modal.style';

interface IProps {
  id: string;
  isOpen: boolean;
  showCloseButton?: boolean;
  onClose?: () => void;
  onBack?: () => void | null;
  children?: React.ReactNode;
  maxWidth?: number;
  hideDivider?: boolean;
}

const FullMobileModal = ({
  id,
  isOpen,
  onClose,
  onBack,
  children,
  maxWidth,
  hideDivider = false,
}: IProps) => (
  <Modal show={isOpen} id={id} onClose={onClose} maxWidth={maxWidth}>
    <Box style={styles.wrapper}>
      <Box style={styles.header(hideDivider)}>
        {onBack && (
          <Box style={styles.backIconWrapper}>
            <Box onClick={onBack}>
              <Box style={styles.icon}>
                <IconChevronFat
                  color="space"
                  style={{ transform: 'rotate(90deg) translateY(1px)' }}
                />
              </Box>
            </Box>
          </Box>
        )}
        {onClose && (
          <Box style={styles.closeIconWrapper}>
            <Box onClick={onClose}>
              <Box style={styles.icon}>
                <IconCrossBold width={11} />
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      <Box style={styles.container}>{children}</Box>
    </Box>
  </Modal>
);

export default FullMobileModal;
