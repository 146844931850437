const CALLS_TIMEOUT = 28000;
const ANALYTICS_OPTIONS = null;

declare var global: NodeJS.Global & { analytics: any };

export interface ITrackProps extends Record<string, any> {
  event: string;
  label?: string;
  category?: string;
}

export interface IDefaultProps extends Record<string, any> {
  sessionId: string | null;
}

interface IOptionalProps {
  identifyCallback?: () => void;
  trackCallback?: () => void;
  userId?: string;
}

interface IIdentifyProps extends Record<string, any> {
  userId?: string;
}

export interface IFilterLabelProps {
  (props: Omit<ITrackProps, 'event'>): Record<string, any>;
}

export interface IMethods extends ReturnType<AnalyticsContext['getMethods']> {}

export abstract class AnalyticsContext {
  private readonly _analytics: any;
  private readonly _defaultProps: IDefaultProps;

  get analytics() {
    return this._analytics || global.analytics;
  }

  constructor(analytics: any, defaultProps: IDefaultProps) {
    this._analytics = analytics;
    this._defaultProps = defaultProps;
  }

  alias(userId: string) {
    this.analytics.alias(userId);
  }

  page(name: string, properties: Record<string, any>) {
    this.analytics.page(name, { ...this._defaultProps, ...properties });
  }

  getMethods(category: string, filterLabelProps?: IFilterLabelProps) {
    const analytics = this.analytics;
    const defaultProps = this._defaultProps;
    return new (class {
      track = (properties: ITrackProps, callback?: () => void) =>
        new Promise<void>((resolve, reject) => {
          const { event, ...restProps } = properties;
          let timeout: NodeJS.Timeout | null = null;

          if (typeof callback === 'function') {
            timeout = setTimeout(
              () => reject(new Error(`Failed to call callback of track event '${event}'`)),
              CALLS_TIMEOUT,
            );
          }

          const label = JSON.stringify(
            typeof filterLabelProps === 'function' ? filterLabelProps(restProps) : restProps,
          );
          analytics.track(
            event,
            {
              category,
              ...defaultProps,
              ...restProps,
              label,
            },
            ANALYTICS_OPTIONS,
            () => {
              try {
                if (typeof callback === 'function') {
                  callback();
                }
                if (timeout) {
                  clearTimeout(timeout);
                }
                resolve();
              } catch (e) {
                reject(e);
              }
            },
          );
        });

      identify = (userId: string, traits: Record<string, any>, callback?: () => void) =>
        new Promise<void>((resolve, reject) => {
          let timeout: NodeJS.Timeout | null = null;

          if (typeof callback === 'function') {
            timeout = setTimeout(
              () => reject(new Error(`Failed to call callback of identify`)),
              CALLS_TIMEOUT,
            );
          }

          analytics.identify(userId, traits, ANALYTICS_OPTIONS, () => {
            try {
              if (typeof callback === 'function') {
                callback();
              }
              if (timeout) {
                clearTimeout(timeout);
              }
              resolve();
            } catch (e) {
              reject(e);
            }
          });
        });

      async identifyWithTrack(
        identifyProps: IIdentifyProps,
        trackProps?: ITrackProps,
        { identifyCallback, trackCallback, userId }: IOptionalProps = {},
      ) {
        const { userId: uid } = identifyProps;
        delete identifyProps.userId;

        await this.identify(uid || userId, identifyProps, identifyCallback);

        if (trackProps) {
          await this.track(trackProps, trackCallback);
        }
      }
    })();
  }
}
