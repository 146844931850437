import { TSectionId } from 'lib/home/types';

interface IParams {
  isDesktop: boolean;
  bookedSuppliers: {
    venue: boolean;
    photo: boolean;
  };
}

type HomeSectionOrderTypes = 'venue' | 'photo' | 'default';

type SectionsOrder = Record<HomeSectionOrderTypes, TSectionId[]>;

/*
  According to config described here: https://docs.google.com/spreadsheets/d/1PeVqZ2gv09ahPoeYKeGTmiJjVNTgZUAzZyQQIDOe64c/edit?gid=221955663#gid=221955663
  Some widgets are not ready so they are commented for now
 */
export const getHomeSectionsOrder = (params: IParams) => {
  const SECTIONS_ORDER: SectionsOrder = {
    venue: [
      'HomeToolsCards',
      'ChecklistToolWidget',
      'TrendingCarousel',
      'PopularVenueTypes',
      'LateAvailabilityBanner',
      'ToolWidgetsCarousel',
      'PopularVenuesCarousel',
      'RecentArticles',
      'WeddingFairs',
      //FavouritesToolWidget'
      'VenueRexCarousel',
      'SpecialOffers',
      'ExploreVenuesWorldwide',
      'RealWeddings',
      'BookedSupplierWidget',
      'SupplierTypes',
      'MatchWithSuppliersBanner',
    ],
    photo: [
      'HomeToolsCards',
      'ChecklistToolWidget',
      'SupplierTypes',
      'TrendingCarousel',
      'GuestlistToolWidget',
      'SupplierCategoryCarousel',
      'BookedSupplierWidget',
      'RealWeddings',
      // FavouritesToolWidget
      'RecentlyViewedSuppliers',
      'RecentArticles',
      'MatchWithSuppliersBanner',
      'BudgetToolWidget',
    ],
    default: [
      'HomeToolsCards',
      'ChecklistToolWidget',
      'SupplierTypes',
      'GuestlistToolWidget',
      'SupplierCategoryCarousel',
      'BookedSupplierWidget',
      'BudgetToolWidget',
      'RealWeddings',
      'RecentlyViewedSuppliers',
      'RecentArticles',
      'MatchWithSuppliersBanner',
      // FavouritesToolWidget'
    ],
  };

  if (!params.bookedSuppliers.venue) {
    return SECTIONS_ORDER.venue;
  }
  if (!params.bookedSuppliers.photo) {
    return SECTIONS_ORDER.photo;
  }
  return SECTIONS_ORDER.default;
};
