import { assocPath, compose, merge } from 'ramda';
import { DatePickerAction, DatePickerState, DatePickerType } from 'lib/datepicker/types';
import { datePickerDefault } from 'lib/datepicker/utils/datepicker-default';
import DatePickerModel from './datepicker';
import validateDatepickerTabs from './utils/validate-datepicker-tabs';

const datePickerState: DatePickerState = {
  datepicker: merge({}, new DatePickerModel()),
  datePickerDate: merge({}, datePickerDefault),
  states: {
    'year-screen': false,
    'month-screen': true,
    'day-screen': true,
  },
  datepickerModalOpened: false,
  valid: false,
};

const initialState: DatePickerType = {
  instances: {},
  tabClickedByUser: false,
};

export default function reducer(
  state: DatePickerType = initialState,
  action: DatePickerAction,
): DatePickerType {
  switch (action.type) {
    case 'REGISTER_DATEPICKER': {
      const { datePickerUI, activeTab, datePickerId } = action.payload;
      const { instances } = state;
      // note if you refactor the following line, server rendering won't work
      if (!instances[datePickerId]) {
        const datePickerDate = !datePickerUI ? merge({}, datePickerDefault) : datePickerUI;
        instances[datePickerId] = merge({}, datePickerState);
        const newState = compose(
          assocPath<DatePickerState['datePickerDate'], DatePickerType>(
            ['instances', datePickerId, 'datePickerDate'],
            datePickerDate,
          ),
          assocPath(['instances', datePickerId, 'datepicker', 'activeTab'], activeTab),
        )(state);
        const { datepicker } = state.instances[datePickerId];
        const states = validateDatepickerTabs(datePickerDate, datepicker);
        return compose(
          assocPath<DatePickerState['states'], DatePickerType>(
            ['instances', datePickerId, 'states'],
            states,
          ),
        )(newState);
      }
      return compose(
        assocPath<DatePickerState['datepicker']['activeTab'], DatePickerType>(
          ['instances', datePickerId, 'datepicker', 'activeTab'],
          activeTab,
        ),
      )(state);
    }

    case 'DATEPICKER_DATE_VALID':
    case 'DATEPICKER_VALIDATE_CURRENT_TAB_SUCCESS': {
      const { datePickerId } = action.payload;
      const assocFn = assocPath<boolean, DatePickerType>(
        ['instances', datePickerId, 'valid'],
        true,
      );
      return compose<DatePickerType, DatePickerType>(assocFn)(state);
    }

    case 'DATEPICKER_VALIDATE_CURRENT_TAB_ERROR': {
      const { datePickerId } = action.payload;
      return compose(
        assocPath<boolean, DatePickerType>(['instances', datePickerId, 'valid'], false),
      )(state);
    }

    case 'CHANGE_DATEPICKER_DATE': {
      const { name, value, datePickerId } = action.payload;
      const newState = compose(
        assocPath<string | number, DatePickerType>(
          ['instances', datePickerId, 'datePickerDate', name],
          value,
        ),
      )(state);

      const { datePickerDate, datepicker } = newState.instances[datePickerId];
      const states = validateDatepickerTabs(datePickerDate, datepicker);
      return compose(
        assocPath<DatePickerState['states'], DatePickerType>(
          ['instances', datePickerId, 'states'],
          states,
        ),
      )(newState);
    }

    case 'DATEPICKER_CHANGE_TAB_SUCCESS': {
      const { datePickerId } = action.payload;
      return compose(
        assocPath<string, DatePickerType>(
          ['instances', datePickerId, 'datepicker', 'activeTab'],
          action.payload.tab,
        ),
        assocPath(['instances', datePickerId, 'datepickerError'], null),
      )(state);
    }

    case 'CANCEL_DATEPICKER_EDIT': {
      const { datePickerId } = action.payload;
      const { instances } = state;
      if (instances[datePickerId]) {
        return compose(
          assocPath<DatePickerState['datepicker']['activeTab'], DatePickerType>(
            ['instances', datePickerId, 'datepicker', 'activeTab'],
            'year-screen',
          ),
          assocPath(['instances', datePickerId, 'datepickerModalOpened'], false),
        )(state);
      }
      return state;
    }

    case 'TOGGLE_DATEPICKER': {
      const { datePickerId, modalState } = action.payload;
      return compose(
        assocPath<boolean, DatePickerType>(
          ['instances', datePickerId, 'datepickerModalOpened'],
          modalState,
        ),
      )(state);
    }

    case 'RESET_DATEPICKER_DATE': {
      const { datePickerId, newDateObject } = action.payload;
      const { instances } = state;
      if (instances[datePickerId]) {
        return compose(
          assocPath<NonNullable<DatePickerState['weddingDate']>['datePickerDate'], DatePickerType>(
            ['instances', 'weddingDate', 'datePickerDate'],
            merge(datePickerDefault, newDateObject),
          ),
        )(state);
      }
      return state;
    }

    case 'START_DATEPICKER_DATE_EDIT': {
      const { date } = action.payload;
      return compose(
        assocPath<NonNullable<DatePickerState['weddingDate']>['datePickerDate'], DatePickerType>(
          ['instances', 'weddingDate', 'datePickerDate'],
          merge(datePickerDefault, date),
        ),
        assocPath(['instances', 'weddingDate', 'datepicker'], merge({}, new DatePickerModel())),
      )(state);
    }

    case 'TAB_CLICKED_BY_USER':
      return { ...state, tabClickedByUser: true };

    case 'TAB_CLICKED_BY_USER_RESET':
      return { ...state, tabClickedByUser: false };

    default:
      return state;
  }
}
