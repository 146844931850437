import { createWrapper } from 'app-shared/lib/next-redux-wrapper';
import { IConnectedDispatch } from 'lib/types';
import createReduxStore from '../lib/create-redux-store';

const makeStore = () => {
  const reduxStore = createReduxStore({});
  return reduxStore.store;
};

export type TStore = Omit<ReturnType<typeof makeStore>, 'dispatch'> & IConnectedDispatch;

export const wrapper = createWrapper(makeStore, { id: 'full', debug: false });
