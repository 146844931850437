import type { IGuestFilter } from 'lib/guestlist/types';

const GuestFilterDefault: IGuestFilter = {
  // @ts-ignore FIXME
  invitationType: null,
  // @ts-ignore FIXME
  rsvpStatus: null,
  search: null,
  // @ts-ignore FIXME
  gender: null,
  // @ts-ignore FIXME
  age: null,
  // @ts-ignore FIXME
  contacts: null,
};

export default GuestFilterDefault;
