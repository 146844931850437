import { pathOr } from 'ramda';
import { IApplicationState } from 'lib/types';
import { createDeepEqualSelector } from 'lib/utils/selectors';

export const bookmarks = (state: IApplicationState): number[] | null =>
  pathOr(null, ['weddings', 'profile', 'bookmarks'], state);

export const getTotalArticles = (state: IApplicationState) => state.articles.recordsFound;

export const getAuthorDetails = (state: IApplicationState) => state.articles.currentAuthor;

export const getRelatedArticles = (state: IApplicationState) => state.articles.relatedArticles;

export const getIsAllTagsToggle = (state: IApplicationState) => state.articles.showAllTags;

export const getBookmarks = createDeepEqualSelector(
  [bookmarks],
  (bookmarks: number[] | null): number[] => bookmarks || [],
);
