import { ICollaboratorInviteType } from 'lib/auth/types';

const CollaboratorInvite: ICollaboratorInviteType = {
  nonceId: '',
  nonceSecret: '',
  inviteExpired: false,
  inviteError: null,
  inviteUrl: '',
  partners: [],
  role: null,
  method: null,
};

export default CollaboratorInvite;
