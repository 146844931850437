import { useTranslation } from 'next-i18next';
import React, { useRef } from 'react';
import { useMarket } from 'app-shared/lib/i18n/hooks/use-market';
import { useAuthContext } from 'components/auth/auth-context';
import FullMobileModal from 'components/common/full-mobile-modal';
import { getContactFormHelpUrl } from 'lib/url-helper';
import DeleteAccountSuccessContent from '../delete-account/delete-account-success-content';

const NoticeContent = () => {
  const { t } = useTranslation();
  const clickedClose = useRef(false);
  const { country } = useMarket();
  const { logout } = useAuthContext();

  const contactFromLink = getContactFormHelpUrl(country);

  const onClose = async () => {
    if (clickedClose.current) return;

    clickedClose.current = true;

    await logout();
  };

  return (
    <FullMobileModal isOpen id="user-deletion-notice" onClose={onClose}>
      <DeleteAccountSuccessContent
        button={{ href: contactFromLink, text: t('accountDeletion.notice.button') }}
        desc={t('accountDeletion.notice.desc')}
        heading={t('accountDeletion.notice.heading')}
      />
    </FullMobileModal>
  );
};

export default NoticeContent;
