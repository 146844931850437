export enum DashboardAnalyticsActions {
  USER_UPLOADED_COVER_PHOTO_ANALYTICS = '[Analytics] [Dashboard] User uploaded cover photo analytics',
  TRIGGERED_CONFETTI = '[Analytics] Triggered confetti',
  TRIGGERED_INVITE_COLLABORATION_BANNER_ANALYTICS = '[Analytics] [Dashboard] Triggered invite collaborator banner',
  FEATURE_CARD_CLICKED_ANALYTICS = '[Analytics] Feature card clicked',
  CLICKED_TO_ADD_WEDDING_DETAILS_TO_THE_COUNTDOWN_WIDGET = '[Analytics] Clicked to add wedding details to the countdown widget',
  FEATURE_CARD_VIEWED_ANALYTICS = '[Analytics] Viewed feature card',
  TODAYS_TASK_CLICKED_ANALYTICS = '[Analytics] Todays task clicked',
  SCROLLED_THROUGH_TODAYS_TASKS_ANALYTICS = '[Analytics] Scrolled through todays tasks',
  NEW_TODAYS_TASK_SESSION_ANALYTICS = '[Analytics] New todays task session',
  TODAYS_TASK_VIEWED_ANALYTICS = '[Analytics] Todays task viewed',
}
