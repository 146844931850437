import produce from 'immer';
import { any, values } from 'ramda';
import { Guests } from '@bridebook/models/source/models/Weddings/Guests';
import {
  GuestlistActionTypes,
  IAddPlusOneAction,
  IEditGuestAction,
  IEditGuestAddressAction,
  IEditGuestContactsAction,
  IEditGuestFormAction,
  IEditInvitationTypeAction,
  IEditRsvpStatusAction,
  IGuestlistAddressManualEnterAction,
  IGuestlistSearchToggleAction,
  IGuestlistSetFilterAction,
  IGuestlistToggleFiltersAction,
  IOnFirebaseGuestlistAction,
  IOnGuestSearchAction,
  IRemovePlusOneAction,
  ISetGuestLocationAction,
  IToggleAddGuestsPopupAction,
  IToggleCategoryDetailsPopupAction,
  IToggleGuestCollectorPopupAction,
  IToggleImportContactsViewAction,
} from 'lib/guestlist/action-types';
import GuestFilterDefault from 'lib/guestlist/models/guest-filter-default';
import { Action, IGuestlistState, IReducersImmer } from 'lib/types';
import { getReducerActions } from 'lib/utils';
import { AuthActionTypes } from '../auth/action-types';
import { getGroupIdByGuest, getPlusOnesByGroupId } from './utils';

const guestDefault = Guests.new('_');
const guestAddressDefault = Guests.new('address');

const initialState: IGuestlistState = {
  list: [],
  loaded: false,
  guest: guestDefault,
  edit: false,
  groupGuestsToUnlink: [],
  groupGuestsToLink: [],
  showSearch: false,
  guestSearch: '',
  guestFilter: GuestFilterDefault,
  showGuestlistFilters: false,
  addressManual: false,
  categoryDetailsPopup: {
    isVisible: false,
    category: null,
  },
  addGuestsPopupVisible: false,
  showImportGuestsModal: false,
  showGuestCollectorPopup: false,
};

const reducers: IReducersImmer<IGuestlistState> = (draft) => ({
  [GuestlistActionTypes.ON_FIREBASE_GUESTLIST]: (action: IOnFirebaseGuestlistAction) => {
    draft.list = action.payload;
    draft.loaded = true;
  },

  [GuestlistActionTypes.EDIT_GUEST]: (action: IEditGuestAction) => {
    const { name, value } = action.payload;
    // @ts-expect-error type is added, not sure if we can do any better in here
    draft.guest[name] = value;
  },

  [GuestlistActionTypes.EDIT_GUEST_CONTACTS]: (action: IEditGuestContactsAction) => {
    const { name, value } = action.payload;
    draft.guest.contacts = {
      ...draft.guest.contacts,
      [name]: value,
    };
  },

  [GuestlistActionTypes.EDIT_GUEST_ADDRESS]: (action: IEditGuestAddressAction) => {
    const { name, value } = action.payload;
    draft.guest.address = {
      ...guestAddressDefault,
      ...draft.guest.address,
      [name]: value,
    };
  },

  [GuestlistActionTypes.EDIT_GUEST_FORM]: (action: IEditGuestFormAction) => {
    const { guest } = action.payload;
    const groupId = guest.head || guest.id;
    const guests = draft.list;
    const plusOneIds = guests
      // Do not include currently edited guest
      .filter((g) => g.id !== guest.id)
      // Get the whole group (both Head and Plus Ones)
      .filter((g) => (g.head ? g.head === groupId : g.id === groupId))
      // Return only guest ids
      .map((item) => item.id);
    const isManual = !!guest.address && any(Boolean, values(guest.address));

    draft.guest = guest;
    draft.groupGuestsToUnlink = [];
    draft.groupGuestsToLink = plusOneIds;
    draft.edit = true;
    draft.addressManual = isManual;
  },

  [GuestlistActionTypes.EDIT_GUEST_RSVP_STATUS]: (action: IEditRsvpStatusAction) => {
    if (!draft.guest.events.wedding)
      draft.guest.events.wedding = { ...guestDefault.events.wedding };
    draft.guest.events.wedding.attending = action.payload;
  },

  [GuestlistActionTypes.EDIT_GUEST_INVITATION_TYPE]: (action: IEditInvitationTypeAction) => {
    if (!draft.guest.events.wedding)
      draft.guest.events.wedding = { ...guestDefault.events.wedding };
    draft.guest.events.wedding.invitation = action.payload;
  },

  [GuestlistActionTypes.CANCEL_EDIT_GUEST]: () => ({
    ...draft,
    guest: guestDefault,
    groupGuestsToUnlink: [],
    groupGuestsToLink: [],
    addressManual: false,
    edit: false,
  }),

  [GuestlistActionTypes.DELETE_GUEST_SUCCESS]: () => ({
    ...draft,
    guest: guestDefault,
    groupGuestsToUnlink: [],
    groupGuestsToLink: [],
    edit: false,
  }),

  [GuestlistActionTypes.SAVE_GUEST_SUCCESS]: () => ({
    ...draft,
    edit: false,
    addressManual: false,
    groupGuestsToLink: [],
    groupGuestsToUnlink: [],
  }),

  [GuestlistActionTypes.SET_GUEST_LOCATION]: (action: ISetGuestLocationAction) => {
    const { street_number, route, postal_town, locality, country, postal_code } = action.payload;

    const street = `${route ? `${route} ` : ''}${street_number || ''}`;

    draft.guest.address = {
      city: locality || postal_town || '',
      // @ts-expect-error TODO: This should be always CountryCodes
      country: country || '',
      postalCode: postal_code || '',
      street: [street],
    };
  },

  [GuestlistActionTypes.ADD_PLUS_ONE]: (action: IAddPlusOneAction) => {
    const guest = action.payload;
    const { groupGuestsToUnlink, groupGuestsToLink, list } = draft;

    // IDs of all the guests to be added as +1s
    // If the guest is a Head of another Group, attach also its +1s.
    const totalToLink = [guest.id, ...getPlusOnesByGroupId(guest.id, list).map((g) => g.id)];

    draft.groupGuestsToLink = [...groupGuestsToLink, ...totalToLink];
    // If this guest has already been added to the groupGuestsToUnlink
    // to be removed from this group, then delete it from groupGuestsToUnlink
    draft.groupGuestsToUnlink = groupGuestsToUnlink.filter((id) => id !== guest.id);
  },

  [GuestlistActionTypes.REMOVE_PLUS_ONE]: (action: IRemovePlusOneAction) => {
    const plusOneId = action.payload;
    const { groupGuestsToLink, groupGuestsToUnlink, guest } = draft;
    const isGroupHead = plusOneId === getGroupIdByGuest(guest);

    // If this guest has already been added to the groupGuestsToLink
    // to be added to this group, then delete it from groupGuestsToLink
    draft.groupGuestsToLink = groupGuestsToLink.filter((id) => id !== plusOneId);
    draft.groupGuestsToUnlink = groupGuestsToUnlink.concat([plusOneId]);
    draft.guest.head = isGroupHead ? null : guest.head || null;
  },

  [GuestlistActionTypes.ON_GUEST_SEARCH]: (action: IOnGuestSearchAction) => {
    const searchFor = action.payload === '' ? null : action.payload.toLowerCase();

    draft.guestSearch = action.payload;
    draft.guestFilter.search = searchFor;
  },

  [GuestlistActionTypes.GUESTLIST_SET_FILTER]: (action: IGuestlistSetFilterAction) => {
    const { filters } = action.payload;

    draft.guestFilter = {
      ...draft.guestFilter,
      ...filters,
    };
  },

  [GuestlistActionTypes.GUESTLIST_FILTER_RESET]: () => {
    draft.guestFilter = {};
  },

  [GuestlistActionTypes.RESET_GUESTLIST]: () => {
    draft.guestFilter = {};
    draft.showGuestlistFilters = false;
    draft.guestSearch = '';
    draft.showSearch = false;
  },

  [GuestlistActionTypes.GUESTLIST_TOGGLE_FILTERS]: (action: IGuestlistToggleFiltersAction) => {
    draft.showGuestlistFilters = action.payload;
  },

  [GuestlistActionTypes.GUESTLIST_ADDRESS_MANUAL_ENTER]: (
    action: IGuestlistAddressManualEnterAction,
  ) => {
    draft.addressManual = action.payload;
  },

  [GuestlistActionTypes.MAKE_GROUP_HEAD]: () => {
    draft.guest.head = null;
  },

  [GuestlistActionTypes.GUEST_SEARCH_TOGGLE]: (action: IGuestlistSearchToggleAction) => {
    draft.showSearch = action.payload;
  },

  [GuestlistActionTypes.TOGGLE_CATEGORY_DETAILS_POPUP]: (
    action: IToggleCategoryDetailsPopupAction,
  ) => {
    draft.categoryDetailsPopup = action.payload;
  },

  [GuestlistActionTypes.TOGGLE_ADD_GUESTS_POPUP]: (action: IToggleAddGuestsPopupAction) => {
    draft.addGuestsPopupVisible = action.payload;
  },

  [GuestlistActionTypes.TOGGLE_IMPORT_CONTACTS_VIEW]: (action: IToggleImportContactsViewAction) => {
    draft.showImportGuestsModal = action.payload;
  },

  [GuestlistActionTypes.TOGGLE_GUEST_COLLECTOR_POPUP]: (
    action: IToggleGuestCollectorPopupAction,
  ) => {
    draft.showGuestCollectorPopup = action.payload;
  },

  [AuthActionTypes.SIGN_OUT_COMPLETED]: () => initialState,
});

const reducersActions = getReducerActions(reducers);

/*
  This is a wrapper function which runs a proper reducer from the object above.
*/
const reducer = (state: IGuestlistState = initialState, action: Action): IGuestlistState => {
  if (!reducersActions[action.type]) {
    return state;
  }

  try {
    return produce(state, (draft) => reducers(draft)[action.type](action));
  } catch (err) {
    return state;
  }
};

export default reducer;
