import { AnyAction, PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  ILazyLoadingModuleStatus,
  IModuleKey,
  IModulesState,
  ModuleBeforeUnloadAction,
  ModuleLoadedAction,
  ModuleUnloadedAction,
} from './types';

const initialState: IModulesState = {
  byId: {},
};

function isModuleLoadedAction(action: AnyAction): action is ModuleLoadedAction {
  return action.type.startsWith('modules/moduleLoaded');
}

function isModuleBeforeUnloadAction(action: AnyAction): action is ModuleBeforeUnloadAction {
  return action.type.startsWith('modules/moduleBeforeUnload');
}

function isModuleUnloadedAction(action: AnyAction): action is ModuleUnloadedAction {
  return action.type.startsWith('modules/moduleUnloaded');
}

export function moduleLoaded(module: IModuleKey): ModuleLoadedAction {
  return {
    type: `modules/moduleLoaded/${module}`,
    payload: {
      module,
    },
  };
}

export function moduleBeforeUnload(module: IModuleKey): ModuleBeforeUnloadAction {
  return {
    type: `modules/moduleBeforeUnload/${module}`,
    payload: {
      module,
    },
  };
}

export function moduleUnloaded(module: IModuleKey): ModuleUnloadedAction {
  return {
    type: `modules/moduleUnloaded/${module}`,
    payload: {
      module,
    },
  };
}

export const modulesSlice = createSlice({
  name: 'modules',
  initialState,
  reducers: {
    moduleLoading: (state, action: PayloadAction<{ module: IModuleKey }>) => {
      const moduleKey = action.payload.module;
      state.byId[moduleKey] = {
        id: moduleKey,
        referenceCount: 0,
        status: ILazyLoadingModuleStatus.LOADING,
      };
    },
    moduleReferenced: (state, action: PayloadAction<{ module: IModuleKey }>) => {
      const module = state.byId[action.payload.module];
      module.referenceCount += 1;
    },
    moduleDereferenced: (state, action: PayloadAction<{ module: IModuleKey }>) => {
      const module = state.byId[action.payload.module];
      module.referenceCount -= 1;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(isModuleLoadedAction, (state, action) => {
      const module = state.byId[action.payload.module];
      module.status = ILazyLoadingModuleStatus.LOADED;
    });
    builder.addMatcher(isModuleUnloadedAction, (state, action) => {
      const module = state.byId[action.payload.module];
      module.status = ILazyLoadingModuleStatus.UNLOADED;
    });
    builder.addMatcher(isModuleBeforeUnloadAction, (state, action) => {
      const module = state.byId[action.payload.module];
      module.status = ILazyLoadingModuleStatus.UNLOADING;
    });
  },
});
