import { Reviews as ReviewsType } from 'app-shared/lib/supplier/types';

const Reviews: ReviewsType = {
  activePage: 0,
  reviews: [],
  loading: false,
  hasReviews: false,
  hasTestimonials: false,
  stars: 0,
};

export default Reviews;
