import produce from 'immer';
import { isEmpty } from 'ramda';
import {
  ChecklistActionTypes,
  IAddChecklistTaskToggleAction,
  IAssignTaskToggleAction,
  IFetchRelatedSuppliersSuccessAction,
  IFetchTasksInitialSuccessAction,
  IFetchUserChecklistSuccessAction,
  IFilterChecklistAction,
  IReInitialiseChecklistAction,
  IShowSubtasksAction,
} from 'lib/checklist/action-types';
import { Action, IChecklistState, IReducersImmer } from 'lib/types';
import { getReducerActions } from 'lib/utils';
import { AuthActionTypes } from '../auth/action-types';
import { getPeriodsAndGroups } from './utils';

const initialState: IChecklistState = {
  assignTaskTo: '',
  detailsTaskId: '',
  filter: 'alltasks',
  index: 0,
  initial: [],
  initialised: false,
  loaded: false,
  reInitialising: false,
  relatedSuppliers: [],
  showAddTask: false,
  showAssignTask: false,
  subtasks: [],
  tags: [],
  tasksInitial: {},
  tasks: {},
  groups: {},
  periods: {
    mytasks: {},
    completed: {},
    alltasks: {},
  },
};

const reducers: IReducersImmer<IChecklistState> = (draft) => ({
  [ChecklistActionTypes.FETCH_USER_CHECKLIST_SUCCESS]: (
    action: IFetchUserChecklistSuccessAction,
  ) => {
    if (isEmpty(action.payload.result)) {
      return {
        ...draft,
        loaded: true,
      };
    }
    const tasks = action.payload.result;
    const completed = getPeriodsAndGroups(tasks, 'completed');
    const alltasks = getPeriodsAndGroups(tasks, 'alltasks');
    const mytasks = getPeriodsAndGroups(tasks, 'mytasks');

    const periods = {
      completed: completed[0],
      alltasks: alltasks[0],
      mytasks: mytasks[0],
    };

    const groups = {
      ...completed[1],
      ...alltasks[1],
    };

    return {
      ...draft,
      tasks,
      periods,
      groups,
      initialised: true,
      loaded: true,
    };
  },

  [ChecklistActionTypes.FETCH_TASKS_INITIAL_SUCCESS]: (action: IFetchTasksInitialSuccessAction) => {
    draft.tasksInitial = action.payload;
  },

  [ChecklistActionTypes.SHOW_SUBTASKS]: (action: IShowSubtasksAction) => ({
    ...draft,
    detailsTaskId: action.payload,
    relatedSuppliers: [],
  }),

  [ChecklistActionTypes.CLOSE_SUBTASKS]: () => ({
    ...draft,
    detailsTaskId: null,
  }),

  [ChecklistActionTypes.FILTER_CHECKLIST]: (action: IFilterChecklistAction) => {
    draft.filter = action.payload;
  },

  [ChecklistActionTypes.FETCH_RELATED_SUPPLIERS_SUCCESS]: (
    action: IFetchRelatedSuppliersSuccessAction,
  ) => ({
    ...draft,
    relatedSuppliers: action.payload,
  }),

  [ChecklistActionTypes.ADD_CHECKLIST_TASK_TOGGLE]: (action: IAddChecklistTaskToggleAction) => ({
    ...draft,
    showAddTask: action.payload,
  }),

  [ChecklistActionTypes.ASSIGN_TASK_TOGGLE]: (action: IAssignTaskToggleAction) => ({
    ...draft,
    showAssignTask: action.payload,
  }),

  [ChecklistActionTypes.REINITIALISE_CHECKLIST]: (action: IReInitialiseChecklistAction) => ({
    ...draft,
    reInitialising: action.payload,
  }),

  [AuthActionTypes.SIGN_OUT_COMPLETED]: () => initialState,
});

const reducersActions = getReducerActions(reducers);

/*
	This is a wrapper function which runs a proper reducer from the object above.
*/
const reducer = (state: IChecklistState = initialState, action: Action): IChecklistState => {
  if (!reducersActions[action.type]) {
    return state;
  }

  try {
    return produce(state, (draft) => reducers(draft)[action.type](action));
  } catch (err) {
    return state;
  }
};

export default reducer;
