export enum ArticlesAnalyticsActionTypes {
  ADD_BOOKMARK_ANALYTICS = '[Articles] [Analytics] Add bookmark',
  REMOVE_BOOKMARK_ANALYTICS = '[Articles] [Analytics] Remove bookmark',
  SET_ACTIVE_TAG_ANALYTICS = '[Articles] [Analytics] Set active tag',
  CLEAR_ARTICLES_SEARCH_ANALYTICS = '[Articles] [Analytics] Clear search',
  TOGGLE_MORE_TOPICS_ANALYTICS = '[Articles] [Analytics] Toggle more topics',
  SEARCH_ARTICLES_ANALYTICS = '[Articles] [Analytics] Search',
  ARTICLE_SIDEBAR_USED_ANALYTICS = '[Articles] [Analytics] Sidebar used',
  LOAD_MORE_ARTICLES_ANALYTICS = '[Articles] [Analytics] Load more',
  ARTICLE_LOADED_ANALYTICS = '[Articles] [Analytics] Article loaded',
  TRIGGER_ARTICLE_SOCIAL_SHARE_ANALYTICS = '[Articles] [Analytics] Triggered social share',
  CLICKED_EXTERNAL_LINK_WITHIN_ARTICLE = '[Articles] [Analytics] Clicked external link within article',
}
