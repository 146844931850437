import { Action, IDeps } from 'lib/types';
import { WebAnalyticsContext } from '../bbcommon/utils/bridebook-analytics';
import { trackClickedRealWedding, trackViewedRealWedding } from './slice';
import { RealWedding, ViewedLocation } from './types';

const category = 'RealWeddings';

const viewedLocationMap: Record<ViewedLocation, string> = {
  home: 'realWeddingsCarousel_hometab',
  supplier: 'realWeddingsCarousel_supplier',
};

const getCommonProps = (
  realWedding: RealWedding,
  getState: IDeps['getState'],
  viewedLocationInit: ViewedLocation,
) => {
  const weddingId = getState().weddings.profile.id;
  const realWeddingCreator = realWedding.createdBy;
  const realWeddingId = realWedding.id;
  const viewedLocation = viewedLocationMap[viewedLocationInit] || viewedLocationInit;

  return {
    realWeddingCreator,
    realWeddingId,
    viewedLocation,
    weddingId,
  };
};

const realWeddingsAnalytics = (
  action: Action,
  bridebookAnalytics: WebAnalyticsContext,
  getState: IDeps['getState'],
): void => {
  switch (true) {
    case trackClickedRealWedding.match(action): {
      const { track } = bridebookAnalytics.getMethods(category);
      const { realWedding, viewedLocation } = action.payload as ReturnType<
        typeof trackClickedRealWedding
      >['payload'];
      const { url: contactLink, createdBy: viewedSupplierId } = realWedding;
      const supplierCategory =
        realWedding.suppliers.find((s) => s.supplierId === realWedding.createdBy)
          ?.supplierCategory || '';

      track({
        ...getCommonProps(realWedding, getState, viewedLocation),
        event: 'Clicked real weddings tile',
        category,
        clickedContactLinkType: 'website',
        contactLink,
        supplierCategory,
        viewedSupplierId,
      });

      break;
    }

    case trackViewedRealWedding.match(action): {
      const { track } = bridebookAnalytics.getMethods(category);
      const { realWedding, viewedLocation } = action.payload as ReturnType<
        typeof trackViewedRealWedding
      >['payload'];
      const creatorSupplier = realWedding.suppliers.find(
        (s) => s.supplierId === realWedding.createdBy,
      );
      const venueSupplier = realWedding.suppliers.find((s) => s.supplierCategory === 'venue');

      /** Every RW needs a venue connection atm */
      if (!creatorSupplier || !venueSupplier) return;

      const realWeddingSuppliers = {
        mainSupplier: { [creatorSupplier.supplierCategory]: creatorSupplier.supplierId },
        /**
         * In case we should support other supplier types in the future,
         * automatically add them via a "[category]: supplierId" property.
         * If we don't have any connected suppliers (e.g. as in DE atm),
         * we don't add any connected suppliers at all.
         **/
        ...realWedding.suppliers
          .filter((s) => s.supplierId != creatorSupplier.supplierId)
          .reduce((obj, s) => ({ ...obj, [s.supplierCategory]: s.supplierId }), {}),
      };

      track({
        ...getCommonProps(realWedding, getState, viewedLocation),
        event: 'Viewed real weddings tile',
        realWeddingSuppliers,
      });

      break;
    }

    default:
      break;
  }
};

export default realWeddingsAnalytics;
