import { FelaCSS, colors } from '@bridebook/ui';

interface IStyles {
  icon: FelaCSS;
  backIconWrapper: FelaCSS;
  closeIconWrapper: FelaCSS;
  container: FelaCSS;
  wrapper: FelaCSS;
  header: (hideDivider: boolean) => FelaCSS;
}

const iconWrapper: FelaCSS = {
  position: 'absolute',
  bottom: 27.5,
  transform: 'translate(0, 50%)',
};

const styles: IStyles = {
  icon: {
    alignItems: 'center',
    background: colors.space04,
    borderRadius: '32px',
    height: '32px',
    justifyContent: 'center',
    width: '32px',
    cursor: 'pointer',
  },
  backIconWrapper: {
    ...iconWrapper,
    left: '16px',
  },
  closeIconWrapper: {
    ...iconWrapper,
    right: '16px',
  },
  container: {
    width: '100%',
    alignItems: 'center',
    maxWidthXsMax: {
      height: '100%',
    },
  },
  wrapper: {
    alignItems: 'center',
    background: colors.white,
    borderRadius: 16,
    minHeight: 'auto',

    maxWidthXsMax: {
      borderRadius: 0,
      minHeight: '100vh',
    },
    supportsSafeAreaInset: {
      paddingTop: 'env(safe-area-inset-top)',
      paddingBottom: 'env(safe-area-inset-bottom)',
    },
  },
  header: (hideDivider) => ({
    position: 'relative',
    width: '100%',
    minHeight: '55px',
    maxWidthSmMax: {
      borderBottom: hideDivider ? 'unset' : `1px solid ${colors.space15}`,
    },
  }),
};

export default styles;
