import { IBudgetInitForm } from 'lib/budget/types';

const Form: IBudgetInitForm = {
  disabled: false,
  error: null,
  fields: {
    budget: 0,
    guestsEstimate: 'undecided',
    timeOfWeek: 'undecided',
    timeOfYear: 'undecided',
    year: 'undecided',
    optionalCategoriesList: [],
    optionalCategories: {
      video: false,
      liveband: false,
      entertainment: false,
      planners: false,
      Insurance: false,
      stationery: false,
    },
  },
};

export default Form;
