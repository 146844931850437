import { IDropdownOption } from '@bridebook/ui';
import { getI18n } from 'lib/i18n/getI18n';

export const getFeedbackType = (translate = true): IDropdownOption[] => [
  {
    label: translate ? getI18n().t('settings:feedback.reportBug') : 'Report a bug',
    value: 'bug-report',
  },
  {
    label: translate ? getI18n().t('settings:feedback.giveFeedback') : 'Give product feedback',
    value: 'product-feedback',
  },
  {
    label: translate ? getI18n().t('settings:feedback.featureRequest') : 'New feature request',
    value: 'feature-request',
  },
  {
    label: translate ? getI18n().t('settings:feedback.other') : 'Other',
    value: 'other',
  },
];
