import { mapUrlSlugToSupplierType } from '@bridebook/toolbox';
import { IUISupplier } from '@bridebook/toolbox/src/types';
import { getPreviousPath } from 'lib/app/selectors';
import { getCollaboratorInvite } from 'lib/auth/selectors';
import { WebAnalyticsContext } from 'lib/bbcommon/utils/bridebook-analytics';
import { NewOnboardingAnalyticsAction } from 'lib/onboarding-new/analytics-actions';
import { getIdentityPropsKey } from 'lib/search/utils/get-identity-props-key';
import mapSupplierListToAnalytics from 'lib/search/utils/map-suppliers/map-supplier-list-to-analytics';
import { Action, IApplicationState } from 'lib/types';

const filterLabelProps = (props: Record<string, any>) => {
  const newProps = { ...props };
  delete newProps.category;
  delete newProps.event;
  delete newProps.firstName;
  delete newProps.partnerName;
  delete newProps.value;
  return newProps;
};

export default function newOnboardingAnalytics(
  { type, payload }: Action,
  bridebookAnalytics: WebAnalyticsContext,
  getState: () => IApplicationState,
) {
  const defaultCategory = 'User onboarding';

  const getGenericProps = () => {
    const state = getState();
    const collaborator = !!getCollaboratorInvite(state).nonceId?.length;
    const previousPath = getPreviousPath(state);
    const profileType = 'user';

    return {
      collaborator,
      previousPath,
      profileType,
    };
  };

  const track = (props: Record<string, any> = {}, category: string = defaultCategory) => {
    const { track } = bridebookAnalytics.getMethods(category, filterLabelProps);
    track({
      event: type,
      ...getGenericProps(),
      ...props,
    });
  };

  const identifyWithTrack = (
    identifyProps: Record<string, any>,
    trackProps: Record<string, any>,
    userId?: string,
    category: string = defaultCategory,
  ) => {
    const { identifyWithTrack } = bridebookAnalytics.getMethods(category, filterLabelProps);

    identifyWithTrack(
      identifyProps,
      {
        event: type,
        ...getGenericProps(),
        ...trackProps,
      },
      { userId },
    );
  };

  switch (type) {
    case NewOnboardingAnalyticsAction.StartedUserOnboarding:
    case NewOnboardingAnalyticsAction.FinishedUserOnboarding:
    case NewOnboardingAnalyticsAction.TriggeredWeddingInsurancePopup: {
      track();
      break;
    }

    case NewOnboardingAnalyticsAction.SkippedUserOnboardingStep:
    case NewOnboardingAnalyticsAction.ClickedBackOnUserOnboardingStep:
    case NewOnboardingAnalyticsAction.CompletedUserOnboardingStep: {
      track({
        onboardingStepNumber: payload.currentStep.index,
        onboardingStepContent: payload.currentStep.id,
        chapterName: payload.currentChapter.getId(),
      });
      break;
    }

    case NewOnboardingAnalyticsAction.LoadedSearchResults: {
      const { list, userId, searchActiveFilters, ...rest } = payload;
      const supplierType = mapUrlSlugToSupplierType(rest.searchSupplierCategory);

      const trackProps = {
        searchSource: 'onboarding',
        searchCoords: {},
        searchToggledFiltersStatus: false,
        searchPageNumber: 1,
        loadedSearchResultsList: list.map(({ id }: IUISupplier) => id),
        loadedSearchResultsCoreScore: list.map(({ _score = 0 }: { _score: number }) => _score),
        searchResultsLoaded: list.length,
        searchActiveFilters,
        ...rest,
      };

      if (['photographers', 'venues'].includes(payload.searchSupplierCategory)) {
        const identifyProps = {
          ...trackProps,
          [getIdentityPropsKey(supplierType)]: mapSupplierListToAnalytics(list, supplierType),
        };

        identifyWithTrack(identifyProps, trackProps, userId);
      } else {
        track(trackProps);
      }
      break;
    }

    case NewOnboardingAnalyticsAction.ClosedConciergeMatchesScreen: {
      track();
      break;
    }

    case NewOnboardingAnalyticsAction.UserChoseQuizFilter: {
      track(payload);
      break;
    }

    case NewOnboardingAnalyticsAction.SelectedWeddingKeyword: {
      track(payload);
      break;
    }

    case NewOnboardingAnalyticsAction.ConfirmedWeddingKeyword: {
      track(payload);
      break;
    }

    case NewOnboardingAnalyticsAction.UserSelectedNextSupplierCategory: {
      track({
        nextSupplierCategory: payload.nextSupplierCategory,
        nextSupplierSelectedLocation: payload.nextSupplierSelectedLocation,
      });
      break;
    }

    default:
      break;
  }
}
