import { ReviewForm as ReviewFormType } from './types';

const ReviewForm: ReviewFormType = {
  error: null,
  showError: false,
  uploadPhotoSuccess: false,
  recaptchaComplete: false,
  fields: {
    weddingDate: '',
    from: 'happyCouple',
    message: '',
    name: '',
    title: '',
    email: '',
    stars: 0,
    photo: {},
  },
};

export default ReviewForm;
