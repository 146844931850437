import produce from 'immer';
import { Action } from 'redux';
import { AccessControlActionTypes } from 'lib/access-control/action-types';
import {
  AuthActionTypes,
  IAddCollaborativeInviteAction,
  IChangeAuthEmailErrorAction,
  IChangeAuthEmailFormAction,
  IChangePasswordErrorAction,
  IChangePasswordSuccessAction,
  IEmailExistsWithDifferentProviderAction,
  IEnableResetPasswordAction,
  IInitChangePassAction,
  IOnAuthFOrmFieldChangeAction,
  IResetPasswordErrorAction,
  ISignInErrorAction,
  ISignInOrSignUpErrorAction,
  ISignUpErrorAction,
  IToggleAuthFormStateToSignupAction,
  IToggleSelectedCountryError,
} from 'lib/auth/action-types';
import { IAuthState } from 'lib/auth/types';
import { IReducersImmer } from 'lib/types';
import { IOnUserListenerAction, UserActionTypes } from 'lib/users/action-types';
import { getReducerActions } from 'lib/utils';
import ChangeAuthEmailForm from './changeAuthEmailForm';
import CollaboratorInvite from './collaborator-invite';
import Form from './form';

// const LastSocialProvider: ILastSocialProvideState = {
//   accessToken: undefined,
//   currentProvider: null,
// };

const initialState: IAuthState = {
  form: Form,
  passChange: null,
  signup: true,
  // When auth is triggered by user manually clicking Sign in or Sign up buttons,
  // instead of Firebase observer just kicking in
  userTriggerAuth: false,
  resetPassword: false,
  protectedRoute: false,
  changeAuthEmailForm: ChangeAuthEmailForm,
  userId: '',
  emailExists: false,
  // todo @yuri, fix last social provider using local storage
  // lastSocialProvider: LastSocialProvider,
  emailExistsWithDifferentProvider: null,
  emailAndPasswordFormShown: false,
  collaboratorInvite: CollaboratorInvite,
  updatedAccessControlThisSession: false,
  selectedCountryError: false,
  logOutAllDevices: false,
  deleteAccountInSession: false,
  loggedIn: false,
  authContextAndConditionalRedirectsReady: false,
};

const reducers: IReducersImmer<IAuthState> = (draft) => ({
  [AuthActionTypes.ON_AUTH_FORM_FIELD_CHANGE]: (action: IOnAuthFOrmFieldChangeAction) => {
    const { value, name } = action.payload;
    if (name === 'password' || name === 'email' || name === 'countryCode') {
      // @ts-expect-error TODO: The value should be CountryCodes
      draft.form.fields[name] = value;
      draft.form.error = null;
    }
  },

  [AuthActionTypes.SIGN_IN_ERROR]: (action: ISignInErrorAction) => {
    draft.form.disabled = false;
    draft.form.error = action.payload.error;
    draft.emailExists = false;
    draft.userTriggerAuth = false;
  },

  [AuthActionTypes.SIGN_UP_ERROR]: (action: ISignUpErrorAction) => {
    draft.form.disabled = false;
    draft.form.error = action.payload.error;
    draft.userTriggerAuth = false;
  },

  [AuthActionTypes.SIGN_IN_OR_SIGN_UP_ERROR]: (action: ISignInOrSignUpErrorAction) => {
    draft.form.disabled = false;
    draft.form.error = action.payload.error;
    draft.userTriggerAuth = false;
  },

  [AuthActionTypes.SIGN_UP_SUCCESS]: () => {
    draft.form.error = null;
    draft.emailExists = false;
    draft.passChange = null;
  },

  [AuthActionTypes.SIGN_IN_WITH_PASSWORD_SUCCESS]: () => {
    draft.form.error = null;
    draft.emailExists = false;
    draft.passChange = null;
    draft.emailExistsWithDifferentProvider = null;
  },

  [AuthActionTypes.SIGN_IN_WITH_SOCIAL_SUCCESS]: () => {
    draft.form.error = null;
    draft.emailExists = false;
    draft.passChange = null;
    draft.emailExistsWithDifferentProvider = null;
  },

  [AuthActionTypes.INIT_CHANGE_PASSWORD]: (action: IInitChangePassAction) => {
    draft.passChange = action.payload.passChange;
  },

  [AuthActionTypes.SIGN_IN_WITH_PASSWORD]: () => {
    draft.form.disabled = true;
    draft.userTriggerAuth = true;
  },
  [AuthActionTypes.DISABLE_AUTH_FORM]: () => {
    draft.form.disabled = true;
  },

  [AuthActionTypes.SIGN_IN_WITH_SOCIAL_PROVIDER]: () => {
    draft.form.disabled = true;
    draft.userTriggerAuth = true;
  },

  [AuthActionTypes.SIGN_UP]: () => {
    draft.form.disabled = true;
    draft.userTriggerAuth = true;
  },

  [AuthActionTypes.TOGGLE_AUTH_FORM_STATE_TO_SIGNUP]: (
    action: IToggleAuthFormStateToSignupAction,
  ) => {
    draft.form.error = null;
    draft.emailExists = false;
    draft.resetPassword = false;
    draft.signup = action.payload;
    draft.emailExistsWithDifferentProvider = null;
    draft.emailAndPasswordFormShown = !action.payload;
  },

  [AuthActionTypes.CHANGE_PASSWORD_SUCCESS]: (action: IChangePasswordSuccessAction) => {
    const { email, password } = action.payload;
    draft.form.fields.password = password;
    draft.form.fields.email = email;
  },

  [AuthActionTypes.CHANGE_PASSWORD_ERROR]: (action: IChangePasswordErrorAction) => {
    draft.form.disabled = false;
    draft.form.error = action.payload;
  },

  [AuthActionTypes.RESET_PASSWORD]: () => {
    draft.form.resetDisabled = true;
  },

  [AuthActionTypes.RESET_PASSWORD_ERROR]: (action: IResetPasswordErrorAction) => {
    draft.form.disabled = false;
    draft.form.error = action.payload;
    draft.form.resetDisabled = false;
  },

  [AuthActionTypes.RESET_PASSWORD_SUCCESS]: () => {
    draft.form.disabled = false;
    draft.resetPassword = false;
    draft.form.resetDisabled = false;
  },

  [AuthActionTypes.SET_AUTH_EMAIL_FORM_FIELD]: (action: IChangeAuthEmailFormAction) => {
    const fieldName = action.payload.name;
    if (fieldName === 'password' || fieldName === 'email') {
      draft.changeAuthEmailForm.fields[fieldName] = action.payload.value;
    }
  },

  [AuthActionTypes.CHANGE_AUTH_EMAIL]: () => {
    draft.changeAuthEmailForm.disabled = true;
  },

  [AuthActionTypes.CHANGE_AUTH_EMAIL_ERROR]: (action: IChangeAuthEmailErrorAction) => {
    draft.changeAuthEmailForm.disabled = false;
    draft.changeAuthEmailForm.error = action.payload;
  },

  [AuthActionTypes.CHANGE_AUTH_EMAIL_SUCCESS]: () => {
    draft.changeAuthEmailForm = ChangeAuthEmailForm;
  },

  [AuthActionTypes.TOGGLE_PASSWORD_RESET]: (action: IEnableResetPasswordAction) => {
    draft.form.error = null;
    draft.resetPassword = action.payload.show;
    draft.signup = false;
  },

  [AuthActionTypes.SIGNUP_EMAIL_EXISTS]: () => {
    draft.signup = false;
    draft.emailAndPasswordFormShown = true;
    draft.emailExists = true;
  },

  [AuthActionTypes.SIGNUP_EMAIL_EXISTS_WITH_DIFFERENT_PROVIDER]: (
    action: IEmailExistsWithDifferentProviderAction,
  ) => {
    draft.signup = false;
    draft.emailAndPasswordFormShown = true;
    draft.emailExistsWithDifferentProvider = action.payload;
  },

  // todo @yuri, fix last social provider using local storage
  // [AuthActionTypes.SAVE_ACCESS_TOKEN]: (action: ISaveAccessTokenAction) => {
  //   const { accessToken, currentProvider } = action.payload;
  //   draft.lastSocialProvider.accessToken = accessToken;
  //   draft.lastSocialProvider.currentProvider = currentProvider;
  // },

  [AuthActionTypes.TOGGLE_SELECTED_COUNTRY_ERROR]: (action: IToggleSelectedCountryError) => {
    draft.selectedCountryError = action.payload;
  },

  [UserActionTypes.ON_USER_LISTENER]: (action: IOnUserListenerAction) => {
    if (action.payload && action.payload.contacts?.email) {
      draft.form.fields.email = action.payload.contacts.email;
    }
  },

  [AuthActionTypes.ADD_COLLABORATOR_INVITE]: (action: IAddCollaborativeInviteAction) => {
    draft.collaboratorInvite = action.payload;
  },
  [AccessControlActionTypes.UPDATE_ACCESS_CONTROL_SUCCESS]: () => {
    draft.updatedAccessControlThisSession = true;
  },

  // This clears the collaboratorInvite info, which is also cleared during
  // signout
  FINISHED_USER_ONBOARDING: () => {
    draft.collaboratorInvite = CollaboratorInvite;
  },

  [AuthActionTypes.USER_SETUP_COMPLETED]: () => {
    draft.loggedIn = true;
  },

  [AuthActionTypes.SIGN_OUT_COMPLETED]: () => initialState,

  [UserActionTypes.MARK_USER_AS_DELETED_SUCCESS]: () => {
    draft.logOutAllDevices = true;
    draft.deleteAccountInSession = true;
  },
});

const reducersActions = getReducerActions(reducers);

/*
	This is a wrapper function which runs a proper reducer from the object above.
*/
const reducer = (state: IAuthState = initialState, action: Action): IAuthState => {
  if (!reducersActions[action.type]) {
    return state;
  }

  try {
    return produce(state, (draft) => reducers(draft)[action.type](action));
  } catch (err) {
    return state;
  }
};

export default reducer;
