import produce from 'immer';
import { Suppliers } from '@bridebook/models';
import mapToPackagesSections, {
  sortSupplierPackages,
} from '@bridebook/toolbox/src/map-packages-to-sections';
import { mapSlugToFriendlyUrlSlug } from '@bridebook/toolbox/src/mapSlugToFriendlyUrlSlug';
import { serializeTimestamp } from '@bridebook/toolbox/src/serialize-timestamps';
import type { Slug } from '@bridebook/toolbox/src/types';
import Reviews from 'app-shared/lib/supplier/reviews';
import {
  FetchFeedbackSuccessAction,
  FetchPhotosDoneAction,
  FetchPremiumDoneAction,
  FetchSupplierDataDoneAction,
  FetchSupplierSuccessAction,
  IFetchSupplierErrorAction,
  IFetchSupplierStartAction,
  ISaveReviewErrorAction,
  ISetReviewFormFieldAction,
  ISetReviewFormRatingAction,
  ISetSupplierDeletedAction,
  ISupplierGalleryToggleAction,
  ISupplierReviewsToggleAction,
  ISupplierVideoModalToggleAction,
  IToggleReviewFormAction,
  IToggleSupplierAccordionAction,
  IToggleWhyBridebookModalAnalyticsAction,
  IUploadReviewPhotoSuccessAction,
  SupplierActions,
} from 'lib/supplier/actions-types';
import { Action, IReducersImmer } from 'lib/types';
import { getReducerActions } from 'lib/utils';
import ReviewForm from './review-form';
import { ISupplierState } from './types';

const initialState: ISupplierState = {
  accordions: [],
  appsConnected: [],
  badges: [],
  errorCode: null,
  galleryInitIndex: 0,
  hasBadDebt: false,
  initVotes: false,
  lateAvailabilityCounter: 0,
  offers: [],
  packages: {},
  photos: { status: 'notLoaded', photos: [] },
  premium: null,
  reviewForm: ReviewForm,
  reviewFormShow: false,
  reviews: Reviews,
  reviewSuccessShow: false,
  selectedReview: null,
  seoIndex: true,
  seoScore: 0,
  showGallery: false,
  showReviewsPage: false,
  supplier: { data: null, status: 'notLoaded' },
  supplierDataStatus: 'notLoaded',
  supplierPackages: [],
  supplierQueryId: null,
  videoModalOpened: false,
  whyBridebookModalShow: false,
};

const reducers: IReducersImmer<ISupplierState> = (draft) => ({
  [SupplierActions.SET_SUPPLIER_DELETED]: (action: ISetSupplierDeletedAction) => {
    draft.deleted = action.payload.deleted;
  },

  [SupplierActions.FETCH_SUPPLIER_SUCCESS]: (action: FetchSupplierSuccessAction) => {
    draft.supplier = {
      status: 'loaded',
      data: action.payload.supplier,
    };
  },

  [SupplierActions.FETCH_FEEDBACK_DONE]: (action: FetchFeedbackSuccessAction) => {
    draft.reviews.feedback = action.payload.feedback;
    draft.reviews.activePage = action.payload.activePage;
    draft.reviews.hasReviews = action.payload.hasReviews;
    draft.reviews.hasTestimonials = action.payload.hasTestimonials;
    draft.reviews.stars = action.payload.stars;
    draft.reviews.loading = false;
  },

  [SupplierActions.FETCH_FEEDBACK_ERROR]: () => {
    draft.reviews.loading = false;
  },

  [SupplierActions.FETCH_FEEDBACK_STOP]: () => {
    draft.reviews.loading = false;
  },

  [SupplierActions.FETCH_PREMIUM_DONE]: (action: FetchPremiumDoneAction) => {
    draft.premium = action.payload.premium || null;
  },
  [SupplierActions.FETCH_PHOTOS]: () => {
    draft.photos.status = 'notLoaded';
  },
  [SupplierActions.FETCH_PHOTOS_DONE]: (action: FetchPhotosDoneAction) => {
    draft.photos = {
      photos: action.payload?.photos || [],
      status: 'loaded',
    };
  },

  [SupplierActions.FETCH_SUPPLIER_DATA_DONE]: (action: FetchSupplierDataDoneAction) => {
    draft.achievements = action.payload.achievements;
    draft.appsConnected = action.payload.appsConnected || [];
    // @ts-ignore FIXME
    draft.badges = action.payload.badges;
    draft.brochures = action.payload.brochures;
    draft.fairs = action.payload.fairs.map((fair) => ({
      ...fair,
      dates: fair.dates.map((date) => serializeTimestamp(date) as number),
    }));
    draft.hasBadDebt = !!action.payload.hasBadDebtCoupleside;
    draft.offers = action.payload.offers;
    draft.packages = mapToPackagesSections(action.payload.packages);
    draft.premium = action.payload.premium || null;
    draft.questions = action.payload.questions;
    draft.recommendations = action.payload.recommendations;
    draft.relations = action.payload.relations;
    draft.seoIndex = action.payload.seoIndex;
    draft.supplierDataStatus = 'loaded';
    draft.supplierPackages = sortSupplierPackages(action.payload.packages);
    draft.trustedPartnerSince = action.payload.trustedPartnerSince;
    draft.videos = action.payload.videos;
  },

  [SupplierActions.FETCH_SUPPLIER_START]: (action: IFetchSupplierStartAction) => {
    const { query } = action.payload || {};
    const { slug, supplierQueryId } = query || {};
    const publicId = supplierQueryId?.split('-').slice(-1)[0];

    // Reset state to the initial one when switching between suppliers
    return {
      ...initialState,
      supplierQueryId,
      supplier: {
        status: 'notLoaded',
        data: Suppliers.new('_', {
          type: [mapSlugToFriendlyUrlSlug(slug as Slug).oldSlug] as Slug[],
          name: '',
          description: { short: '', long: '' },
          publicId,
        }),
      },
    };
  },

  [SupplierActions.CLEAR_SUPPLIER_DETAILS]: () => {
    Object.assign(draft, initialState);
  },

  [SupplierActions.REVIEW_FORM_RECAPTCHA_VERIFIED]: () => {
    draft.reviewForm.recaptchaComplete = true;
  },

  [SupplierActions.FETCH_SUPPLIER_ERROR]: (action: IFetchSupplierErrorAction) => {
    draft.errorCode = action.payload.errorCode;
  },

  [SupplierActions.SHOW_REVIEW_SUCCESS]: () => {
    draft.reviewSuccessShow = true;
  },

  [SupplierActions.HIDE_REVIEW_SUCCESS]: () => {
    draft.reviewSuccessShow = false;
  },

  [SupplierActions.SAVE_REVIEW]: () => {
    draft.reviewForm.error = null;
    draft.reviewForm.showError = false;
  },

  [SupplierActions.HIDE_REVIEW_FORM_ERROR]: () => {
    draft.reviewForm.showError = false;
  },

  [SupplierActions.SAVE_REVIEW_ERROR]: (action: ISaveReviewErrorAction) => {
    draft.reviewForm.error = action.payload;
    draft.reviewForm.showError = true;
  },

  [SupplierActions.TOGGLE_REVIEW_FORM]: (action: IToggleReviewFormAction) => {
    draft.reviewForm.fields.weddingDate = action.payload.weddingDate || '';
    draft.reviewFormShow = !!action.payload.show;
  },

  [SupplierActions.SET_REVIEW_FORM_FIELD]: (action: ISetReviewFormFieldAction) => {
    // @ts-expect-error type is added, not sure if we can do any better in here
    draft.reviewForm.fields[action.payload.name] = action.payload.value;
    draft.reviewForm.error = null;
  },

  [SupplierActions.SET_REVIEW_FORM_RATING]: (action: ISetReviewFormRatingAction) => {
    draft.reviewForm.fields.stars = action.payload;
    draft.reviewForm.error = null;
  },

  [SupplierActions.RESET_REVIEW_FORM]: () => {
    draft.reviewForm = ReviewForm;
  },

  [SupplierActions.UPLOAD_REVIEW_PHOTO]: () => {
    draft.reviewForm.uploadPhotoSuccess = false;
  },

  [SupplierActions.UPLOAD_REVIEW_PHOTO_ERROR]: () => {
    draft.reviewForm.uploadPhotoSuccess = false;
  },

  [SupplierActions.UPLOAD_REVIEW_PHOTO_SUCCESS]: (action: IUploadReviewPhotoSuccessAction) => {
    const { public_id, portrait, faces, source, ext, imgixURL } = action.payload;
    const photo = { public_id, portrait, faces, source, ext, imgixURL };

    draft.reviewForm.fields.photo = photo;
    draft.reviewForm.uploadPhotoSuccess = true;
  },

  [SupplierActions.SUPPLIER_GALLERY_TOGGLE]: (action: ISupplierGalleryToggleAction) => {
    draft.showGallery = action.payload.show;
    draft.galleryInitIndex = action.payload.index || 0;
  },

  [SupplierActions.SUPPLIER_REVIEWS_TOGGLE]: (action: ISupplierReviewsToggleAction) => {
    draft.showReviewsPage = action.payload.show;
    draft.selectedReview = action.payload.selectedReview;
  },

  [SupplierActions.TOGGLE_SUPPLIER_ACCORDION]: (action: IToggleSupplierAccordionAction) => {
    draft.accordions = action.payload;
  },

  [SupplierActions.RESET_SUPPLIER_ACCORDION]: () => {
    draft.accordions = [];
  },

  [SupplierActions.SUPPLIER_VIDEO_MODAL_TOGGLE]: (action: ISupplierVideoModalToggleAction) => {
    draft.videoModalOpened = action.payload;
  },

  [SupplierActions.TOGGLE_WHY_BRIDEBOOK_MODAL]: (
    action: IToggleWhyBridebookModalAnalyticsAction,
  ) => {
    draft.whyBridebookModalShow = action.payload;
  },

  [SupplierActions.FETCH_FEEDBACK]: () => {
    draft.reviews.loading = true;
  },
});

const reducersActions = getReducerActions(reducers);

const reducer = (state: ISupplierState = initialState, action: Action): ISupplierState => {
  if (!reducersActions[action.type]) {
    return state;
  }

  try {
    return produce(state, (draft: ISupplierState) => reducers(draft)[action.type](action));
  } catch (err) {
    return state;
  }
};

export default reducer;
