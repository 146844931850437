import { isUIDate, mapDateToUI } from '@bridebook/toolbox/src/datepicker/map-date-to-from-ui';
import type { IDatePicker, IDatePickerUI } from '@bridebook/toolbox/src/datepicker/types';
import { DatePickerOptions } from '../types';

/**
 * Function `validateYear`
 * returns whether year screen part is filled out
 *
 * @function validateYear
 * @param {Object, Object} - IDatePickerUI, DatePickerOptions
 * @returns {Boolean}
 */
const validateYear = (datepickerDate: IDatePickerUI, options: DatePickerOptions): boolean => {
  const { yearUndecided, year } = datepickerDate;
  const { showUndecided } = options;
  const yearNumber = parseInt(year, 10);
  if ((showUndecided && !yearUndecided) || !showUndecided) {
    return Number.isInteger(yearNumber);
  }
  return showUndecided ? yearUndecided : false;
};

/**
 * Function `validateMonth`
 * returns whether month screen part is filled out
 *
 * @function validateMonth
 * @param {Object, Object} - IDatePickerUI, DatePickerOptions
 * @returns {Boolean}
 */
const validateMonth = (datepickerDate: IDatePickerUI, options: DatePickerOptions): boolean => {
  const { monthUndecided, season, month } = datepickerDate;
  const { showSeason, showUndecided } = options;
  const monthNumber = parseInt(month, 10);

  const isMonthValid: boolean =
    Number.isInteger(monthNumber) && monthNumber >= 0 && monthNumber < 12;

  const isValidSeason: boolean = showSeason ? isMonthValid || !!season : isMonthValid;

  const undecidedCondition = (showUndecided && !monthUndecided) || !showUndecided;

  return undecidedCondition || !showSeason ? isValidSeason : monthUndecided;
};

/**
 * Function `validateDay`
 * returns whether day screen part is filled out
 *
 * @function validateDay
 * @param {Object, Object} - IDatePickerUI, DatePickerOptions
 * @returns {Boolean}
 */
const validateDay = (datepickerDate: IDatePickerUI, options: DatePickerOptions): boolean => {
  const { dayUndecided, weekDay, day } = datepickerDate;
  const { showUndecided, showDays } = options;
  const dayNumber = parseInt(day, 10);

  const isDay: boolean = Number.isInteger(dayNumber) && dayNumber >= 0 && dayNumber < 32;

  const dayIsValid: boolean = showDays ? isDay || !!weekDay : isDay;

  const undecidedCondition = (showUndecided && !dayUndecided) || !showUndecided;

  return undecidedCondition ? dayIsValid : dayUndecided;
};

/**
 * Function `validateDatepickerTabs`
 * returns whether given datepicker tab needs to be disabled
 *
 * @function validateDatepickerTabs
 * @param {Object, Object} - IDatePickerUI, DatePickerOptions
 * @returns {Object}
 */
const validateDatepickerTabs = (datepickerDate: IDatePickerUI, options: DatePickerOptions) => ({
  'year-screen': false,
  'month-screen': !validateYear(datepickerDate, options),
  'day-screen': !validateMonth(datepickerDate, options),
});

/**
 * Function `isValidDatePickerDate`
 * Check if user selected something on all 3 screens of datepicker
 *
 * @function isValidDatePickerDate
 * @param {Object, Object} - IDatePickerUI, DatePickerOptions
 * @returns {Boolean}
 */
export const isValidDatePickerDate = (
  datepickerDate: IDatePickerUI | null,
  options: DatePickerOptions,
) => {
  if (!datepickerDate) return false;
  const year = validateYear(datepickerDate, options);
  const month = validateMonth(datepickerDate, options);
  const day = validateDay(datepickerDate, options);
  return year && month && day;
};

/**
 * Function `isDatePickerDateEmpty`
 * Check if user did not select anything from any of the 3 screens of datepicker
 *
 * @function isDatePickerDateEmpty
 * @param {Object, Object} - IDatePickerUI, DatePickerOptions
 * @returns {Boolean}
 */
export const isDatePickerDateEmpty = (
  datepickerDate: IDatePicker | IDatePickerUI | undefined | null,
  options: DatePickerOptions,
): boolean => {
  let date =
    datepickerDate && !isUIDate(datepickerDate) ? mapDateToUI(datepickerDate) : datepickerDate;
  if (!date) return false;

  const year = validateYear(date, options);
  const month = validateMonth(date, options);
  const day = validateDay(date, options);

  return !year && !month && !day;
};

export default validateDatepickerTabs;
