import { IAuthFields } from './types';

const FormFields: IAuthFields = {
  email: '',
  password: '',
  // @ts-expect-error TODO: The value should be CountryCodes
  countryCode: '',
};

export default FormFields;
