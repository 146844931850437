import { PayloadAction } from '@reduxjs/toolkit';
import { IApplicationState } from 'lib/types';

export type IModuleKey = keyof IApplicationState;
export enum ILazyLoadingModuleStatus {
  LOADING = 'loading',
  LOADED = 'loaded',
  UNLOADED = 'unloaded',
  UNLOADING = 'unloading',
}

export type ILazyModule = {
  id: IModuleKey;
  referenceCount: number;
  status: ILazyLoadingModuleStatus;
};

export interface IModulesState {
  byId: Record<string, ILazyModule>;
}

export type ModuleLoadedAction = PayloadAction<{
  module: string;
}>;

export type ModuleUnloadedAction = PayloadAction<{
  module: string;
}>;

export type ModuleBeforeUnloadAction = PayloadAction<{
  module: string;
}>;
