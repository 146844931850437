import { omit } from 'ramda';
import { IRelatedContentType } from 'app-shared/lib/articles/types';
import { getArticlePropertiesGeneric } from 'app-shared/lib/articles/utils';
import { ArticlesAnalyticsActionTypes } from 'lib/articles/analytics/action-types';
import {
  addBookmarkAnalytics,
  articleLoadedAnalytics,
  articleSidebarUsedAnalytics,
  clickedExternalLinkWithinArticle,
  loadMoreArticlesAnalytics,
  searchArticlesAnalytics,
  triggerArticleSocialShareAnalytics,
} from 'lib/articles/analytics/actions';
import { WebAnalyticsContext } from 'lib/bbcommon/utils/bridebook-analytics';
import { Action, IApplicationState } from 'lib/types';
import { getBookmarks } from '../selectors';

export const getSocialSharePropertiesGeneric = (
  method: string,
): {
  socialShareType: string;
  socialShareMethod: string;
} => ({
  socialShareType: 'article',
  socialShareMethod: method,
});

export const getArticleRHCPropsGeneric = (
  { supplierSlug, checklistTaskId }: IRelatedContentType,
  taskTitle: string,
): {
  articleRHCSuppliers: boolean;
  articleRHCSupplierCategory: string;
  articleRHCChecklist: boolean;
  articleRHCChecklistMainTaskTitle: string;
  articleRHCChecklistMainTaskId: string;
  articleRHCRelatedArticles: boolean; // always exists
} => ({
  articleRHCSuppliers: !!supplierSlug,
  articleRHCSupplierCategory: supplierSlug,
  articleRHCChecklist: !!checklistTaskId,
  articleRHCChecklistMainTaskTitle: taskTitle,
  articleRHCChecklistMainTaskId: checklistTaskId,
  articleRHCRelatedArticles: true, // always exists
});

export const getArticleRHCInteractPropsGeneric = (
  section: string,
  action: string,
): {
  artcileRHCSection: string;
  articleRHCAction: string;
} => ({
  artcileRHCSection: section,
  articleRHCAction: action,
});

export const getBookmarkIdentifyProps = (
  getState: () => IApplicationState,
): { numberOfBookmarkedArticles: number } => {
  const numberOfBookmarkedArticles = getBookmarks(getState()).length;
  return {
    numberOfBookmarkedArticles,
  };
};

interface MenuGeneric {
  getState: () => IApplicationState;
  activeTag: string;
  showAllTagsOverride?: boolean;
  searchStringOverride?: string;
}

export const getArticlesMenuPropertiesGeneric = ({
  getState,
  activeTag,
  showAllTagsOverride,
  searchStringOverride,
}: MenuGeneric) => {
  const {
    articles: { searchString, showAllTags },
    app: { pathname },
  } = getState();

  return {
    articlesMenuToggledTagsStatus: showAllTagsOverride || showAllTags,
    articlesMenuSelectedTags: activeTag ? [activeTag] : [],
    articlesMenuToggledBookmarkedStatus: pathname.indexOf('bookmarks') > -1,
    articlesMenuSearchTerm:
      typeof searchStringOverride === 'string' ? searchStringOverride : searchString,
  };
};

const filterLabelProps = (props: Record<string, any>) =>
  omit(['category', 'event', 'value'], props);

export default function articlesAnalytics(
  action: Action,
  bridebookAnalytics: WebAnalyticsContext,
  getState: () => IApplicationState,
) {
  const { type } = action;

  const { track, identifyWithTrack: identify } = bridebookAnalytics.getMethods(
    'Articles',
    filterLabelProps,
  );

  switch (type) {
    case ArticlesAnalyticsActionTypes.ADD_BOOKMARK_ANALYTICS: {
      const { article, isArticlePage } = action.payload as ReturnType<
        typeof addBookmarkAnalytics
      >['payload'];
      const method = isArticlePage ? 'article' : 'articlesMenu';
      const articlesMenuPropertiesGeneric = isArticlePage
        ? {}
        : {
            ...getArticlesMenuPropertiesGeneric({
              getState,
              activeTag: '',
            }),
          };
      identify(
        getBookmarkIdentifyProps(getState),
        {
          event: 'Bookmarked article',
          ...getArticlePropertiesGeneric({ article }),
          ...articlesMenuPropertiesGeneric,
          bookmarkedArticleMethod: method,
        },
        { userId: getState().users.user?.id },
      );
      break;
    }

    case ArticlesAnalyticsActionTypes.REMOVE_BOOKMARK_ANALYTICS: {
      const { article, isArticlePage } = action.payload;
      const method = isArticlePage ? 'article' : 'articlesMenu';
      const articlesMenuPropertiesGeneric = isArticlePage
        ? {}
        : {
            ...getArticlesMenuPropertiesGeneric({
              getState,
              activeTag: '',
            }),
          };
      identify(
        getBookmarkIdentifyProps(getState),
        {
          event: 'Removed article bookmark',
          ...getArticlePropertiesGeneric({ article }),
          ...articlesMenuPropertiesGeneric,
          removedArticleBookmarkMethod: method,
        },
        { userId: getState().users.user?.id },
      );
      break;
    }

    case ArticlesAnalyticsActionTypes.SET_ACTIVE_TAG_ANALYTICS: {
      const { isArticlePage, newTag } = action.payload;
      track({
        event: 'Used article filters',
        ...getArticlesMenuPropertiesGeneric({
          getState,
          activeTag: newTag,
        }),
        usedArticleFiltersTag: newTag,
        usedArticleFiltersLocation: isArticlePage ? 'article' : 'articlesMenu',
      });
      break;
    }

    case ArticlesAnalyticsActionTypes.CLEAR_ARTICLES_SEARCH_ANALYTICS: {
      track({
        event: 'Cleared article search',
        ...getArticlesMenuPropertiesGeneric({
          getState,
          activeTag: '',
        }),
      });
      break;
    }

    case ArticlesAnalyticsActionTypes.TOGGLE_MORE_TOPICS_ANALYTICS: {
      const { show } = action.payload;
      track({
        event: 'Toggled article filters',
        ...getArticlesMenuPropertiesGeneric({
          getState,
          activeTag: '',
          showAllTagsOverride: show,
        }),
      });
      break;
    }

    case ArticlesAnalyticsActionTypes.SEARCH_ARTICLES_ANALYTICS: {
      const {
        payload: { searchString },
      } = action as ReturnType<typeof searchArticlesAnalytics>;
      track({
        event: 'Searched articles',
        ...getArticlesMenuPropertiesGeneric({
          getState,
          activeTag: '',
          searchStringOverride: searchString,
        }),
      });
      break;
    }

    case ArticlesAnalyticsActionTypes.ARTICLE_SIDEBAR_USED_ANALYTICS: {
      const {
        payload: { section, action: userAction, article },
      } = action as ReturnType<typeof articleSidebarUsedAnalytics>;
      track({
        event: 'Used article RHC',
        ...(!!article && getArticlePropertiesGeneric({ article })),
        ...getArticleRHCInteractPropsGeneric(section, userAction),
      });
      break;
    }

    case ArticlesAnalyticsActionTypes.LOAD_MORE_ARTICLES_ANALYTICS: {
      const {
        payload: { activeTag },
      } = action as ReturnType<typeof loadMoreArticlesAnalytics>;
      const {
        articles: { searchString },
      } = getState();
      track({
        event: searchString ? 'Clicked load more search results' : 'Loaded more articles',
        ...getArticlesMenuPropertiesGeneric({
          getState,
          activeTag,
        }),
      });
      break;
    }

    case ArticlesAnalyticsActionTypes.ARTICLE_LOADED_ANALYTICS: {
      const {
        payload: { article, source },
      } = action as ReturnType<typeof articleLoadedAnalytics>;
      const {
        app: { previousPath },
      } = getState();
      track({
        event: 'Read article',
        ...(!!article && getArticlePropertiesGeneric({ article })),
        readArticleMethod: source ?? 'article',
        previousPath,
      });
      break;
    }

    case ArticlesAnalyticsActionTypes.TRIGGER_ARTICLE_SOCIAL_SHARE_ANALYTICS: {
      const {
        payload: { network, article },
      } = action as ReturnType<typeof triggerArticleSocialShareAnalytics>;
      track({
        event: 'Triggered social share',
        category: 'Social sharing',
        ...(!!article && getArticlePropertiesGeneric({ article })),
        ...getSocialSharePropertiesGeneric(network),
      });
      break;
    }

    case ArticlesAnalyticsActionTypes.CLICKED_EXTERNAL_LINK_WITHIN_ARTICLE: {
      const {
        payload: { article, source, ...restProps },
      } = action as ReturnType<typeof clickedExternalLinkWithinArticle>;
      const {
        app: { previousPath, pathname: pageCategoryWhenUserLoggedIn },
      } = getState();
      track({
        event: 'Clicked external link within article',
        category: 'Logged in',
        readArticleMethod: source ?? 'article',
        ...(!!article && getArticlePropertiesGeneric({ article })),
        previousPath,
        ...restProps,
        pageCategoryWhenUserLoggedIn,
      });
      break;
    }

    default:
      break;
  }
}
