import { UIActionTypes } from 'lib/ui/action-types';
import { WebAnalyticsContext } from '../bbcommon/utils/bridebook-analytics';
import { Action } from '../types';

export default function mobileAppAnalytics(
  action: Action,
  bridebookAnalytics: WebAnalyticsContext,
) {
  const { type } = action;
  const { track } = bridebookAnalytics.getMethods('App Engagement');

  switch (type) {
    case UIActionTypes.TOGGLE_PUSH_NOTIFICATION_OPT_IN_ANALYTICS: {
      const { show, interactionType } = action.payload;
      if (show) {
        track({ event: 'Triggered push notifications popup' });
      } else {
        track({ event: 'Interacted with push notifications popup', interactionType });
      }
      break;
    }

    case 'PUSH_NOTIFICATIONS_REGISTER_DEVICE_SUCCESS': {
      track({
        event: 'Received push registration token',
        token: action.payload,
      });
      break;
    }

    case 'RECEIVED_PUSH_NOTIFICATION': {
      const { notification, wasInBackground } = action.payload;

      track({
        event: 'Received push notification',
        notificationPayload: notification,
        clickedOn: wasInBackground,
        ...(notification.data.category === 'feedback' && {
          category: notification.data.category,
          notificationTriggerLocation: notification.data.notificationTriggerLocation,
          notificationType: notification.data.notificationType,
          supplierId: notification.data.supplierId,
          notificationDestination: notification.data.notificationDestination,
          collaborator: !!notification.data.collaborator,
        }),
      });
      break;
    }

    default:
      break;
  }
}
