import produce from 'immer';
import { Weddings } from '@bridebook/models';
import { IDatePicker, IWedding } from '@bridebook/models/source/models/Weddings.types';
import { recursiveSerializeTimestamps } from '@bridebook/toolbox/src/serialize-timestamps';
import {
  AccessControlActionTypes,
  IFetchCollaboratorsEmailsSuccessAction,
} from 'lib/access-control/action-types';
import { Action, IReducersImmer, IWeddingsState } from 'lib/types';
import { getReducerActions } from 'lib/utils';
import { AuthActionTypes, UserSetupCompletedAction } from '../auth/action-types';
import {
  IFetchCountryBoundsSuccessAction,
  IUpdateInboxUnreadAction,
  IUpdatePartnerNamesAction,
  IUpdateWeddingAction,
  IUpdateWeddingFieldAction,
  WeddingActionTypes,
} from './action-types';

const weddingProfileDefault: IWedding = {
  ...Weddings.new('_'),
  id: '',
};

const initialState: IWeddingsState = {
  profile: {
    ...weddingProfileDefault,
    tasks: { ...weddingProfileDefault.tasks, initializedAt: 0 },
  },
  collaborators: [],
  loaded: false,
  countryLatLongBounds: {
    ne: { lat: -90, lon: 180 },
    sw: { lat: 90, lon: 180 },
  },
  listenersInitialised: false,
  inboxUnread: 0,
  loading: false,
};

const reducers: IReducersImmer<IWeddingsState> = (draft: IWeddingsState) => ({
  [WeddingActionTypes.UPDATE_WEDDING]: (action: IUpdateWeddingAction) => {
    draft.profile = recursiveSerializeTimestamps(action.payload.wedding);
    draft.loading = false;
    draft.loaded = true;
  },
  [AuthActionTypes.USER_SETUP_COMPLETED]: (action: UserSetupCompletedAction) => {
    draft.profile = recursiveSerializeTimestamps(action.payload.wedding);
    draft.loading = false;
    draft.loaded = true;
  },

  [AuthActionTypes.USER_SETUP_STARTED]: () => {
    draft.loading = true;
  },

  [WeddingActionTypes.UPDATE_WEDDING_FIELD]: (action: IUpdateWeddingFieldAction) => {
    const { payload } = action;

    if (payload.name === 'date' && payload.value.date) {
      draft.profile.date = { ...draft.profile.date, ...payload.value.date } as IDatePicker;
    }
  },

  [WeddingActionTypes.UPDATE_PARTNER_NAMES]: (action: IUpdatePartnerNamesAction) => {
    draft.profile.partners = action.payload;
  },

  [WeddingActionTypes.UPDATE_INBOX_UNREAD]: (action: IUpdateInboxUnreadAction) => {
    draft.inboxUnread = action.payload.unreadCount;
  },

  [AccessControlActionTypes.FETCH_COLLABORATORS_EMAILS_SUCCESS]: (
    action: IFetchCollaboratorsEmailsSuccessAction,
  ) => {
    draft.collaborators = action.payload;
  },

  [WeddingActionTypes.FETCH_PROFILE_COUNTRY_BOUNDS_SUCCESS]: (
    action: IFetchCountryBoundsSuccessAction,
  ) => {
    draft.countryLatLongBounds = action.payload;
  },

  [WeddingActionTypes.GLOBAL_LISTENERS_INITIALISED]: () => {
    draft.listenersInitialised = true;
  },

  // Set weddings to initial state upon signout
  [AuthActionTypes.SIGN_OUT_COMPLETED]: () => initialState,
});

const reducersActions = getReducerActions(reducers);

/*
  This is a wrapper function which runs a proper reducer from the object above.
*/
const reducer = (state: IWeddingsState = initialState, action: Action): IWeddingsState => {
  if (!reducersActions[action.type]) {
    return state;
  }

  try {
    return produce(state, (draft) => reducers(draft)[action.type](action));
  } catch (err) {
    return state;
  }
};

export default reducer;
