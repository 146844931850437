import {
  getRecordsSuccess,
  setCurrentAuthor,
  setRelatedArticles,
  setSearchString,
  toggleMoreTopics,
} from './actions';

export enum ArticlesActionTypes {
  CLEAR_SEARCH = '[Articles] Clear articles search',
  GET_RECORDS_SUCCESS = '[Articles] Get records success',
  SEARCH_ARTICLES = '[Articles] Trigger articles search',
  SET_SEARCH_STRING = '[Articles] Set search string',
  TOGGLE_MORE_TOPICS = '[Articles] Toggle more articles topics',
  SET_CURRENT_AUTHOR = '[Articles] Set current author',
  SET_RELATED_ARTICLES = '[Articles] Set related articles',
}

export type ISetSearchStringAction = ReturnType<ReturnType<typeof setSearchString>>;

export type IToggleMoreTopicsAction = ReturnType<ReturnType<typeof toggleMoreTopics>>;

export type IGetRecordsSuccessAction = ReturnType<typeof getRecordsSuccess>;

export type ISetCurrentAuthorAction = ReturnType<typeof setCurrentAuthor>;

export type ISetRelatedArticleAction = ReturnType<typeof setRelatedArticles>;
