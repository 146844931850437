import { IUISupplier } from '@bridebook/toolbox/src/types';

export enum InboxAnalyticsAction {
  ClickedQuickAction = 'Clicked to use messages quick action',
  ArchivedEnquiry = 'Archived enquiry',
  UnarchivedEnquiry = 'Unarchived enquiry',
}

export interface IClickedQuickResponse {
  supplier: IUISupplier;
  supplierWeddingId?: string;
  quickActionText: string;
}

export const clickedQuickResponse = (payload: IClickedQuickResponse) => ({
  type: InboxAnalyticsAction.ClickedQuickAction,
  payload,
});

export interface IArchivedEnquiryAnalytics {
  supplierWeddingId?: string;
  supplier: IUISupplier;
  archiveLocation: 'messages' | 'chat';
}
export const archivedEnquiryAnalytics = (payload: IArchivedEnquiryAnalytics) => ({
  type: InboxAnalyticsAction.ArchivedEnquiry,
  payload,
});

export interface IUnarchivedEnquiryAnalytics {
  supplierWeddingId?: string;
  supplier: IUISupplier;
}
export const unarchivedEnquiryAnalytics = (payload: IUnarchivedEnquiryAnalytics) => ({
  type: InboxAnalyticsAction.UnarchivedEnquiry,
  payload,
});
