export default class DatePickerModel {
  constructor() {
    this.edit = false;
    this.showUndecided = true;
    this.showSeason = true;
    this.showDays = true;
    this.title = '';
    this.activeTab = 'year-screen';
  }

  activeTab: string;
  title: string;
  edit: boolean;
  showDays: boolean;
  showSeason: boolean;
  showUndecided: boolean;
}
