import produce from 'immer';
import { merge, not } from 'ramda';
import { Action, IBudgetState, IReducersImmer } from 'lib/types';
import { getReducerActions } from 'lib/utils';
import { AuthActionTypes } from '../auth/action-types';
import {
  BudgetActionTypes,
  IDeleteSupplierBudgetAction,
  IInitialiseSuccessAction,
  ILoadOnboardingDataAction,
  IOnFirebaseBudgetAction,
  ISetBudgetInitFormFieldAction,
  IToggleBudgetDrawerAction,
  IToggleBudgetInitFormCategoryAction,
  IToggleCustomModalAction,
  IToggleInitLoaderAction,
  IToggleScrollToItemAfterAddingAction,
  IUpdateBudgetExtras,
  IUpdateGuestsEstimate,
} from './action-types';
import IBudgetInitForm from './budget-init-form';

const initialState: IBudgetState = {
  actual: 0,
  showCustomCostModal: false,
  customCostGroupId: null,
  budgetInitForm: merge({}, IBudgetInitForm),
  costs: {},
  costsInitial: {},
  listenerInitialised: false,
  initSession: false,
  loaded: false,
  showBudgetError: false,
  showInitLoader: false,
  itemDrawerVisible: false,
  openedItemId: null,
  scrollToItemAfterAdding: false,
};

const reducers: IReducersImmer<IBudgetState> = (draft) => ({
  [BudgetActionTypes.ON_FIREBASE_BUDGET]: (action: IOnFirebaseBudgetAction) => {
    draft.costs = action.payload;
    draft.listenerInitialised = true;
    draft.customCostGroupId = null;
    draft.loaded = null;
  },

  [BudgetActionTypes.FETCH_COSTS_INITIAL_SUCCESS]: (action: IOnFirebaseBudgetAction) => {
    draft.costsInitial = action.payload;
  },

  [BudgetActionTypes.LOAD_ONBOARDING_DATA]: (action: ILoadOnboardingDataAction) => {
    const { budget, guestsEstimate, timeOfWeek, timeOfYear, year } = action.payload;
    draft.budgetInitForm.fields.budget = Number(budget) || 0;
    draft.budgetInitForm.fields.guestsEstimate = guestsEstimate;
    draft.budgetInitForm.fields.timeOfWeek = timeOfWeek;
    draft.budgetInitForm.fields.timeOfYear = timeOfYear;
    draft.budgetInitForm.fields.year = year;
  },

  [BudgetActionTypes.UPDATE_GUESTS_ESTIMATE]: (action: IUpdateGuestsEstimate) => {
    draft.budgetInitForm.fields.guestsEstimate = action.payload;
  },

  [BudgetActionTypes.UPDATE_BUDGET_EXTRAS]: (action: IUpdateBudgetExtras) => {
    draft.budgetInitForm.fields.optionalCategories = action.payload;
  },

  [BudgetActionTypes.DELETE_SUPPLIER_BUDGET]: (action: IDeleteSupplierBudgetAction) => {
    const { cost } = action.payload;
    delete draft.costs[cost.id];
  },

  [BudgetActionTypes.INITIALISE_DYNAMIC_BUDGET_SUCCESS]: (action: IInitialiseSuccessAction) => {
    // @ts-ignore FIXME
    draft.costs = action.payload;
    draft.listenerInitialised = false;
    draft.loaded = true;
    draft.initSession = true;
  },

  [BudgetActionTypes.INITIALISE_DYNAMIC_BUDGET_START]: () => {
    draft.loaded = false;
  },

  [BudgetActionTypes.INITIALISE_DYNAMIC_BUDGET_ERROR]: () => {
    draft.loaded = true;
    draft.showBudgetError = true;
  },

  [BudgetActionTypes.TOGGLE_INIT_LOADER]: (action: IToggleInitLoaderAction) => {
    draft.showInitLoader = action.payload;
  },

  [BudgetActionTypes.RESET_BUDGET]: () => {
    draft.costs = {};
  },

  [BudgetActionTypes.SET_BUDGET_INIT_FORM_FIELD]: (action: ISetBudgetInitFormFieldAction) => {
    // @ts-expect-error type is added, not sure if we can do any better in here
    draft.budgetInitForm.fields[action.payload.name] = action.payload.value;
  },

  [BudgetActionTypes.TOGGLE_BUDGET_INIT_FORM_CATEGORY]: (
    action: IToggleBudgetInitFormCategoryAction,
  ) => {
    const { name } = action.payload;
    const categoryInit = draft.budgetInitForm.fields.optionalCategories[name];
    draft.budgetInitForm.fields.optionalCategories[name] = not(categoryInit);
  },

  [BudgetActionTypes.HIDE_BUDGET_ERROR]: () => {
    draft.showBudgetError = false;
  },

  [BudgetActionTypes.TOGGLE_CUSTOM_COST_MODAL]: (action: IToggleCustomModalAction) => {
    const { show, groupId } = action.payload;
    draft.showCustomCostModal = show;
    draft.customCostGroupId = groupId;
  },

  [BudgetActionTypes.TOGGLE_BUDGET_DRAWER]: (action: IToggleBudgetDrawerAction) => {
    const { show, itemId } = action.payload;
    draft.itemDrawerVisible = show;
    draft.openedItemId = itemId;
  },

  [BudgetActionTypes.TOGGLE_SCROLL_TO_ITEM_AFTER_ADDING]: (
    action: IToggleScrollToItemAfterAddingAction,
  ) => {
    draft.scrollToItemAfterAdding = action.payload;
  },

  [AuthActionTypes.SIGN_OUT_COMPLETED]: () => initialState,
});

const reducersActions = getReducerActions(reducers);

const reducer = (state: IBudgetState = initialState, action: Action): IBudgetState => {
  if (!reducersActions[action.type]) {
    return state;
  }

  try {
    return produce(state, (draft: IBudgetState) => reducers(draft)[action.type](action));
  } catch (err) {
    return state;
  }
};

export default reducer;
