import { WebAnalyticsContext } from 'lib/bbcommon/utils/bridebook-analytics';
import {
  CompareSuppliersActionTypes,
  ComparedSuppliersAnalyticsAction,
} from 'lib/compare-suppliers/action-types';
import { Action } from 'lib/types';

export default function compareSuppliersAnalytics(
  action: Action,
  bridebookAnalytics: WebAnalyticsContext,
): void {
  switch (action.type) {
    case CompareSuppliersActionTypes.COMPARED_SUPPLIERS_ANALYTICS: {
      const { track } = bridebookAnalytics.getMethods('Compare suppliers');
      const { payload } = action as ComparedSuppliersAnalyticsAction;

      track({
        event: 'Compared suppliers',
        suppliersComparedList: payload.supplierIdsList,
        noOfSuppliersCompared: payload.supplierIdsList.length,
        noOfSuppliersEnquired: payload.totalSuppliersEnquired,
      });
      break;
    }

    default:
      break;
  }
}
