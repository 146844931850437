import produce from 'immer';
import { Action } from 'redux';
import { AuthActionTypes } from 'lib/auth/action-types';
import { IReducersImmer, MobileAppState } from 'lib/types';
import { getReducerActions } from 'lib/utils';
import { MobileAppActionTypes } from './action-types';

const initialState: MobileAppState = {
  showAppRatePopup: false,
  pushPrePromptShown: false,
  platform: null,
  loaded: false,
  appKeyboardOpened: false,
};

const reducers: IReducersImmer<MobileAppState> = (draft) => ({
  [MobileAppActionTypes.PUSH_PRE_PROMPT_SHOWN]: () => {
    draft.pushPrePromptShown = true;
  },

  SHOW_APP_RATE_POPUP: () => {
    draft.showAppRatePopup = true;
  },

  [MobileAppActionTypes.SET_PLATFORM_INFO]: (action) => {
    draft.platform = action.payload;
  },

  [AuthActionTypes.SIGN_OUT_COMPLETED]: () => {
    draft.loaded = false;
  },

  [AuthActionTypes.REDIRECT_AFTER_AUTH_DONE]: () => {
    draft.loaded = true;
  },

  [MobileAppActionTypes.MOBILE_NATIVE_KEYBOARD_TOGGLE]: (action: { payload: boolean }) => {
    draft.appKeyboardOpened = action.payload;
  },
});

const reducersActions = getReducerActions(reducers);

/*
	This is a wrapper function which runs a proper reducer from the object above.
*/
const reducer = (state: MobileAppState = initialState, action: Action): MobileAppState => {
  if (!reducersActions[action.type]) {
    return state;
  }

  try {
    return produce(state, (draft) => reducers(draft)[action.type](action));
  } catch (err) {
    return state;
  }
};

export default reducer;
