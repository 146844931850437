import { ILayoutProps } from 'next';
import { appWithTranslation } from 'next-i18next';
import { ReactElement, useEffect } from 'react';
import { Capacitor } from '@capacitor/core';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { IAppProps } from 'pages/_app';
import { AuthProvider } from 'components/auth/auth-context';
import { SectionsContextProvider } from 'components/home/sections/sections-context';
import UserDeletionNotice from 'components/settings/user-deletion-notice/user-deletion-notice';
import { setDevice } from 'lib/app/actions';
import ComponentLevelErrorBoundary from 'lib/app/component-level-error-boundary';
import { selectLayoutProps } from 'lib/app/selectors';
import { useSetMarketCookie } from 'lib/auth/hooks/use-set-market-cookie-listener';
import { withFullLayout } from 'lib/layout-full';
import { hideSplashScreen } from 'lib/mobile-app/actions';
import { isCordovaApp, useDispatch, useSelector } from 'lib/utils';
import nextI18NextConfig from '../next-i18next.app-full.config.js';
import { wrapper } from '../store/store';

const queryClient = new QueryClient();
const EXCLUDE_FROM_NORMAL_FLOW = ['/inbox-panel'];

const AppFull = (props: IAppProps) => {
  const dispatch = useDispatch();
  const appStarted = useSelector((state) => state.app.started);

  const { Component, router, pageProps } = props;
  const shouldExcludeFromNormalFlow = EXCLUDE_FROM_NORMAL_FLOW.some((path) =>
    router.asPath.startsWith(path),
  );
  const Layout = Component.Layout || DefaultLayout;
  const layoutProps = useSelector(selectLayoutProps);

  useSetMarketCookie();

  // Global Init
  useEffect(() => {
    if (!appStarted && !shouldExcludeFromNormalFlow) {
      const isCordova = isCordovaApp() || Capacitor.isNativePlatform();
      dispatch(setDevice({ isCordova }));
      dispatch({ type: 'APP_STARTED' });
      if (isCordova) dispatch(hideSplashScreen());
    }
  }, [appStarted, dispatch, shouldExcludeFromNormalFlow]);

  return (
    <ComponentLevelErrorBoundary component={'AppFull'}>
      <AuthProvider>
        <QueryClientProvider client={queryClient}>
          <SectionsContextProvider>
            {Layout(
              <UserDeletionNotice>
                <Component {...pageProps} url={router.query} />
              </UserDeletionNotice>,
              props,
              layoutProps,
            )}
          </SectionsContextProvider>
        </QueryClientProvider>
      </AuthProvider>
    </ComponentLevelErrorBoundary>
  );
};

const ConnectedAppFull = ({ Component, ...rest }: IAppProps) => (
  <AppFull Component={Component} {...rest} />
);

const TranslatedAppFull = appWithTranslation(ConnectedAppFull, nextI18NextConfig);

export default wrapper.withRedux(TranslatedAppFull);

function DefaultLayout(page: ReactElement<unknown>, props: any, layoutProps: ILayoutProps) {
  return withFullLayout(page, props, layoutProps);
}
